import { useMutation } from "@tanstack/react-query";
import ModalResult from "Components/Shared/ModalResult/ModalResult.component";
import { API_ENDPOINTS } from "Constants/api.constants";
import { ConfirmationModal } from "UI";
import { apiGenerator } from "Utils";
import React from "react";

const RevokeAccessModal = ({ seat, onClose, translate }) => {
  const [keepFiles, setKeepFiles] = React.useState(true);
  const revokeAccessMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATION_SEATS_REVOKE(seat?.id), data);
    },
  });

  if (revokeAccessMutation.isSuccess) {
    return (
      <ModalResult
        success={revokeAccessMutation.isSuccess}
        successHeader={translate("organizationHome.confirmationModals.revokeAccess.success.header")}
        successDescription={translate("organizationHome.confirmationModals.revokeAccess.success.description")}
        onClose={onClose}
      />
    );
  }

  return (
    <ConfirmationModal
      headerText={translate("organizationHome.confirmationModals.revokeAccess.confirmation.header")}
      descriptionText={
        <div className="show-revoke-description-content">
          <p>{translate("organizationHome.confirmationModals.revokeAccess.confirmation.description")}</p>
          <div className="keep-file-checkbox">
            <input
              type="checkbox"
              value={keepFiles}
              defaultChecked={keepFiles}
              onChange={(e) => {
                setKeepFiles(e.target.checked);
              }}
            />
            <span>{translate("organizationHome.confirmationModals.revokeAccess.confirmation.keepFiles")}</span>
          </div>
        </div>
      }
      closeAction={() => {
        onClose();
      }}
      confirmText="Confirm"
      confirmAction={() => {
        revokeAccessMutation.mutate({ keep_files: keepFiles });
      }}
      pending={revokeAccessMutation.isLoading}
    />
  );
};

export default RevokeAccessModal;
