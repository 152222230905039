import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { APP_SCOPES, LOCAL_STORAGE, REDIRECT_FROM_SOURCES, REQUEST_STATUS } from "Constants/global.constants";
import routes from "Constants/Route.constants";
import dashboardTranslations from "Translations/dashboard.json";
import TeamsDashboardTranslations from "Translations/teamsDashboard.json";
import createComputerTranslations from "Translations/createComputer.json";
import workstationTranslations from "Translations/workstation.json";
import filesTranslations from "Translations/files.json";
import appStreamingTranslations from "Translations/appStreaming.json";
import { withLocalize } from "react-localize-redux";
import { getAccountAPI } from "Actions/Account.actions";
import organizationsTranslations from "Translations/organizations.json";
import ProtectedRouteWithTranslation from "Utils/Components/Route/Helpers/ProtectedRouteWithTranslation";
import { withRouter } from "react-router-dom";
import { useAppScope } from "Utils/Hooks/useAppScope";
import { saveItemToLocalStorage } from "Utils/Helpers/storage.helpers";
import { logoutAPI } from "Actions/Authentication/Login.actions";
import { useAPI } from "Utils/Helpers/api.helpers";
import { API_ENDPOINTS } from "Constants/api.constants";
import Loader from "../../Loader/Loader.component";

const AuthenticatedRoute = (props) => {
  const dispatch = useDispatch();
  const { path, guard: Guard, addTranslation, account, history } = props;
  const { accessToken, account: user } = account || {};
  const { verified: isUserVerified, scope: accountScope, time_zone: userTimeZone } = user?.attributes || {};
  const { appScope, setAppScope } = useAppScope();

  // parameter for redirected from which source e.g. unity
  const source = new URLSearchParams(window.location.search).get("source");

  // accountScope is last saved scope in backend

  const urlParams = new URLSearchParams(window.location.search);
  const amzMarketplaceToken = urlParams.get("x-amzn-marketplace-token");

  useEffect(() => {
    if (amzMarketplaceToken) {
      saveItemToLocalStorage(LOCAL_STORAGE.amzMarketplaceToken, amzMarketplaceToken);
      dispatch(logoutAPI(account.accessToken));
      history.push(routes.login);
    }
  }, [amzMarketplaceToken]);

  useEffect(() => {
    dispatch(getAccountAPI());
  }, [accessToken, path]);

  const { api: setUserTimeZone } = useAPI({
    endpoint: API_ENDPOINTS.TIME_ZONE,
    type: "put",
    onSuccess: () => {
      dispatch(getAccountAPI());
    },
  });

  useEffect(() => {
    if (source && source === REDIRECT_FROM_SOURCES.unity) {
      setAppScope(APP_SCOPES.vendor);
    }
  }, [source]);

  useEffect(() => {
    addTranslation(workstationTranslations);
    addTranslation(filesTranslations);
    addTranslation(createComputerTranslations);
    addTranslation(TeamsDashboardTranslations);
    addTranslation(dashboardTranslations);
    addTranslation(appStreamingTranslations);
    addTranslation(organizationsTranslations);
  }, []);

  useEffect(() => {
    if (user && userTimeZone === null) {
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (localTimeZone.toLowerCase() === "utc") {
        setUserTimeZone({ time_zone: "Europe/Dublin" });
      } else {
        setUserTimeZone({ time_zone: localTimeZone });
      }
    }
  }, [user?.id]);

  useEffect(() => {
    if (user?.id) {
      window.gtag("event", "page_view", {
        user_id: user.id,
        scope: accountScope,
      });
    }
  }, [path, user?.id]);

  useEffect(() => {
    if (user?.id && window.mixpanel && typeof window.mixpanel.identify === "function") {
      window.mixpanel.identify(user.id);
      window.mixpanel.people.set({
        $email: user.attributes.email,
        $name: user.attributes.name,
      });
    }
  }, [user?.id]);

  useEffect(() => {
    // if account scope set in backend and not in context
    if (accountScope && !appScope) {
      setAppScope(accountScope);
    }

    if (account.getAccountCTX.status === REQUEST_STATUS.SUCCESS && !appScope && !accountScope) {
      if (path === routes.appStreamingSendTokenToPlugin) {
        setAppScope(APP_SCOPES.vendor);
        return;
      }

      history.push(routes.suite);
    }
  }, [accountScope, appScope, account.getAccountCTX.status]);

  if (
    account.getAccountCTX.status === REQUEST_STATUS.PENDING ||
    account.getAccountCTX.status === REQUEST_STATUS.NOT_DEFINED ||
    (!appScope && !path?.includes(routes.suite))
  ) {
    const darkTheme = path?.includes(routes.workstation);
    return <Loader big centered darkTheme={darkTheme} />;
  }

  if (accessToken) {
    const newProps = { ...props, isUserVerified };
    if (Guard) {
      return <Guard {...newProps} />;
    }

    return <ProtectedRouteWithTranslation {...newProps} />;
  }

  return null;
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withLocalize(withRouter(connect(mapStateToProps, {})(AuthenticatedRoute)));
