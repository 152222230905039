import React from "react";
import { useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { apiGenerator } from "Utils";
import { ConfirmationModal } from "UI";
import ModalResult from "Components/Shared/ModalResult/ModalResult.component";
import { Translate } from "react-localize-redux";
import moment from "moment";

const ActivateComputerModal = ({ seat, onClose, willActivateSubscription, translate }) => {
  const { subscription_end_at: subscriptionEndAt } = seat?.attributes || {};

  const setRenewalMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATION_SEATS_RENEWAL(seat.id));
    },
  });

  if (setRenewalMutation.isSuccess) {
    return (
      <ModalResult
        success={setRenewalMutation.isSuccess}
        successHeader={translate("organizationHome.confirmationModals.activateComputer.success.header")}
        successDescription={translate("organizationHome.confirmationModals.activateComputer.success.description")}
        onClose={onClose}
      />
    );
  }

  return (
    <ConfirmationModal
      headerText={translate("organizationHome.confirmationModals.activateComputer.confirmation.header")}
      descriptionText={
        <Translate
          id={`organizationHome.confirmationModals.activateComputer.confirmation.willActivateSubscription.${!!willActivateSubscription}`}
          options={{
            renderInnerHtml: true,
          }}
          data={{ date: moment(subscriptionEndAt).format("MMMM Do YYYY") }}
        />
      }
      // descriptionText={translate("organizationHome.confirmationModals.activateComputer.confirmation.description")}
      closeAction={() => {
        setRenewalMutation.reset();
        onClose();
      }}
      confirmText="Confirm"
      confirmAction={() => {
        setRenewalMutation.mutate();
      }}
      pending={setRenewalMutation.isLoading}
      success={setRenewalMutation.isSuccess}
    />
  );
};

export default ActivateComputerModal;
