import React from "react";

import { ReactComponent as NoIcon } from "Assets/icons/no-icon.svg";
import { ReactComponent as ArrowLeftSmall } from "Assets/icons/arrow-left-small.svg";
import { ReactComponent as ArrowRight } from "Assets/icons/arrow-right.svg";
import { ReactComponent as ArrowRightSmall } from "Assets/icons/arrow-right-small.svg";
import { ReactComponent as Calculator } from "Assets/icons/calculator.svg";
import { ReactComponent as ChevronDown } from "Assets/icons/chevron-down.svg";
import { ReactComponent as Check } from "Assets/icons/check.svg";
import { ReactComponent as CheckMark } from "Assets/icons/checkMark.svg";
import { ReactComponent as ChevronDownCircle } from "Assets/icons/chevron-down-circle.svg";
import { ReactComponent as ChevronLeft } from "Assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "Assets/icons/chevron-right.svg";
import { ReactComponent as ChevronUp } from "Assets/icons/chevron-up.svg";
import { ReactComponent as ChevronUpCircle } from "Assets/icons/chevron-up-circle.svg";
import { ReactComponent as Close } from "Assets/icons/close.svg";
import { ReactComponent as Cloud } from "Assets/icons/cloud.svg";
import { ReactComponent as CloudImport } from "Assets/icons/cloud-import.svg";
import { ReactComponent as CopyToClipboard } from "Assets/icons/copy-to-clipboard.svg";
import { ReactComponent as CopyLink } from "Assets/icons/copy-link.svg";
import { ReactComponent as Display } from "Assets/icons/display.svg";
import { ReactComponent as ErrorIcon } from "Assets/icons/error.svg";
import { ReactComponent as EyeClosedIcon } from "Assets/icons/eye-closed.svg";
import { ReactComponent as EyeOpenIcon } from "Assets/icons/eye-open.svg";
import { ReactComponent as FilesUpload } from "Assets/icons/files/upload.svg";
import { ReactComponent as FileDrag } from "Assets/icons/files/file-drag.svg";
import { ReactComponent as FileCopy } from "Assets/icons/file-copy.svg";
import { ReactComponent as Facebook } from "Assets/icons/social/facebook.svg";
import { ReactComponent as InfoIcon } from "Assets/icons/info.svg";
import { ReactComponent as Individual } from "Assets/icons/individual.svg";
import { ReactComponent as Keyboard } from "Assets/icons/keyboard.svg";
import { ReactComponent as KeyboardMouse } from "Assets/icons/keyboard-mouse.svg";
import { ReactComponent as LeftArrowIcon } from "Assets/icons/left-arrow.svg";
import { ReactComponent as LoaderIcon } from "Assets/icons/loader.svg";
import { ReactComponent as Lock } from "Assets/icons/lock.svg";
import { ReactComponent as Messenger } from "Assets/icons/social/messenger.svg";
import { ReactComponent as Pen } from "Assets/icons/pen.svg";
import { ReactComponent as PlayIcon } from "Assets/icons/play.svg";
import { ReactComponent as Pause } from "Assets/icons/pause.svg";
import { ReactComponent as Plus } from "Assets/icons/plus.svg";
import { ReactComponent as Reload } from "Assets/icons/reload.svg";
import { ReactComponent as Refresh } from "Assets/icons/appStreaming/refresh.svg";
import { ReactComponent as Twitter } from "Assets/icons/social/twitter.svg";
import { ReactComponent as SendVagon } from "Assets/icons/send-vagon.svg";
import { ReactComponent as ScrollDown } from "Assets/icons/scroll-down.svg";
import { ReactComponent as ScrollUp } from "Assets/icons/scroll-up.svg";
import { ReactComponent as Settings } from "Assets/icons/settings.svg";
import { ReactComponent as Search } from "Assets/icons/search.svg";
import { ReactComponent as Star } from "Assets/icons/star.svg";
import { ReactComponent as SoundMute } from "Assets/icons/sound-mute.svg";
import { ReactComponent as SoundOn } from "Assets/icons/sound-on.svg";
import { ReactComponent as Microphone } from "Assets/icons/microphone.svg";
import { ReactComponent as NewFullScreen } from "Assets/icons/new-fullscreen.svg";
import { ReactComponent as ActiveFullScreen } from "Assets/icons/active-fullscreen.svg";
import { ReactComponent as GamePad } from "Assets/icons/gamepad.svg";
import { ReactComponent as VagonIcon } from "Assets/icons/vagon-icon.svg";
import { ReactComponent as VagonLogo } from "Assets/icons/vagon-logo.svg";
import { ReactComponent as Whatsapp } from "Assets/icons/social/whatsapp.svg";
import { ReactComponent as Undef } from "Assets/icons/undef.svg";
import { ReactComponent as Trash } from "Assets/icons/trash.svg";
import { ReactComponent as TeamsMember } from "Assets/icons/teams-member.svg";
import { ReactComponent as TeamsCaretDown } from "Assets/icons/teams-caret-down.svg";
import { ReactComponent as TeamsCaretUp } from "Assets/icons/teams-caret-up.svg";
import { ReactComponent as TeamsPlus } from "Assets/icons/teams-plus.svg";
import { ReactComponent as Telegram } from "Assets/icons/social/telegram.svg";
import { ReactComponent as Folder } from "Assets/icons/files/folder.svg";
import { ReactComponent as SharedFolder } from "Assets/icons/files/shared-folder.svg";
import { ReactComponent as MemberFolder } from "Assets/icons/files/member-folder.svg";
import { ReactComponent as FolderWhite } from "Assets/icons/files/white/folder.svg";
import { ReactComponent as FolderCreate } from "Assets/icons/files/create-folder.svg";
import { ReactComponent as File } from "Assets/icons/files/file.svg";
import { ReactComponent as FileArrowLeft } from "Assets/icons/files/file-left-arrow.svg";
import { ReactComponent as Files } from "Assets/icons/files.svg";
import { ReactComponent as FileImage } from "Assets/icons/files/img.svg";
import { ReactComponent as FilePSD } from "Assets/icons/files/psd.svg";
import { ReactComponent as FileDOC } from "Assets/icons/files/doc.svg";
import { ReactComponent as FileVideo } from "Assets/icons/files/video.svg";
import { ReactComponent as FileZip } from "Assets/icons/files/zip.svg";
import { ReactComponent as FileWhite } from "Assets/icons/files/white/file.svg";
import { ReactComponent as FileImageWhite } from "Assets/icons/files/white/img.svg";
import { ReactComponent as FilePSDWhite } from "Assets/icons/files/white/psd.svg";
import { ReactComponent as FileDOCWhite } from "Assets/icons/files/white/doc.svg";
import { ReactComponent as FileVideoWhite } from "Assets/icons/files/white/video.svg";
import { ReactComponent as FileZipWhite } from "Assets/icons/files/white/zip.svg";
import { ReactComponent as FileDownload } from "Assets/icons/files/download.svg";
import { ReactComponent as FileRename } from "Assets/icons/files/rename.svg";
import { ReactComponent as FileSync } from "Assets/icons/files/sync.svg";
import { ReactComponent as FileSyncLeft } from "Assets/icons/files/sync-left.svg";
import { ReactComponent as FileSelect } from "Assets/icons/file-select.svg";
import { ReactComponent as FileUpload } from "Assets/icons/files/file-upload.svg";
import { ReactComponent as FileCancelSync } from "Assets/icons/files/cancel-sync.svg";
import { ReactComponent as Filter } from "Assets/icons/filter.svg";
import { ReactComponent as Fullscreen } from "Assets/icons/fullscreen.svg";
import { ReactComponent as More } from "Assets/icons/more.svg";
import { ReactComponent as UploadToCloud } from "Assets/icons/files/cloud-upload.svg";
import { ReactComponent as UploadToWorkstation } from "Assets/icons/files/workstation-upload.svg";
import { ReactComponent as Share } from "Assets/icons/share.svg";
import { ReactComponent as Team } from "Assets/icons/team.svg";
import { ReactComponent as Dashboard } from "Assets/icons/appStreaming/dashboard.svg";
import { ReactComponent as Infrastructure } from "Assets/icons/appStreaming/infrastructure.svg";
import { ReactComponent as AppInTest } from "Assets/icons/appStreaming/test.svg";
import { ReactComponent as Payment } from "Assets/icons/appStreaming/payment.svg";
import { ReactComponent as PlusCircle } from "Assets/icons/appStreaming/plus-circle.svg";
import { ReactComponent as Message } from "Assets/icons/contact-us.svg";
import { ReactComponent as LocationPin } from "Assets/icons/location-pin.svg";
import { ReactComponent as InactiveRegion } from "Assets/icons/appStreaming/inactive-region.svg";
import { ReactComponent as Hourglass } from "Assets/icons/hourglass.svg";
import { ReactComponent as Calendar } from "Assets/icons/calendar.svg";
import { ReactComponent as CaretRight } from "Assets/icons/caret-right.svg";
import { ReactComponent as CaretDown } from "Assets/icons/caret-down.svg";
import { ReactComponent as CaretUp } from "Assets/icons/caret-up.svg";
import { ReactComponent as ArrowUp } from "Assets/icons/arrow-up.svg";
import { ReactComponent as Clock } from "Assets/icons/clock.svg";
import { ReactComponent as Copy } from "Assets/icons/copy.svg";
import { ReactComponent as Document } from "Assets/icons/document.svg";
import { ReactComponent as ViewMode } from "Assets/icons/view-mode.svg";
import { ReactComponent as Monitor } from "Assets/icons/monitor.svg";
import { ReactComponent as Warning } from "Assets/icons/warning.svg";
import { ReactComponent as RightEmptyCaret } from "Assets/icons/right-empty-caret.svg";
import { ReactComponent as StreamCheck } from "Assets/icons/appStreaming/stream-check.svg";
import { ReactComponent as AppsCheck } from "Assets/icons/apps-check.svg";
import { ReactComponent as StreamPlay } from "Assets/icons/appStreaming/stream-play.svg";
import { ReactComponent as StreamPause } from "Assets/icons/appStreaming/stream-pause.svg";
import { ReactComponent as Pencil } from "Assets/icons/pencil.svg";
import { ReactComponent as StreamSettings } from "Assets/icons/appStreaming/stream-settings.svg";
import { ReactComponent as PlayButton } from "Assets/icons/appStreaming/play-button.svg";
import { ReactComponent as Streams } from "Assets/icons/appStreaming/streams.svg";
import { ReactComponent as Stats } from "Assets/icons/appStreaming/stats.svg";
import { ReactComponent as Api } from "Assets/icons/appStreaming/api.svg";
import { ReactComponent as Applications } from "Assets/icons/appStreaming/applications.svg";
import { ReactComponent as Linux } from "Assets/icons/appStreaming/linux.svg";
import { ReactComponent as TriangleError } from "Assets/icons/triangle-error.svg";
import { ReactComponent as SignalWeak } from "Assets/icons/signal-weak.svg";
import { ReactComponent as SignalLow } from "Assets/icons/signal-low.svg";
import { ReactComponent as SignalGood } from "Assets/icons/signal-good.svg";
import { ReactComponent as SignalStrong } from "Assets/icons/signal-strong.svg";
import { ReactComponent as AppStreamingSoundOn } from "Assets/icons/appStreaming/sound-on.svg";
import { ReactComponent as AppStreamingSoundOff } from "Assets/icons/appStreaming/sound-off.svg";
import { ReactComponent as CreateComputers } from "Assets/icons/teams/create-computers.svg";
import { ReactComponent as AddUser } from "Assets/icons/teams/add-user.svg";
import { ReactComponent as TeamsCheck } from "Assets/icons/teams/check.svg";
import { ReactComponent as Bolt } from "Assets/icons/bolt.svg";
import { ReactComponent as AquaCheck } from "Assets/icons/aqua-check.svg";
import { ReactComponent as BoldCross } from "Assets/icons/bold-cross.svg";
import { ReactComponent as Desktop } from "Assets/icons/desktop.svg";
import { ReactComponent as Tablet } from "Assets/icons/tablet.svg";
import { ReactComponent as Mobile } from "Assets/icons/mobile.svg";
import { ReactComponent as Reset } from "Assets/icons/reset.svg";
import { ReactComponent as Lightning } from "Assets/icons/lightning.svg";
import { ReactComponent as PixelStreaming } from "Assets/icons/pixel-streaming.svg";
import { ReactComponent as RenderStreaming } from "Assets/icons/render-streaming.svg";
import { ReactComponent as OrganizationsCheck } from "Assets/icons/organizations/check.svg";
import { ReactComponent as OrganizationsCross } from "Assets/icons/organizations/cross.svg";
import { ReactComponent as OrganizationsUser } from "Assets/icons/organizations/user.svg";
import { ReactComponent as ApplicationLibrary } from "Assets/icons/organizations/application-library.svg";
import { ReactComponent as SelectComputer } from "Assets/icons/organizations/select-computer.svg";
import { ReactComponent as Connect } from "Assets/icons/organizations/connect.svg";
import { ReactComponent as CopyComputer } from "Assets/icons/organizations/copy-computer.svg";
import { ReactComponent as ComputerLogo } from "Assets/icons/logos/computer.svg";
import { ReactComponent as StreamsLogo } from "Assets/icons/logos/streams.svg";
import { ReactComponent as TeamsLogo } from "Assets/icons/logos/teams.svg";
import { ReactComponent as BasicPlan } from "Assets/icons/appStreaming/basic-plan.svg";
import { ReactComponent as ProPlan } from "Assets/icons/appStreaming/pro-plan.svg";
import { ReactComponent as EnterprisePlan } from "Assets/icons/appStreaming/enterprise-plan.svg";
import { ReactComponent as IpAddress } from "Assets/icons/appStreaming/ip-address.svg";
import { ReactComponent as Google } from "Assets/icons/google.svg";
import { ReactComponent as Camera } from "Assets/icons/camera.svg";
import { ReactComponent as Shell } from "Assets/icons/shell.svg";
import { ReactComponent as Stack } from "Assets/icons/stack.svg";
import { ReactComponent as Monitoring } from "Assets/icons/organizations/monitoring.svg";
import { ReactComponent as Logs } from "Assets/icons/appStreaming/logs.svg";
import { ReactComponent as VrHeadset } from "Assets/icons/vr-headset.svg";
import { ReactComponent as TwoSquares } from "Assets/icons/appStreaming/two-squares.svg";
import { ReactComponent as FourSquares } from "Assets/icons/appStreaming/four-squares.svg";
import { ReactComponent as Check2D } from "Assets/icons/check-2d.svg";
import { ReactComponent as RoundedCheck } from "Assets/icons/rounded-check.svg";
import { ReactComponent as MultipleUser } from "Assets/icons/appStreaming/multiple-user.svg";
import { ReactComponent as SingleUser } from "Assets/icons/appStreaming/single-user.svg";
import { ReactComponent as Flame } from "Assets/icons/flame.svg";

import "./Icon.style.scss";
import { classnames } from "Utils";

const ICONS = {
  Default: NoIcon,
  api: Api,
  applications: Applications,
  "arrow-left-small": ArrowLeftSmall,
  "arrow-right": ArrowRight,
  "arrow-right-small": ArrowRightSmall,
  "aqua-check": AquaCheck,
  "bold-cross": BoldCross,
  bolt: Bolt,
  calculator: Calculator,
  check: Check,
  "check-mark": CheckMark,
  "chevron-down": ChevronDown,
  "chevron-down-circle": ChevronDownCircle,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  "chevron-up-circle": ChevronUpCircle,
  close: Close,
  clock: Clock,
  cloud: Cloud,
  "cloud-import": CloudImport,
  copy: Copy,
  "copy-link": CopyLink,
  "copy-to": CopyToClipboard,
  display: Display,
  error: ErrorIcon,
  "eye-closed": EyeClosedIcon,
  "eye-open": EyeOpenIcon,
  facebook: Facebook,
  file: File,
  "file-copy": FileCopy,
  "file-arrow-left": FileArrowLeft,
  "file-white": FileWhite,
  files: Files,
  "file-desync": FileCancelSync,
  filter: Filter,
  "file-doc": FileDOC,
  "file-doc-white": FileDOCWhite,
  "file-drag": FileDrag,
  "file-download": FileDownload,
  "file-img": FileImage,
  "file-img-white": FileImageWhite,
  "file-psd": FilePSD,
  "file-psd-white": FilePSDWhite,
  "file-video": FileVideo,
  "file-video-white": FileVideoWhite,
  "file-rename": FileRename,
  "file-upload": FileUpload,
  "files-upload": FilesUpload,
  "file-sync": FileSync,
  "file-sync-left": FileSyncLeft,
  "file-select": FileSelect,
  "file-zip": FileZip,
  "file-zip-white": FileZipWhite,
  folder: Folder,
  "folder-white": FolderWhite,
  "folder-create": FolderCreate,
  "shared-folder": SharedFolder,
  "member-folder": MemberFolder,
  fullscreen: Fullscreen,
  info: InfoIcon,
  individual: Individual,
  keyboard: Keyboard,
  "keyboard-mouse": KeyboardMouse,
  "left-arrow": LeftArrowIcon,
  loader: LoaderIcon,
  lock: Lock,
  more: More,
  messenger: Messenger,
  microphone: Microphone,
  pen: Pen,
  play: PlayIcon,
  pause: Pause,
  plus: Plus,
  reload: Reload,
  refresh: Refresh,
  undef: Undef,
  trash: Trash,
  "teams-member": TeamsMember,
  "teams-caret-down": TeamsCaretDown,
  "teams-caret-up": TeamsCaretUp,
  "teams-plus": TeamsPlus,
  twitter: Twitter,
  "send-vagon": SendVagon,
  "scroll-down": ScrollDown,
  "scroll-up": ScrollUp,
  "sound-mute": SoundMute,
  "sound-on": SoundOn,
  "new-fullscreen": NewFullScreen,
  "active-fullscreen": ActiveFullScreen,
  gamepad: GamePad,
  share: Share,
  search: Search,
  settings: Settings,
  star: Star,
  "vagon-icon": VagonIcon,
  "vagon-logo": VagonLogo,
  "upload-to-cloud": UploadToCloud,
  "upload-to-workstation": UploadToWorkstation,
  whatsapp: Whatsapp,
  team: Team,
  telegram: Telegram,
  dashboard: Dashboard,
  infrastructure: Infrastructure,
  test: AppInTest,
  payment: Payment,
  plusCircle: PlusCircle,
  message: Message,
  "location-pin": LocationPin,
  "inactive-region": InactiveRegion,
  hourglass: Hourglass,
  calendar: Calendar,
  "caret-right": CaretRight,
  "caret-down": CaretDown,
  "caret-up": CaretUp,
  "arrow-up": ArrowUp,
  "view-mode": ViewMode,
  monitor: Monitor,
  warning: Warning,
  "right-empty-caret": RightEmptyCaret,
  "stream-check": StreamCheck,
  "apps-check": AppsCheck,
  "stream-play": StreamPlay,
  "stream-pause": StreamPause,
  pencil: Pencil,
  "stream-settings": StreamSettings,
  "play-button": PlayButton,
  streams: Streams,
  stats: Stats,
  document: Document,
  "triangle-error": TriangleError,
  linux: Linux,
  "signal-weak": SignalWeak,
  "signal-low": SignalLow,
  "signal-good": SignalGood,
  "signal-strong": SignalStrong,
  desktop: Desktop,
  mobile: Mobile,
  tablet: Tablet,
  reset: Reset,
  "create-computers": CreateComputers,
  "add-user": AddUser,
  "teams-check": TeamsCheck,
  "organizations-check": OrganizationsCheck,
  "organizations-cross": OrganizationsCross,
  "organizations-user": OrganizationsUser,
  lightning: Lightning,
  "pixel-streaming": PixelStreaming,
  "render-streaming": RenderStreaming,
  "stream-sound-on": AppStreamingSoundOn,
  "stream-sound-off": AppStreamingSoundOff,
  "application-library": ApplicationLibrary,
  "select-computer": SelectComputer,
  "copy-computer": CopyComputer,
  connect: Connect,
  "computer-logo": ComputerLogo,
  "streams-logo": StreamsLogo,
  "teams-logo": TeamsLogo,
  "basic-plan": BasicPlan,
  "pro-plan": ProPlan,
  "enterprise-plan": EnterprisePlan,
  "ip-address": IpAddress,
  google: Google,
  camera: Camera,
  shell: Shell,
  stack: Stack,
  monitoring: Monitoring,
  logs: Logs,
  "vr-headset": VrHeadset,
  "two-squares": TwoSquares,
  "four-squares": FourSquares,
  "check-2d": Check2D,
  "rounded-check": RoundedCheck,
  "multiple-user": MultipleUser,
  "single-user": SingleUser,
  flame: Flame,
};

const Icon = (props) => {
  const {
    className: classNameFromProps,
    color,
    name,
    onClick,
    hoverText,
    small,
    smaller,
    smallest,
    tiny,
    iconRef,
    disabled,
    large,
    ...rest
  } = props;

  const IconComponent = ICONS[name] || ICONS.Default;
  const className = classnames([
    "vg-icon",
    classNameFromProps,
    name,
    color,
    small && "small",
    smaller && "smaller",
    smallest && "smallest",
    tiny && "tiny",
    large && "large",
    onClick && !disabled && "clickable",
    disabled && "disabled",
  ]);

  return <IconComponent ref={iconRef} className={className} onClick={onClick} {...rest} />;
};

export default Icon;
