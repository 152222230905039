import React from "react";
import { Dropdown, HoverableTooltip, Icon, Switch } from "UI";
import { LINUX_RESOLUTION_TYPES, OS, RESOLUTION_TYPES, RS_RESOLUTION_TYPES } from "Constants/global.constants";
import {
  JOYSTICK_OPTIONS,
  KEYBOARD_LAYOUTS,
  PS_SOUND_AND_MICROPHONE_OPTIONS,
  SOUND_AND_MICROPHONE_OPTIONS,
  STREAM_TYPES,
} from "Constants/AppStreaming.constants";
import { classnames } from "Utils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import routes from "Constants/Route.constants";

const PixelStreamingNotSupported = ({ streamConfigs, translate }) => {
  return (
    streamConfigs?.pixelStreamingEnabled && (
      <HoverableTooltip content={translate("pixelStreamingNotSupported")} side="right">
        <Icon name="info" color="gray-4" smaller />
      </HoverableTooltip>
    )
  );
};

const RenderStreamingNotSupported = ({ streamConfigs, translate }) => {
  return (
    streamConfigs?.renderStreamingEnabled && (
      <HoverableTooltip content={translate("renderStreamingNotSupported")} side="right">
        <Icon name="info" color="gray-4" smaller />
      </HoverableTooltip>
    )
  );
};

const AccessibilityStep = ({ streamConfigs, setStreamConfigs, isVendorEnterprise, streamType, translate }) => {
  const parentStream = streamType === STREAM_TYPES.parent;
  const childStream = streamType === STREAM_TYPES.child;
  const { os } = streamConfigs;

  const setResolutionTypes = () => {
    if (streamConfigs?.renderStreamingEnabled) {
      return RS_RESOLUTION_TYPES;
    }
    if (os === OS.linux) {
      return LINUX_RESOLUTION_TYPES;
    }
    return RESOLUTION_TYPES;
  };

  return (
    <>
      <div className={classnames(["configuration-option switch-dropdown", parentStream && "disabled"])}>
        <div>
          <p className="header">{translate("configureStream.configurations.audio.header")}</p>
          <p className="description">{translate("configureStream.configurations.audio.description")}</p>
        </div>

        <div className="input-box">
          <Dropdown
            underlined
            defaultSelected={streamConfigs?.sound}
            options={Object.keys(SOUND_AND_MICROPHONE_OPTIONS)}
            mapFunction={(option) => {
              return translate(`configureStream.configurations.audio.options.${option}`);
            }}
            handleSelectedOptionChange={(e) => {
              setStreamConfigs({ sound: e });
            }}
            disabled={streamConfigs?.pixelStreamingEnabled || streamConfigs?.renderStreamingEnabled}
          />
        </div>
      </div>

      <div className={classnames(["configuration-option switch-dropdown", parentStream && "disabled"])}>
        <div>
          <div className="with-icon">
            <p className="header">{translate("configureStream.configurations.microphone.header")}</p>
          </div>

          <p className="description">{translate("configureStream.configurations.microphone.description")}</p>
        </div>
        <div className="input-box">
          <Dropdown
            underlined
            defaultSelected={streamConfigs?.microphone}
            options={Object.keys(
              streamConfigs?.pixelStreamingEnabled || streamConfigs?.renderStreamingEnabled
                ? PS_SOUND_AND_MICROPHONE_OPTIONS
                : SOUND_AND_MICROPHONE_OPTIONS,
            )}
            mapFunction={(option) => {
              return translate(`configureStream.configurations.microphone.options.${option}`);
            }}
            handleSelectedOptionChange={(e) => {
              setStreamConfigs({ microphone: e });
            }}
            disabled={streamConfigs?.renderStreamingEnabled}
          />
        </div>
      </div>
      <div className={classnames(["configuration-option switch-dropdown", parentStream && "disabled"])}>
        <div>
          <p className="header">{translate("configureStream.configurations.resolution.header")}</p>
          <p className="description">{translate("configureStream.configurations.resolution.description")}</p>
        </div>
        <div className="input-box">
          <Dropdown
            underlined
            options={setResolutionTypes()}
            defaultSelected={streamConfigs?.resolution}
            mapFunction={(option) => {
              if (streamConfigs?.pixelStreamingEnabled && option === RESOLUTION_TYPES[0]) {
                return translate("configureStream.configurations.resolution.options.appManaged");
              }
              return translate(`configureStream.configurations.resolution.options.${option}`);
            }}
            handleSelectedOptionChange={(e) => setStreamConfigs({ resolution: e })}
          />
        </div>
      </div>

      <div className={classnames(["configuration-option switch-dropdown", parentStream && "disabled"])}>
        <div>
          <div className="with-icon">
            <p className="header">{translate("configureStream.configurations.keyboardLayout.header")}</p>
            <PixelStreamingNotSupported streamConfigs={streamConfigs} translate={translate} />
            <RenderStreamingNotSupported streamConfigs={streamConfigs} translate={translate} />
          </div>

          <p className="description">{translate("configureStream.configurations.keyboardLayout.description")}</p>
        </div>
        <div className="input-box">
          <Dropdown
            underlined
            options={Object.values(KEYBOARD_LAYOUTS)}
            defaultSelected={streamConfigs?.keyboardLayout || KEYBOARD_LAYOUTS.us}
            mapFunction={(option) => {
              return translate(`configureStream.configurations.keyboardLayout.options.${option}`);
            }}
            handleSelectedOptionChange={(e) => setStreamConfigs({ keyboardLayout: e })}
            disabled={
              streamConfigs.os === OS.linux ||
              streamConfigs?.pixelStreamingEnabled ||
              streamConfigs?.renderStreamingEnabled
            }
          />
        </div>
      </div>

      <div className={classnames(["configuration-option switch-dropdown", parentStream && "disabled"])}>
        <div>
          <div className="with-icon">
            <p className="header">{translate("configureStream.configurations.joystick.header")}</p>
            <PixelStreamingNotSupported streamConfigs={streamConfigs} translate={translate} />
            <RenderStreamingNotSupported streamConfigs={streamConfigs} translate={translate} />
          </div>

          <p className="description">{translate("configureStream.configurations.joystick.description")}</p>
        </div>
        <div className="input-box">
          <Dropdown
            underlined
            options={Object.values(JOYSTICK_OPTIONS)}
            defaultSelected={streamConfigs?.joystick}
            mapFunction={(option) => {
              return translate(`configureStream.configurations.joystick.options.${option}`);
            }}
            handleSelectedOptionChange={(e) => setStreamConfigs({ joystick: e })}
            disabled={
              streamConfigs.os === OS.linux ||
              streamConfigs?.pixelStreamingEnabled ||
              streamConfigs?.renderStreamingEnabled
            }
          />
        </div>
      </div>

      {os === OS.windows && (
        <div
          className={classnames([
            "configuration-option",
            (!isVendorEnterprise || childStream || streamConfigs?.os === OS.linux) && "disabled",
          ])}
        >
          <div className="with-badge">
            <Switch
              enterprise
              reverseText
              className="header"
              text={translate("configureStream.configurations.portAccess.header")}
              onChange={(e) => setStreamConfigs({ portAccessEnabled: e })}
              checked={streamConfigs.portAccessEnabled}
              disabled={streamConfigs.sumOfAllUsedCapacities > 0}
            />

            <HoverableTooltip
              content={
                <p className="port-access-tooltip-content">
                  Check <Link to={{ pathname: routes.appStreamingUsers, state: { showMachines: true } }}>Stats</Link>{" "}
                  page to learn <br />
                  Stream Machine IP Address
                </p>
              }
              side="top"
            >
              <Icon name="info" color="gray-4" smaller style={{ marginLeft: "0.4rem" }} />
            </HoverableTooltip>
            <div className="enterprise-badge">Enterprise</div>
          </div>
          <p className="description">{translate("configureStream.configurations.portAccess.description")}</p>
        </div>
      )}
    </>
  );
};

export default AccessibilityStep;
