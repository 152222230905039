import { CAPACITY_MANAGEMENT_TYPES } from "Constants/global.constants";
import React, { useMemo } from "react";
import { DivButton, HoverableTooltip, Icon, TextButton } from "UI";
import { classnames } from "Utils";

import "./CapacityManagement.styles.scss";

const CapacityManagement = ({
  isVendorEnterprise,
  fastConnectionAvailable,
  isLinux,
  capacityManagement,
  setCapacityManagement,
  canRequestSubscription,
  pendingSubscriptionRequest,
  setShowCreateStreamModal,
  setShowSubscriptionRequestModal,
  automatedAreaNames,
  translate,
}) => {
  const noAutomatedArea = useMemo(() => {
    return !automatedAreaNames || automatedAreaNames.length === 0;
  }, [automatedAreaNames]);

  const disabledReasonText = useMemo(() => {
    if (!isVendorEnterprise) {
      return "Automated Setup is only \navailable for Enterprise Plan users";
    }
    if (!fastConnectionAvailable) {
      return "Automated Setup isn't available\nfor your performance selection";
    }

    return null;
  }, [isVendorEnterprise, fastConnectionAvailable]);
  return (
    <>
      <div className="management-selection">
        <HoverableTooltip side="right" content={disabledReasonText}>
          <DivButton
            className={classnames([
              "management-selection-card",
              !isVendorEnterprise && !canRequestSubscription && "not-allowed",
              !fastConnectionAvailable && "not-allowed",
              noAutomatedArea && "not-allowed",
              isLinux && "not-allowed",
              capacityManagement === CAPACITY_MANAGEMENT_TYPES.fast_connection && "selected",
            ])}
            onClick={() => {
              if (canRequestSubscription && !isVendorEnterprise) {
                setShowCreateStreamModal(false);
                setShowSubscriptionRequestModal(true);
                return;
              }
              setCapacityManagement(CAPACITY_MANAGEMENT_TYPES.fast_connection);
            }}
          >
            <div className="management-selection-header automated">
              <div>
                <div className="management-selection-header-title">
                  <Icon name="flame" smaller />
                  <h1>
                    {translate("appStreamingModals.stream.capacityManagement.fastConnection.header")}
                    <span>New</span>
                  </h1>
                </div>

                <p>{translate("appStreamingModals.stream.capacityManagement.fastConnection.description")}</p>
              </div>
              <div className="enterprise-badge">Enterprise</div>
            </div>
            <div className="management-selection-footer">
              <div className="items">
                <div>
                  <Icon name="single-user" color="green" smaller />
                  <p>{translate("appStreamingModals.stream.capacityManagement.fastConnection.benefits.0")}</p>
                </div>
              </div>
              {(canRequestSubscription || pendingSubscriptionRequest) && (
                <TextButton
                  color="aqua-dark"
                  text={pendingSubscriptionRequest ? "Pending Request" : "Request for Trial →"}
                  onClick={() => {
                    setShowCreateStreamModal(false);
                    setShowSubscriptionRequestModal(true);
                  }}
                  disabled={pendingSubscriptionRequest}
                />
              )}
            </div>
          </DivButton>
        </HoverableTooltip>

        <DivButton
          className={classnames([
            "management-selection-card",
            capacityManagement === CAPACITY_MANAGEMENT_TYPES.high_demand && "selected",
          ])}
          onClick={() => {
            setCapacityManagement(CAPACITY_MANAGEMENT_TYPES.high_demand);
          }}
        >
          <div className="management-selection-header">
            <h1>{translate("appStreamingModals.stream.capacityManagement.highDemand.header")}</h1>
            <p>{translate("appStreamingModals.stream.capacityManagement.highDemand.description")}</p>
          </div>
          <div className="management-selection-footer">
            <div className="items">
              <div>
                <Icon name="multiple-user" color="green" smaller />
                <p>{translate("appStreamingModals.stream.capacityManagement.highDemand.benefits.0")}</p>
              </div>
            </div>
          </div>
        </DivButton>
      </div>
    </>
  );
};

export default CapacityManagement;
