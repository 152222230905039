import { useState, useEffect } from "react";
import { useTimezoneSelect } from "react-timezone-select";

const useMatchTimeZone = (userTimeZone) => {
  const [timezoneLabel, setTimezoneLabel] = useState("(UTC+0:00) UTC");

  const timezones = getSupportedTimeZones();

  const { options } = useTimezoneSelect({ labelStyle: "original", timezones, displayValue: "UTC" });

  useEffect(() => {
    if (options.length > 0) {
      const foundOption = options.find((opt) => opt.value === userTimeZone);
      const label = userTimeZone ? foundOption?.label.replace(/\//g, " / ").replace(/_/g, " ") : "(UTC+0:00) UTC";

      setTimezoneLabel(label);
    }
  }, [userTimeZone, options]);

  return timezoneLabel;
};

export const getSupportedTimeZones = () => {
  if (typeof Intl.supportedValuesOf === "function") {
    return Intl.supportedValuesOf("timeZone").reduce((acc, timezone) => {
      acc[timezone] = timezone;
      return acc;
    }, {});
  }
  return [];
};

export default useMatchTimeZone;
