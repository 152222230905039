import React from "react";
import { useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { ConfirmationModal } from "UI";
import { apiGenerator } from "Utils";
import ModalResult from "Components/Shared/ModalResult/ModalResult.component";

const RemoveUserModal = ({ user, onClose, translate }) => {
  const removeUserMutation = useMutation({
    mutationFn: (data) => {
      return apiGenerator("delete")(API_ENDPOINTS.ORGANIZATION_USER(user?.id), data);
    },
  });

  if (removeUserMutation.isSuccess) {
    return (
      <ModalResult
        success={removeUserMutation.isSuccess}
        successHeader={translate("organizationHome.confirmationModals.removeUser.success.header")}
        successDescription={translate("organizationHome.confirmationModals.removeUser.success.description")}
        onClose={onClose}
      />
    );
  }

  return (
    <ConfirmationModal
      headerText={translate("organizationHome.confirmationModals.removeUser.confirmation.header")}
      descriptionText={
        <div className="show-revoke-description-content">
          <p>{translate("organizationHome.confirmationModals.removeUser.confirmation.description")}</p>
        </div>
      }
      closeAction={() => {
        removeUserMutation.reset();
        onClose();
      }}
      confirmText="Confirm"
      confirmAction={() => {
        removeUserMutation.mutate();
      }}
      pending={removeUserMutation.isLoading}
    />
  );
};

export default RemoveUserModal;
