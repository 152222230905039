import React from "react";
import { useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { ConfirmationModal } from "UI";
import { apiGenerator } from "Utils";
import ModalResult from "Components/Shared/ModalResult/ModalResult.component";
import { Translate } from "react-localize-redux";
import moment from "moment";

const DeleteComputerModal = ({ seat, onClose, willCancelSubscription, translate }) => {
  const setComputerForDeletionMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATION_SEATS_RENEWAL(seat.id));
    },
  });

  const { subscription_end_at: subscriptionEndAt } = seat?.attributes || {};

  if (setComputerForDeletionMutation.isSuccess) {
    return (
      <ModalResult
        success={setComputerForDeletionMutation.isSuccess}
        successHeader={translate("organizationHome.confirmationModals.deleteComputer.success.header")}
        successDescription={translate("organizationHome.confirmationModals.deleteComputer.success.description")}
        onClose={onClose}
      />
    );
  }

  return (
    <ConfirmationModal
      headerText={translate("organizationHome.confirmationModals.deleteComputer.confirmation.header")}
      descriptionText={
        <Translate
          id={`organizationHome.confirmationModals.deleteComputer.confirmation.willCancelSubscription.${!!willCancelSubscription}`}
          options={{
            renderInnerHtml: true,
          }}
          data={{ date: subscriptionEndAt ? moment(subscriptionEndAt).format("MMMM Do YYYY") : "" }}
        />
      }
      closeAction={() => {
        setComputerForDeletionMutation.reset();
        onClose();
      }}
      confirmText="Confirm"
      confirmAction={() => {
        setComputerForDeletionMutation.mutate();
      }}
      pending={setComputerForDeletionMutation.isLoading}
    />
  );
};

export default DeleteComputerModal;
