import React, { useEffect, useRef } from "react";
import { GAME_ENGINES, STREAM_TYPES } from "Constants/AppStreaming.constants";
import { OS } from "Constants/global.constants";
import { Dropdown, HoverableTooltip, Icon, Input, Switch } from "UI";

import "./AdvancedStep.styles.scss";
import { classnames } from "Utils";

const AdvancedStep = ({ streamConfigs, setStreamConfigs, streamType, initialStreamConfigs, translate }) => {
  const {
    autoStart,
    restartApplication,
    pixelStreamingEnabled,
    os,
    sumOfAllUsedCapacities,
    collectApplicationLogs,
    gameEngine,
    companyName,
    productName,
    projectName,
    renderStreamingEnabled,
    executableCompanyName,
    executableProductName,
    executableProjectName,
    setLogPathsAutomatically,
  } = streamConfigs;

  const advancedConfigsDisabled =
    streamType !== STREAM_TYPES.regular ||
    pixelStreamingEnabled ||
    renderStreamingEnabled ||
    os !== OS.windows ||
    sumOfAllUsedCapacities > 0;

  const collectApplicationLogsDisabled = streamType !== STREAM_TYPES.regular || os !== OS.windows;
  const productNameRef = useRef(null);
  const companyNameRef = useRef(null);
  const initialRender = useRef(true);
  const projectNameRef = useRef(null);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (collectApplicationLogs) {
      if (gameEngine === GAME_ENGINES.unity && (companyName === null || companyName === "") && companyNameRef.current) {
        companyNameRef.current.focus();
      } else if (
        gameEngine === GAME_ENGINES.unreal &&
        (projectName === null || projectName === "") &&
        projectNameRef.current
      ) {
        projectNameRef.current.focus();
      }
    }
  }, [collectApplicationLogs, gameEngine]);

  useEffect(() => {
    if (!collectApplicationLogs) {
      setStreamConfigs({
        companyName: initialStreamConfigs.companyName,
        productName: initialStreamConfigs.productName,
        gameEngine: initialStreamConfigs.gameEngine,
      });
    }
  }, [collectApplicationLogs]);

  const tooltipText =
    sumOfAllUsedCapacities > 0
      ? translate("configureStream.tooltips.runningMachinePresent")
      : translate("configureStream.tooltips.featureNotAvailable");

  return (
    <div className="advanced-step">
      <div className="configuration-option">
        <div className="with-icon">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.autoStart.header")}
            checked={autoStart}
            disabled={advancedConfigsDisabled}
            onChange={(e) => {
              setStreamConfigs({ autoStart: e });
            }}
          />
          {advancedConfigsDisabled && (
            <HoverableTooltip content={<p>{tooltipText}</p>} side="top">
              <Icon name="info" color="gray-4" smaller style={{ marginLeft: "0.4rem" }} />
            </HoverableTooltip>
          )}
        </div>
        <p className="description">{translate("configureStream.configurations.autoStart.description")}</p>
      </div>
      <div className="configuration-option">
        <div className="with-icon">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.restartApplication.header")}
            checked={restartApplication}
            disabled={advancedConfigsDisabled}
            onChange={(e) => {
              setStreamConfigs({ restartApplication: e });
            }}
          />
          {advancedConfigsDisabled && (
            <HoverableTooltip content={<p>{tooltipText}</p>} side="top">
              <Icon name="info" color="gray-4" smaller style={{ marginLeft: "0.4rem" }} />
            </HoverableTooltip>
          )}
        </div>
        <p className="description">{translate("configureStream.configurations.restartApplication.description")}</p>
      </div>
      {/* <div className="configuration-option">
        <div className="with-icon">
          <Switch
            enterprise
            reverseText
            className="header"
            text={translate("configureStream.configurations.collectApplicationLogs.header")}
            checked={collectApplicationLogs}
            onChange={(e) => {
              setStreamConfigs({ collectApplicationLogs: e });
            }}
            disabled={streamConfigs.renderStreamingEnabled}
          />
          {streamConfigs.renderStreamingEnabled && (
            <HoverableTooltip content={<Translate id="configureStream.tooltips.featureNotAvailable" />} side="right">
              <Icon name="info" smaller color="gray-4" style={{ marginLeft: "0.4rem" }} />
            </HoverableTooltip>
          )}
        </div>
        <p className="description">{translate("configureStream.configurations.collectApplicationLogs.description")}</p>
      </div> */}

      {os === OS.windows && (
        <div className="configuration-option dropdown">
          <div>
            <p className="header">{translate("configureStream.configurations.collectApplicationLogs.header")}</p>
            <p className="description">
              {translate("configureStream.configurations.collectApplicationLogs.description")}
            </p>
          </div>
          <div className="input-box">
            <Dropdown
              underlined
              defaultSelected={collectApplicationLogs ? gameEngine : GAME_ENGINES.do_not_collect}
              options={Object.keys(GAME_ENGINES)}
              handleSelectedOptionChange={(option) => {
                if (option === GAME_ENGINES.do_not_collect) {
                  setStreamConfigs({ collectApplicationLogs: false });
                } else {
                  setStreamConfigs({ collectApplicationLogs: true });
                  setStreamConfigs({ gameEngine: option });
                  if (executableCompanyName || executableProductName || executableProjectName) {
                    setStreamConfigs({ setLogPathsAutomatically: true });
                  }
                }
              }}
              disabled={collectApplicationLogsDisabled || streamConfigs.renderStreamingEnabled}
              mapFunction={(option) => translate(`configureStream.configurations.gameEngine.options.${option}`)}
            />
          </div>
        </div>
      )}

      {collectApplicationLogs && (
        <>
          <div className="configuration-option">
            <div className="with-icon">
              <Switch
                enterprise
                reverseText
                className="header"
                text={translate("configureStream.configurations.setLogPathsAutomatically.header")}
                checked={setLogPathsAutomatically}
                onChange={(e) => {
                  setStreamConfigs({ setLogPathsAutomatically: e });
                }}
              />
            </div>
            <p className="description">
              {translate("configureStream.configurations.setLogPathsAutomatically.description")}
            </p>
          </div>
          <div className="game-engine-inputs">
            {gameEngine === GAME_ENGINES.unity && !setLogPathsAutomatically && (
              <div className={classnames(["configuration-option dropdown", setLogPathsAutomatically && "disabled"])}>
                <div className="text-content">
                  <p className="header">{translate("configureStream.configurations.companyName.title")}</p>
                </div>
                <div className="input-box">
                  <Input
                    value={companyName}
                    onChange={(e) => setStreamConfigs({ companyName: e.target.value })}
                    inputRef={companyNameRef}
                    disabled={setLogPathsAutomatically}
                  />
                </div>
              </div>
            )}
            {gameEngine === GAME_ENGINES.unity && !setLogPathsAutomatically && (
              <div className={classnames(["configuration-option dropdown", setLogPathsAutomatically && "disabled"])}>
                <div className="text-content">
                  <p className="header">{translate("configureStream.configurations.productName.title")}</p>
                </div>

                <div className="input-box">
                  <Input
                    value={productName}
                    onChange={(e) => setStreamConfigs({ productName: e.target.value })}
                    inputRef={productNameRef}
                    disabled={setLogPathsAutomatically}
                  />
                </div>
              </div>
            )}
            {gameEngine === GAME_ENGINES.unreal && !setLogPathsAutomatically && (
              <div className={classnames(["configuration-option dropdown", setLogPathsAutomatically && "disabled"])}>
                <div className="text-content">
                  <p className="header">{translate("configureStream.configurations.projectName.title")}</p>
                </div>

                <div className="input-box">
                  <Input
                    value={projectName}
                    onChange={(e) => setStreamConfigs({ projectName: e.target.value })}
                    inputRef={projectNameRef}
                    disabled={setLogPathsAutomatically}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AdvancedStep;
