import React, { useState } from "react";
import { useSelector } from "react-redux";
import { groupOrganizationMachineTypes } from "Utils/Helpers/functions.helpers";
import { ConfirmationModal, Modal, TextButton } from "UI";
import { useMutation } from "@tanstack/react-query";
import { apiGenerator } from "Utils";
import _ from "lodash";
import { API_ENDPOINTS } from "Constants/api.constants";
import { withLocalize } from "react-localize-redux";
import PerformanceOptionsTable from "../ConfigurePlan/PerformanceOptionsTable/PerformanceOptionsTable.component";
import ModalResult from "../../../../Shared/ModalResult/ModalResult.component";

import "./UpdatePerformancesModal.styles.scss";

const UpdatePerformancesModal = ({ seat, plan, translate, onClose }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [saveForAll, setSaveForAll] = useState(false);
  const currentMachineTypes = plan?.attributes?.machine_types?.map((machineType) => {
    return machineType.id;
  });

  const { assigned_seats: assignedSeats } = plan?.attributes || {};

  const [selectedMachineTypes, setSelectedMachineTypes] = useState(currentMachineTypes);

  const { organizationPlan } = useSelector((state) => state.organizations?.getOrganizationPlanCTX) || {};

  const machineTypes = organizationPlan?.attributes?.pricing?.map((pricing) => {
    return pricing.attributes.machine_type;
  });

  const groupedMachineTypes = groupOrganizationMachineTypes(machineTypes || []);

  const updateMachineTypesMutation = useMutation({
    mutationFn: () => {
      const data = {
        machine_type_ids: selectedMachineTypes,
        seat_id: !seat || (seat && saveForAll) ? null : seat?.id,
      };

      return apiGenerator("put")(API_ENDPOINTS.ORGANIZATION_SEAT_PLANS_MACHINE_TYPES(plan.id), data);
    },
  });

  if (updateMachineTypesMutation.isSuccess) {
    return (
      <ModalResult
        success={updateMachineTypesMutation.isSuccess}
        successHeader={translate("updatePerformancesModal.result.success.header")}
        successDescription={translate("updatePerformancesModal.result.success.description")}
        failureHeader={translate("updatePerformancesModal.result.error.header")}
        failureDescription={translate("updatePerformancesModal.result.error.description")}
        onClose={onClose}
      />
    );
  }

  if (showConfirmation) {
    return (
      <ConfirmationModal
        className="update-performances-modal"
        headerText={translate("updatePerformancesModal.confirmation.header")}
        descriptionText={
          <div className="content">
            <p>
              {translate(
                `updatePerformancesModal.confirmation.description.${seat && !saveForAll ? "individual" : "team"}`,
                {
                  seatName: seat?.attributes?.friendly_name,
                  assigneeCount: assignedSeats?.length,
                },
              )}
            </p>
            {seat && assignedSeats && assignedSeats.length > 1 && (
              <div className="save-for-all-checkbox">
                <input
                  type="checkbox"
                  value={saveForAll}
                  defaultChecked={saveForAll}
                  onChange={(e) => {
                    setSaveForAll(e.target.checked);
                  }}
                />
                <span>{translate("updatePerformancesModal.confirmation.checkBox")}</span>
              </div>
            )}
          </div>
        }
        closeAction={onClose}
        confirmText="Confirm"
        confirmAction={() => {
          updateMachineTypesMutation.mutate();
        }}
        pending={updateMachineTypesMutation.isLoading}
        success={updateMachineTypesMutation.isSuccess}
      />
    );
  }

  return (
    <Modal
      headerText={translate("updatePerformancesModal.header")}
      descriptionText={translate(`updatePerformancesModal.description.${seat ? "individual" : "team"}`, {
        seatName: seat?.attributes?.friendly_name,
      })}
      topRightIconAction={onClose}
      topRightIcon="close"
      className="update-performances-modal"
    >
      <PerformanceOptionsTable
        selectedMachineTypes={selectedMachineTypes}
        setSelectedMachineTypes={setSelectedMachineTypes}
        groupedMachineTypes={groupedMachineTypes}
        translate={translate}
      />
      <div className="buttons-container">
        <TextButton text="Cancel" color="gray-3" onClick={onClose} />
        <TextButton
          text="Save Selection"
          color="aqua-main"
          onClick={() => {
            if (assignedSeats && assignedSeats.length === 0) {
              updateMachineTypesMutation.mutate();
              return;
            }
            setShowConfirmation(true);
          }}
          disabled={selectedMachineTypes.length === 0 || _.isEqual(currentMachineTypes, selectedMachineTypes)}
        />
      </div>
    </Modal>
  );
};

export default withLocalize(UpdatePerformancesModal);
