import React, { useEffect } from "react";
import { Button, TopLoader, IconButton, TextButton } from "UI";
import { useClick } from "Utils/Hooks";
import { Animation, classnames, isMobile } from "Utils";

import "./Modal.style.scss";

const Modal = (props) => {
  const {
    className,
    closeOnOverlayClick,
    closeAction,
    topRightIcon,
    topRightIconAction,
    headerText,
    headerColor,
    descriptionText,
    buttonText,
    buttonAction,
    buttonDisabled,
    overrideButton,
    success,
    successText,
    errorText,
    errorable,
    children,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    blue,
    color,
    centered,
    centeredContent,
    small,
    big,
    mobile,
    fitContent,
    fullSize,
    isPending,
    invisibleOverlay,
    steps,
    showSidebar,
    currentStep,
    setCurrentStep,
    indexOffset = 0,
    contents,
    overlayClassName,
    sidebarFooter,
  } = props;

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        closeAction();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [closeAction]);

  const overlayRef = useClick(null, () => {
    if (closeOnOverlayClick && closeAction) {
      closeAction();
    }
  });

  const modalContainerClassName = classnames([
    "modal-container",
    className,
    fitContent && "fit-content",
    fullSize && "full-size",
    small && "small",
    big && "big",
    centered && "centered",
    showSidebar && "with-sidebar",
  ]);

  const modalActionClassName = classnames(["modal-action-wrapper", errorable && "errorable", centered && "centered"]);

  return (
    <div
      className={classnames([
        "modal-overlay",
        invisibleOverlay && "invisible-overlay",
        mobile && "mobile",
        overlayClassName && overlayClassName,
      ])}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      ref={overlayRef}
    >
      <div className={modalContainerClassName}>
        {isPending && (
          <div className="modal-pending-loader">
            <TopLoader isInsideComponent />
          </div>
        )}
        {topRightIcon && <IconButton name={topRightIcon} onClick={topRightIconAction} className="top-right-icon" />}
        <div className={classnames(["content-container", !isMobile && showSidebar && "with-sidebar"])}>
          {!isMobile && showSidebar && (
            <>
              <div className="modal-sidebar-container">
                <div className="modal-sidebar">
                  <div className="bar" />
                  <div className="steps">
                    {steps &&
                      Object.keys(steps).map((step, index) => {
                        let colorClass = "";
                        if (index === currentStep) {
                          colorClass = "current";
                        } else if (index < currentStep) {
                          colorClass = "previous";
                        }

                        if (
                          !steps[step].skipStep &&
                          (!steps[step].showOnCurrentStep || currentStep === Object.keys(steps).length - 1)
                        ) {
                          return (
                            <TextButton
                              key={step}
                              disabled={
                                contents[steps[step].step - 1 - indexOffset]?.disabled ||
                                steps[step].disabled ||
                                currentStep === Object.keys(steps).length - 1
                              }
                              className={classnames(["modal-step", colorClass])}
                              text={contents[steps[step].step]?.overrideStepName || steps[step].name || step}
                              onClick={() => {
                                if (index < currentStep || index - 1 === currentStep) {
                                  setCurrentStep(index - indexOffset);
                                }
                              }}
                            />
                          );
                        }
                        return null;
                      })}
                  </div>
                </div>
                {Boolean(sidebarFooter) && <div className="sidebar-footer">{sidebarFooter}</div>}
              </div>
              <div className="vertical-divider" />
            </>
          )}
          <div className={classnames(["modal-content", centeredContent && "centered-content"])}>
            {headerText && <div className={classnames(["header", headerColor])}>{headerText}</div>}
            {success ? (
              <div className="modal-success-content">
                <Animation type="success" style={{ height: "6rem", width: "6rem" }} /> {successText}
              </div>
            ) : (
              <>
                {descriptionText && <div className="description">{descriptionText}</div>}
                {children}
                {(buttonAction || overrideButton) && (
                  <div className={modalActionClassName}>
                    {overrideButton || (
                      <Button
                        className="action"
                        errorText={errorText}
                        text={buttonText}
                        onClick={buttonAction}
                        disabled={buttonDisabled || isPending}
                        big
                        color={color}
                        block={small}
                        blue={blue}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
