import React, { useState } from "react";
import { useTimezoneSelect } from "react-timezone-select";
import Dropdown from "UI/Dropdown/Dropdown.component";

const TimezoneSelection = ({ userTimeZone, setSelectedTimezone, timezones }) => {
  const { options } = useTimezoneSelect({ labelStyle: "original", timezones, displayValue: "UTC" });

  const modifiedOptions = options.map((option) => ({
    ...option,
    label: option.label.replace(/\//g, " / ").replace(/_/g, " "),
  }));

  const [timeZone, setTimeZone] = useState(() => {
    const foundOption = modifiedOptions.find((opt) => opt.value === userTimeZone);
    return foundOption ? foundOption.label.replace(/\//g, " / ").replace(/_/g, " ") : "(UTC+0:00) UTC";
  });

  const handleSelectedOptionChange = (selectedValue) => {
    const timezoneValue = modifiedOptions.find((opt) => opt.label === selectedValue);
    if (timezoneValue) {
      setTimeZone(selectedValue);
      setSelectedTimezone(timezoneValue);
    }
  };

  return (
    <Dropdown
      editable={options.length > 0}
      resetOnOpen
      value={timeZone}
      label="Timezone"
      options={modifiedOptions.map((option) => option.label)}
      mapFunction={(option) => {
        const foundOption = modifiedOptions.find((opt) => opt.label === option);
        return foundOption?.label;
      }}
      placeholder={timeZone}
      handleSelectedOptionChange={handleSelectedOptionChange}
      onChange={() => {}}
      disabled={options.length === 0}
    />
  );
};

export default TimezoneSelection;
