import React, { useMemo } from "react";
import { ExpandableTable, Modal, TextButton, Icon } from "UI";
import SearchInput from "UI/SearchInput/SearchInput.component";
import { Loader } from "Utils";
import EmptyContent from "../EmptyContent/EmptyContent.component";
import ModalResult from "../../../../Shared/ModalResult/ModalResult.component";

import "./ObjectsModal.styles.scss";

const ObjectsModal = ({
  headerText,
  onClose,
  columns,
  data,
  hideSearchBar,
  onConfirm,
  disableConfirm,
  success,
  successHeader,
  successDescription,
  error,
  errorHeader,
  errorDescription,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  searchQuery,
  setSearchQuery,
  emptyContentHeader,
  emptyContentDescription,
}) => {
  const searchBarState = useMemo(() => {
    if (hideSearchBar) return false;
    if (!searchQuery && data?.length === 0) return false;
    return true;
  }, [hideSearchBar, searchQuery, data]);

  if (success || error) {
    return (
      <ModalResult
        success={success}
        successHeader={successHeader}
        successDescription={successDescription}
        failureHeader={errorHeader}
        failureDescription={errorDescription}
        failure={error}
        onClose={onClose}
      />
    );
  }

  const content = () => {
    return (
      <div className="objects-modal-content">
        {searchBarState && (
          <SearchInput
            value={searchQuery}
            placeholder="Search"
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
        )}
        {data?.length > 0 ? (
          <ExpandableTable
            data={data}
            columns={columns}
            hasNextPage={hasNextPage}
            onPageEnd={() => {
              fetchNextPage();
            }}
            isFetchingNextPage={isFetchingNextPage}
          />
        ) : (
          <>
            <EmptyContent headerText={emptyContentHeader} descriptionText={emptyContentDescription} withoutButton />
          </>
        )}
        <div className="buttons-container">
          <TextButton className="action-buttons" text="Cancel" color="gray-4" onClick={onClose} />
          <TextButton
            className="action-buttons"
            text="Confirm"
            color="aqua-light"
            onClick={onConfirm}
            disabled={disableConfirm}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="objects-modal"
      headerText={headerText}
      closeAction={onClose}
      topRightIcon="close"
      topRightIconAction={onClose}
    >
      {isLoading ? <Loader /> : content()}
    </Modal>
  );
};

export default ObjectsModal;
