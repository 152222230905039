import React, { useEffect } from "react";
import { Translate } from "react-localize-redux";
import { Button } from "UI";
import routes from "Constants/Route.constants";
import { ERROR_CODE } from "Constants/global.constants";
import ErrorImage from "Assets/images/appStreaming/streams.png";

import "./StreamError.styles.scss";

const StreamError = ({
  translate,
  error,
  history,
  setShowModal,
  onReset,
  test,
  withoutButton,
  buttonAction,
  resetError,
}) => {
  useEffect(() => {
    return () => {
      if (resetError) resetError();
    };
  }, []);

  return (
    <div className="stream-error-message-container">
      <img src={ErrorImage} />
      <h1>
        <Translate
          id={`appStreamingModals.stream.errors.${error}.header`}
          options={{ onMissingTranslation: () => translate("appStreamingModals.stream.errors.default.header") }}
        />
      </h1>
      <p>
        <Translate
          id={
            test
              ? `appStreamingModals.stream.errors.test.${error}.description`
              : `appStreamingModals.stream.errors.${error}.description`
          }
          options={{ onMissingTranslation: () => translate("appStreamingModals.stream.errors.default.description") }}
        />
      </p>
      {!withoutButton && (
        <Button
          text={
            <Translate
              id={`appStreamingModals.stream.errors.${error}.action`}
              options={{ onMissingTranslation: () => translate("appStreamingModals.stream.errors.default.action") }}
            />
          }
          onClick={() => {
            if (buttonAction) {
              buttonAction();
              return;
            }

            if (error === ERROR_CODE.BALANCE_INSUFFICIENT || error === ERROR_CODE.PAYMENT_METHOD_NOT_EXIST) {
              setShowModal(false);
              history.push(routes.appStreamingBilling);
            } else if (onReset) onReset();
          }}
        />
      )}
    </div>
  );
};

export default StreamError;
