import routes from "Constants/Route.constants";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { SlideModal } from "UI";

import StreamLink from "../StreamLink/StreamLink.component";

import "./ShareStreamModal.style.scss";

const ShareStreamModal = ({ translate, onClose, stream, app }) => {
  const { boost_enabled: boostEnabled, boost_ready: boostReady } = stream?.attributes || {};
  const boosting = boostEnabled && !boostReady;
  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory();
  const tabs = [
    {
      key: "step0",
      step: 0,
      content: (
        <StreamLink
          translate={translate}
          onEdit={() => {
            history.push({
              pathname: routes.appStreamingConfigureStream,
              state: { streamID: stream.attributes.id },
            });
            onClose();
          }}
          stream={stream}
          appID={app.id}
          boosting={boosting}
        />
      ),
    },
  ];

  return (
    <SlideModal
      className="share-stream-modal"
      closeAction={onClose}
      confirmAction={onClose}
      topRightIcon="close"
      contents={tabs}
      topRightIconAction={onClose}
      translate={translate}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      big
    />
  );
};

export default ShareStreamModal;
