/* eslint-disable */
import { createDisplayStringArray } from "../Module/stats";
import { VideoPlayer } from "./videoPlayer";
import { RenderStreaming } from "../Module/renderstreaming";
import { ActionCableSignalling, Signaling, WebSocketSignaling } from "../Module/signaling";
import { func } from "joi";
import { LogarithmicScale } from "chart.js";

/** @type {Element} */
let playButton;
/** @type {RenderStreaming} */
let renderstreaming;
/** @type {boolean} */



let playerDiv = document.getElementById('playerPixel');
let lockMouseCheck = false;
let videoPlayer = new VideoPlayer();
let streamUid = null;
let setShowLoader = null;
let turnCredentials = null;
let onGameModeDisabled = null;
let onResetIdleTracker = null;
let appSessionId = null;
let onDuplicateTab = null;

function setupVideoPlayer(options = {}) {
  playerDiv = document.getElementById('playerPixel');
  lockMouseCheck = options.gameModeEnabled;
  videoPlayer.createPlayer(playerDiv, lockMouseCheck);
  streamUid = options.streamUid;
  setShowLoader = options.setShowLoader;
  turnCredentials = options.turnCredentials;
  onGameModeDisabled = options.onGameModeDisabled;
  onResetIdleTracker = options.onResetIdleTracker;
  appSessionId = options.appSessionId;
  onDuplicateTab = options.onDuplicateTab;
  videoPlayer.addEventListener('videoplayerloaded', videoPlayerLoaded.bind(this));
  videoPlayer.addEventListener('inputactionoccurred', onInputActionOccurred.bind(this));
  document.addEventListener("pointerlockchange", lockStateChange, false);
  document.addEventListener("mozpointerlockchange", lockStateChange, false);
  window.document.oncontextmenu = function () {
    return false;
  };
  
  window.addEventListener('resize', function () {
    videoPlayer.resizeVideo();
  }, true);
  
  window.addEventListener('beforeunload', async () => {
    if(!renderstreaming)
      return;
    await renderstreaming.stop();
  }, true);
}

function lockStateChange() {
  if (document.pointerLockElement == videoPlayer.videoElement || document.mozPointerLockElement === videoPlayer.videoElement) {
    // DO NOTHING
  } else {
    onGameModeDisabled();
  }
}

function onInputActionOccurred() {
  onResetIdleTracker();
}

function videoPlayerLoaded() {
  setShowLoader(false);
}


function fullscreen(){
  videoPlayer._onClickFullscreenButton();
}

function requestPointerLockForPlayer() {
  videoPlayer.requestPointerLockForPlayer();
}


function getRTCConfiguration() {
  let config = {};
  config.bundlePolicy = "max-compat"
  config.iceServers =  [];
  if (turnCredentials) {
      config.iceServers.push({
        urls: `${turnCredentials.url}?transport=udp`,
        username: turnCredentials.username,
        credential: turnCredentials.password,
      });
      config.iceServers.push({
        urls: `${turnCredentials.url.replace("turn:", "turns:")}?transport=tcp`,
        username: turnCredentials.username,
        credential: turnCredentials.password,
      });
  } 
  return config;
}

async function setupRenderStreaming() {
  const signaling = new ActionCableSignalling(streamUid);
  const config = getRTCConfiguration();
  console.log("config", config);
  renderstreaming = new RenderStreaming(signaling, config);
  renderstreaming.onConnect = onConnect;
  renderstreaming.onDisconnect = onDisconnect;
  renderstreaming.onTrackEvent = (data) => videoPlayer.addTrack(data.track);
  renderstreaming.onGotOffer = setCodecPreferences;
  renderstreaming.onMultipleConnectionControl = (receivedAppSession) => {
    if (appSessionId !== receivedAppSession) {
      onDuplicateTab();
    }
  }

  await renderstreaming.start();
  await renderstreaming.createConnection();
}

async function onMultipleConnectionControl(appSessionId) {
  if (appSessionId !== receivedAppSession) {
    signaling.stop();
    onDuplicateTab();
  }
}

function onConnect() {
  const channel = renderstreaming.createDataChannel("input");
  videoPlayer.setupInput(channel);
}

async function onDisconnect(connectionId) {
  await renderstreaming.stop();
  renderstreaming = null;
  videoPlayer.deletePlayer();
  setShowLoader(true);
}

function setCodecPreferences() {
  /** @type {RTCRtpCodecCapability[] | null} */
  let selectedCodecs = null;

  const [mimeType, sdpFmtpLine] = "video/H264 level-asymmetry-allowed=1;packetization-mode=1;profile-level-id=42e01f".split(' ');
  const { codecs } = RTCRtpSender.getCapabilities('video');
  const selectedCodecIndex = codecs.findIndex(c => c.mimeType === mimeType && c.sdpFmtpLine === sdpFmtpLine);
  const selectCodec = codecs[selectedCodecIndex];
  selectedCodecs = [selectCodec];
  const transceivers = renderstreaming.getTransceivers().filter(t => t.receiver.track.kind == "video");
  if (transceivers && transceivers.length > 0) {
    transceivers.forEach(t => t.setCodecPreferences(selectedCodecs));
  }
}

export {
  setupVideoPlayer,
  fullscreen,
  setupRenderStreaming,
  requestPointerLockForPlayer
};
