import React, { useState, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { setCreateComputersCTX } from "Actions/Organization.actions";
import routes from "Constants/Route.constants";
import { API_ENDPOINTS } from "Constants/api.constants";
import { DashboardSubScreenLayout, getItemFromLocalStorage, isMobile } from "Utils";
import { Button, DivButton, Icon, TextButton } from "UI";
import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import WarningImage from "Assets/images/appStreaming/warning.png";
import TeamsHomeOnboardingModal from "Components/Workstation/Modals/TeamsHomeOnboardingModal.component";
import SearchInput from "UI/SearchInput/SearchInput.component";
import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";
import EmptyContent from "../Components/EmptyContent/EmptyContent.component";
import ModalResult from "../../../Shared/ModalResult/ModalResult.component";
import CreateComputersModal from "./CreateComputersModal/CreateComputersModal.component";
import InviteTeamMembersModal from "./InviteTeamMembersModal/InviteTeamMembersModal.component";
import OrganizationObjectsTable from "./OrganizationObjectsTable/OrganizationObjectsTable.component";
import "./Home.styles.scss";

const Home = (props) => {
  const { history, translate } = props;
  const location = useLocation();
  const { computerCreateSuccess, computerCreateFailure } = location.state || {};
  const { organization } = useSelector((state) => state.organizations);
  const { productOnboardingCTX } = useSelector((state) => state.account);
  const checkSubscription = productOnboardingCTX?.data?.teams?.subscription_created;
  const endOfGracePeriod = organization?.attributes?.subscription?.attributes?.end_of_grace_period;
  const paymentRequired = Boolean(endOfGracePeriod); // end of grace period is indicator of payment required
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showCreateComputersModal, setShowCreateComputersModal] = useState(false);
  const [showInviteTeamMembers, setShowInviteTeamMembers] = useState(false);
  const [createComputerCount, setCreateComputerCount] = useState(1);
  const [onboardingModalCurrentStep, setOnboardingModalCurrentStep] = useState(0);
  const [showTeamsHomeOnboardingModal, setShowTeamsHomeOnboardingModal] = useState(null);
  const [showComputerCreatedResultModal, setShowComputerCreatedResultModal] = useState(
    computerCreateSuccess || computerCreateFailure,
  );
  const userManagerContainerRef = useRef(null);
  const dispatch = useDispatch();

  const {
    refetch,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    data: organizationObjectsData,
  } = useCustomInfiniteQuery({
    endpoint: API_ENDPOINTS.ORGANIZATION_OBJECTS,
    params: { q: searchQuery },
    resource: "objects",
    options: {
      refetchInterval: 1000 * 20,
      keepPreviousData: true,
    },
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetch();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useEffect(() => {
    if (showTeamsHomeOnboardingModal === null && productOnboardingCTX.status === REQUEST_STATUS.SUCCESS) {
      const shouldDisplayAfterCreationOnboarding =
        !isMobile && getItemFromLocalStorage(LOCAL_STORAGE.showTeamsHomeOnboardingModal, true);

      setTimeout(() => {
        setShowTeamsHomeOnboardingModal(checkSubscription ? false : shouldDisplayAfterCreationOnboarding);
      }, 500);
    }
  }, [productOnboardingCTX.status]);

  const showTable = useMemo(() => {
    return organizationObjectsData?.length > 0;
  }, [organizationObjectsData]);

  const showCreateComputersButton = useMemo(() => {
    if (selectedUsers.length === 0) return false;

    return selectedUsers.every((user) => !user.attributes.seat);
  }, [selectedUsers]);

  const rightActionsContent = () => {
    if (showCreateComputersButton)
      return (
        <Button
          text="Create Computers"
          className="create-computers-button"
          disabled={paymentRequired}
          onClick={() => {
            const userIds = [];
            const invitationIds = [];
            selectedUsers.forEach((user) => {
              if (user.attributes?.user?.id) {
                userIds.push(user.attributes.user.id);
              } else {
                invitationIds.push(user.attributes.invitation.id);
              }
            });

            dispatch(setCreateComputersCTX({ selectedUserIds: userIds, selectedInvitationIds: invitationIds }));
            history.push(routes.organizationAdminCreateComputersQuickStart);
          }}
        />
      );

    if (selectedUsers.length > 0) return "";

    return (
      <>
        <DivButton
          className="add-user-button"
          onClick={() => {
            if (!showTeamsHomeOnboardingModal) setShowInviteTeamMembers(true);
          }}
          disabled={paymentRequired}
        >
          <Icon name="add-user" className="selected-user-actions" />
          <p>Add Users</p>
        </DivButton>

        <div className="vertical-divider gray-3" />

        <DivButton
          className="create-computers-button"
          onClick={() => {
            if (!showTeamsHomeOnboardingModal) setShowCreateComputersModal(true);
          }}
          disabled={paymentRequired}
        >
          <Icon name="create-computers" className="selected-user-actions" />

          <p>Create Computers</p>
        </DivButton>
      </>
    );
  };

  return (
    <>
      <DashboardSubScreenLayout
        headerTitle={translate("organizationHome.header.title")}
        headerDescription={translate("organizationHome.header.description")}
        translate={translate}
        loading={isLoading && searchQuery === ""}
        noScroll
      >
        <div ref={userManagerContainerRef} className="team-dashboard-container">
          <div className="user-manager-actions-bar">
            <div className="user-manager-left-actions">
              <SearchInput
                placeholder={translate("organizationHome.searchInput.placeHolder")}
                iconName="search"
                color="white"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </div>
            <div className="user-manager-right-actions">{rightActionsContent()}</div>
          </div>

          {paymentRequired && (
            <div className="payment-failed-container">
              <img src={WarningImage} alt="payment-failed" />
              <div className="information">
                <h1>Payment Failed</h1>
                <p>
                  There was a problem with the Team subscription renewal payment. In order to continue using Vagon Teams
                  deposit the required amount of balance.
                </p>
              </div>
              <div className="vertical-divider" />
              <TextButton
                text="Pay Now"
                color="purple"
                onClick={() => {
                  history.push(routes.organizationAdminBilling);
                }}
              />
            </div>
          )}

          {showTable && (
            <OrganizationObjectsTable
              searchQuery={searchQuery}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              organizationObjectsData={organizationObjectsData}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              paymentRequired={paymentRequired}
              fetchNextPage={fetchNextPage}
              refetch={refetch}
              onboardingModalCurrentStep={onboardingModalCurrentStep}
              showTeamsHomeOnboardingModal={showTeamsHomeOnboardingModal}
              translate={translate}
              history={history}
            />
          )}
          {!showTable && (
            <EmptyContent descriptionText={translate("organizationHome.emptyContent.description")} withoutButton />
          )}
          {(organizationObjectsData?.length === 1 && searchQuery === "") ||
            (showTeamsHomeOnboardingModal && onboardingModalCurrentStep < 1 && (
              <div className="no-users-content">
                <DivButton className="card" onClick={() => setShowInviteTeamMembers(true)}>
                  <Icon name="teams-member" />
                  <p>{translate("organizationHome.noUsersContent.inviteMembersCard.title")}</p>
                  {!isMobile && <p>{translate("organizationHome.noUsersContent.inviteMembersCard.description")}</p>}
                </DivButton>
                <DivButton
                  className="card"
                  onClick={() => {
                    setShowCreateComputersModal(true);
                  }}
                >
                  <Icon name="create-computers" color="gray-4" />
                  <p>{translate("organizationHome.noUsersContent.createComputersCard.title")}</p>
                  {!isMobile && <p>{translate("organizationHome.noUsersContent.createComputersCard.description")}</p>}
                </DivButton>
              </div>
            ))}
        </div>
      </DashboardSubScreenLayout>
      {showCreateComputersModal && (
        <CreateComputersModal
          createComputerCount={createComputerCount}
          setCreateComputerCount={setCreateComputerCount}
          onClose={() => {
            setShowCreateComputersModal(false);
          }}
          history={history}
        />
      )}
      {showInviteTeamMembers && (
        <InviteTeamMembersModal
          onClose={() => {
            setShowInviteTeamMembers(false);
            refetch();
          }}
          translate={translate}
        />
      )}
      {showComputerCreatedResultModal && (
        <ModalResult
          success={computerCreateSuccess}
          successHeader={translate("organizationHome.computerCreatedResultModal.success.header")}
          successDescription={translate("organizationHome.computerCreatedResultModal.success.description")}
          failureHeader={translate("organizationHome.computerCreatedResultModal.error.header")}
          failureDescription={translate("organizationHome.computerCreatedResultModal.error.description")}
          failure={computerCreateFailure}
          onClose={() => {
            setShowComputerCreatedResultModal(false);
            refetch();
            history.replace();
          }}
        />
      )}
      {showTeamsHomeOnboardingModal && (
        <TeamsHomeOnboardingModal
          setCurrentStep={setOnboardingModalCurrentStep}
          onExit={() => {
            setShowTeamsHomeOnboardingModal(false);
            localStorage.setItem(LOCAL_STORAGE.showTeamsHomeOnboardingModal, false);
          }}
          translate={translate}
        />
      )}
    </>
  );
};

export default Home;
