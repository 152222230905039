import React, { useState, useEffect } from "react";
import _ from "lodash";
import { classnames } from "Utils";

import "./Switch.style.scss";

const Switch = ({
  text,
  onChange,
  checked,
  disabled,
  className: classNameFromProps,
  isAlpha,
  enterprise,
  pro,
  reverseText,
  afterContent,
  color,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  useEffect(() => {
    if (checked !== undefined && checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked]);

  const switchId = `vg-switch-${_.kebabCase(text)}`;

  const className = classnames([
    "vg-switch",
    classNameFromProps,
    checked && "checked",
    disabled && "disabled",
    isAlpha && "alpha",
    reverseText && "reverse-text",
    enterprise && "enterprise",
    color,
    pro && "pro",
  ]);

  return (
    <div className={className}>
      {afterContent && <div className="after-content">{afterContent}</div>}
      <p>{text}</p>
      <label className="switch" htmlFor={switchId}>
        <input
          className="switch-input"
          type="checkbox"
          id={switchId}
          checked={checked}
          onChange={() => onChange(!checked)}
          disabled={disabled}
          {...rest}
        />
        <span className="switch-button" />
      </label>
    </div>
  );
};

export default Switch;
