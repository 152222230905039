import React, { useEffect, useState, useRef, useMemo } from "react";
import _ from "lodash";
import { camelToSnake, snakeToCamel } from "Utils/Helpers/functions.helpers";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { getApplicationsAPI, getStreamAPI, getVendorAccountAPI } from "Actions/AppStreaming.actions";
import routes from "Constants/Route.constants";
import { uploadImage } from "Actions/Dashboard/Files.actions";
import { GAME_ENGINES, STREAM_ERROR_CODES, STREAM_TYPES } from "Constants/AppStreaming.constants";
import StreamError from "Components/AppStreaming/Modals/StreamError/StreamError.component";
import { Animation, useAPI } from "Utils";
import { Button, TextButton, Input, IconButton, Modal } from "UI";
import { CAPACITY_TYPES, REQUEST_STATUS } from "Constants/global.constants";
import DashboardSubScreenLayout from "Utils/Components/Layouts/DashboardSubScreenLayout/DashboardSubScreenLayout.component";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import BasicsStep from "./Steps/BasicsStep/BasicsStep.component";
import LimitationsStep from "./Steps/LimitationsStep/LimitationsStep.component";
import AccessibilityStep from "./Steps/AccessibilityStep.component";
import LaunchStep from "./Steps/LaunchStep.component";
import VisitorDataStep from "./Steps/VisitorDataStep.component";
import AvailabilityStep from "./Steps/AvailabilityStep/AvailabilityStep.component";
import CustomizeStep from "./Steps/CustomizeStep/CustomizeStep.component";
import FilesStep from "./Steps/FilesStep/FilesStep.component";
import AdvancedStep from "./Steps/AdvancedStep/AdvancedStep.component";

import "./ConfigureStream.styles.scss";

const ConfigureStream = ({ translate }) => {
  const [streamConfigs, setStreamConfigs] = React.useReducer((state, action) => ({ ...state, ...action }), {});
  const [showStreamErrorModal, setShowStreamErrorModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [changeCount, setChangeCount] = useState(0);
  const [editStreamName, setEditStreamName] = useState(false);
  const [password, setPassword] = useState(null);
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  const location = useLocation();
  const history = useHistory();
  const { name: streamName, os } = streamConfigs;
  const { streamID, openSetupModal } = location?.state || {};
  const { appStreaming } = useSelector((state) => state);
  const [showStreamUpdateSuccess, setShowStreamUpdateSuccess] = useState(false);

  const streamType = useMemo(() => {
    if (streamConfigs.parentStreamId) return STREAM_TYPES.child;
    if (streamConfigs.childStreams?.length > 0) return STREAM_TYPES.parent;
    return STREAM_TYPES.regular;
  }, [streamConfigs.parentStreamId, streamConfigs.childStreams]);

  const streamConfigureSteps = useMemo(() => {
    const childStream = streamType === STREAM_TYPES.child;
    const parentStream = streamType === STREAM_TYPES.parent;

    if (childStream) {
      setCurrentStep(2);
    } else {
      setCurrentStep(0);
    }

    return {
      basics: { step: 0, name: "Basics", disabled: childStream },
      limitations: { step: 1, name: "Limitations", disabled: childStream },
      accessibility: { step: 2, name: "Accessibility" },
      availability: { step: 3, name: "Availability", disabled: childStream },
      launch: { step: 4, name: "Launch", disabled: parentStream },
      visitorData: { step: 5, name: "Visitors", disabled: parentStream },
      customize: { step: 6, name: "Customize", disabled: parentStream },
      files: { step: 7, name: "Stream Files", disabled: streamType !== STREAM_TYPES.regular, hide: os !== "windows" },
      advanced: {
        step: 8,
        name: "Advanced",
        disabled: streamType !== STREAM_TYPES.regular,
      },
    };
  }, [streamType, os]);

  const vendorType = useSelector((state) => state.appStreaming.vendorType);
  const isVendorEnterprise = vendorType === "enterprise";
  const isVendorPro = vendorType === "pro" || vendorType === "enterprise";

  useEffect(() => {
    dispatch(getStreamAPI(streamID));
    dispatch(getVendorAccountAPI());
    dispatch(getApplicationsAPI());
  }, []);

  const handleKeydown = (e) => {
    if (e.key === "Enter") handleStreamNameChange();
    if (e.key === "Escape") setEditStreamName(false);
  };

  useEffect(() => {
    if (editStreamName) window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [editStreamName]);

  const initialStreamConfigs = snakeToCamel({
    ...appStreaming.getStreamCTX?.data?.attributes,
    capacities: appStreaming?.getStreamCTX?.data?.attributes?.capacities?.map((cap) => cap?.attributes),
    budget_limit: appStreaming?.getStreamCTX?.data?.attributes?.budget?.attributes?.limit || 0,
  });

  const {
    api: updateStreamAPI,
    isLoading,
    error: updateStreamError,
  } = useAPI({
    type: "put",
    endpoint: APP_STREAMING_API_ENDPOINTS.UPDATE_STREAM(streamID),
    onSuccess: () => {
      dispatch(getStreamAPI(streamID));
      setShowStreamUpdateSuccess(true);
      setTimeout(() => {
        setShowStreamUpdateSuccess(false);
      }, 2000);
    },
    onErr: () => {
      setShowStreamErrorModal(true);
    },
  });

  const { api: updateStreamNameAPI } = useAPI({
    type: "put",
    endpoint: APP_STREAMING_API_ENDPOINTS.UPDATE_STREAM_NAME(streamID),
    onSuccess: () => {
      dispatch(getStreamAPI(streamID));
    },
  });

  useEffect(() => {
    if (appStreaming.getStreamCTX.status === REQUEST_STATUS.SUCCESS) {
      setStreamConfigs({ ...initialStreamConfigs });
    }

    if (appStreaming.getStreamCTX.status === REQUEST_STATUS.FAILURE) {
      history.push({
        pathname: routes.appStreamingStreams,
      });
    }
  }, [appStreaming.getStreamCTX]);

  useEffect(() => {
    if (!appStreaming?.getStreamCTX?.data?.attributes) {
      return;
    }

    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const {
      startDate,
      endDate,
      scheduleType,
      capacities,
      availableDays,
      passwordProtection,
      passwordPresent,
      gameEngine,
      productName,
      companyName,
      projectName,
      collectApplicationLogs,
      setLogPathsAutomatically,
    } = streamConfigs;

    let count = 0;

    Object.keys(streamConfigs).forEach((config) => {
      if (!_.isEqual(initialStreamConfigs[config], streamConfigs[config])) {
        if (config === "timeZoneOffset" || config === "texts") return;
        count += 1;
      }
    });

    if (password !== null) count += 1;

    if (
      capacities?.map((cap) => cap.totalCapacity).includes(0) ||
      (capacities &&
        capacities[0]?.capacityType === CAPACITY_TYPES.balanced &&
        capacities?.map((cap) => cap.reserveCapacity).includes(0))
    ) {
      count = 0;
    }

    if (scheduleType === "time_range") {
      if (startDate > endDate) count = 0;

      if (!startDate || !endDate) count = 0;
    }

    if (scheduleType === "work_days") {
      if (availableDays.length === 0) {
        count = 0;
      }

      if (startDate && endDate) {
        const formattedStartDate = new Date(startDate);
        const formattedEndDate = new Date(endDate);
        if (
          formattedStartDate.getMinutes() + formattedStartDate.getHours() * 60 >=
          formattedEndDate.getMinutes() + formattedEndDate.getHours() * 60
        ) {
          count = 0;
        }
      }
    }

    if (passwordProtection && !password && !passwordPresent) count = 0;

    if (collectApplicationLogs && !setLogPathsAutomatically && gameEngine === GAME_ENGINES.unreal && !projectName)
      count = 0;

    if (collectApplicationLogs && !setLogPathsAutomatically && gameEngine === GAME_ENGINES.unity) {
      if (!productName || !companyName) count = 0;
    }

    setChangeCount(count);
  }, [streamConfigs, password]);

  const handleSubmit = () => {
    const { startDate, endDate } = streamConfigs;
    const data = {
      ...streamConfigs,
      startDate: typeof startDate === "string" ? startDate : startDate?.toISOString(),
      endDate: typeof endDate === "string" ? endDate : endDate?.toISOString(),
      password,
    };

    updateStreamAPI({ ...camelToSnake(data) });
  };

  const streamNameRef = useRef(null);

  const handleStreamNameChange = () => {
    updateStreamNameAPI({ stream_name: streamNameRef.current.value });
    dispatch(getStreamAPI(streamID));
    streamNameRef.current.value = null;
    setEditStreamName(false);
  };

  useEffect(() => {
    if (editStreamName) {
      streamNameRef.current.focus();
    }
  }, [editStreamName]);

  const setSubmitButtonText = () => {
    if (showStreamUpdateSuccess) {
      return "Applied";
    }
    return "Apply Changes";
  };

  const HeaderTitle = ({ editable }) => {
    return (
      <div className="stream-header">
        {editStreamName ? (
          <div className="edit-stream-name">
            <Input
              inputRef={streamNameRef}
              maxLength={19}
              name="streamName"
              type="text"
              className="title"
              placeholder={streamName}
            />
            <IconButton
              smaller
              className="edit-stream-button"
              name="stream-check"
              onClick={() => handleStreamNameChange()}
            />
          </div>
        ) : (
          <div className="stream-name-container">
            {streamName}
            {editable && (
              <IconButton
                small
                className="edit-stream-button"
                name="pencil"
                type="submit"
                onClick={() => {
                  setEditStreamName(true);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <DashboardSubScreenLayout
        loading={isLoading || appStreaming.getStreamCTX.status === REQUEST_STATUS.PENDING}
        pending={isLoading || appStreaming.getStreamCTX.status === REQUEST_STATUS.PENDING}
        headerTitle={<HeaderTitle editable={streamType !== STREAM_TYPES.child} />}
        appStreaming
        showCloseIcon
        onClose={() => {
          history.push({
            pathname: routes.appStreamingStreams,
          });
        }}
        displayCloseIcon
      >
        <div className="configure-stream">
          <div className="configure-stream-content">
            <div className="sidebar">
              {Object.keys(streamConfigureSteps)?.map((step) => {
                if (streamConfigureSteps[step].hide) return null;
                return (
                  <TextButton
                    key={step}
                    className="step-text"
                    text={streamConfigureSteps[step].name}
                    selected={currentStep === streamConfigureSteps[step].step}
                    onClick={() => setCurrentStep(streamConfigureSteps[step].step)}
                    disabled={streamConfigureSteps[step].disabled}
                  />
                );
              })}
            </div>
            <div className="configurations-container">
              {currentStep === streamConfigureSteps.basics.step && (
                <BasicsStep
                  initialStreamConfigs={initialStreamConfigs}
                  streamConfigs={streamConfigs}
                  setStreamConfigs={setStreamConfigs}
                  streamType={streamType}
                  openSetupModal={openSetupModal}
                  translate={translate}
                />
              )}
              {currentStep === streamConfigureSteps.limitations.step && (
                <LimitationsStep
                  streamConfigs={streamConfigs}
                  setStreamConfigs={setStreamConfigs}
                  appStreaming={appStreaming}
                  isVendorEnterprise={isVendorEnterprise}
                  streamType={streamType}
                  translate={translate}
                />
              )}
              {currentStep === streamConfigureSteps.accessibility.step && (
                <AccessibilityStep
                  streamConfigs={streamConfigs}
                  setStreamConfigs={setStreamConfigs}
                  initialStreamConfigs={initialStreamConfigs}
                  isVendorEnterprise={isVendorEnterprise}
                  isVendorPro={isVendorPro}
                  streamType={streamType}
                  translate={translate}
                />
              )}
              {currentStep === streamConfigureSteps.availability.step && (
                <AvailabilityStep
                  streamConfigs={streamConfigs}
                  setStreamConfigs={setStreamConfigs}
                  appStreaming={appStreaming}
                  initialStreamConfigs={initialStreamConfigs}
                  isVendorEnterprise={isVendorEnterprise}
                  password={password}
                  setPassword={setPassword}
                  streamType={streamType}
                  translate={translate}
                />
              )}
              {currentStep === streamConfigureSteps.launch.step && (
                <LaunchStep
                  streamConfigs={streamConfigs}
                  streamType={streamType}
                  setStreamConfigs={setStreamConfigs}
                  translate={translate}
                />
              )}
              {currentStep === streamConfigureSteps.visitorData.step && (
                <VisitorDataStep
                  streamConfigs={streamConfigs}
                  setStreamConfigs={setStreamConfigs}
                  isVendorPro={isVendorPro}
                  isVendorEnterprise={isVendorEnterprise}
                  password={password}
                  setPassword={setPassword}
                  streamType={streamType}
                  translate={translate}
                />
              )}
              {currentStep === streamConfigureSteps.customize.step && (
                <CustomizeStep
                  streamConfigs={streamConfigs}
                  setStreamConfigs={setStreamConfigs}
                  isVendorEnterprise={isVendorEnterprise}
                  uploadImage={uploadImage}
                  streamType={streamType}
                  translate={translate}
                />
              )}
              {currentStep === streamConfigureSteps.files?.step && (
                <FilesStep
                  streamConfigs={streamConfigs}
                  setStreamConfigs={setStreamConfigs}
                  streamType={streamType}
                  translate={translate}
                />
              )}
              {currentStep === streamConfigureSteps.advanced.step && (
                <AdvancedStep
                  streamConfigs={streamConfigs}
                  setStreamConfigs={setStreamConfigs}
                  streamType={streamType}
                  initialStreamConfigs={initialStreamConfigs}
                  translate={translate}
                />
              )}
            </div>
          </div>
        </div>
      </DashboardSubScreenLayout>
      <div className="submit-buttons-container">
        <TextButton
          text="Reset Changes"
          medium
          color="purple"
          onClick={() => dispatch(getStreamAPI(streamID))}
          style={{ opacity: 0, pointerEvents: "none" }}
          disabled={changeCount === 0}
          className="reset-button"
        />
        <Button
          text={setSubmitButtonText()}
          medium
          disabled={changeCount === 0}
          onClick={() => {
            handleSubmit();
          }}
          leftContent={
            showStreamUpdateSuccess && (
              <Animation type="successGray" animationStyle={{ height: "1.5rem", width: "1.5rem" }} />
            )
          }
        />
      </div>
      {showStreamErrorModal && (
        <Modal
          big
          className="start-stream-error-modal"
          closeOnOverlayClick
          closeAction={() => setShowStreamErrorModal(false)}
          topRightIcon="close"
          topRightIconAction={() => setShowStreamErrorModal(false)}
          descriptionText={
            <StreamError
              translate={translate}
              error={updateStreamError.client_code || STREAM_ERROR_CODES.balance_error}
              history={history}
              setShowModal={setShowStreamErrorModal}
            />
          }
        />
      )}
    </>
  );
};

export default ConfigureStream;
