import React from "react";
import { classnames, Loader } from "Utils";
import { Modal, TextButton } from "UI";

import "./SlideModal.styles.scss";

const SlideModal = ({
  translate,
  header,
  contents,
  closeAction,
  confirmAction,
  topRightIcon,
  className: classNameFromProps,
  activeTab,
  setActiveTab,
  pending,
  loading,
  big,
  disablePrevious,
  hideFooter,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDrop,
  steps,
  currentStep,
  sidebarHeader,
  indexOffset,
}) => {
  const stepIndexes = steps
    ? Object.keys(steps)
        .map((key) => {
          if (!steps[key].skipStep) return steps[key].step;
          return null;
        })
        .filter((step) => step !== null)
    : contents.map((_, index) => index);

  const isLastTab = stepIndexes.indexOf(currentStep) === stepIndexes.length - 1;
  const submitTab = stepIndexes.indexOf(currentStep) === stepIndexes.length - 2;

  const nextButtonText = () => {
    if (contents[activeTab].overrideNextButtonText) return contents[activeTab].overrideNextButtonText;
    if (isLastTab) return translate("appStreamingModals.actions.done");
    if (submitTab) return translate("appStreamingModals.actions.submit");
    return translate("appStreamingModals.actions.next");
  };
  return (
    <Modal
      className={classnames(["slide-modal", classNameFromProps, loading && "loading"])}
      headerText={header}
      closeAction={(event) => {
        if (!pending) closeAction(event);
      }}
      topRightIcon={topRightIcon || "close"}
      descriptionText={loading ? <Loader /> : contents[activeTab].content}
      overrideButton={
        !hideFooter && (
          <div
            className={classnames([
              "slide-modal-footer",
              (contents.length === 1 || disablePrevious) && "single-button",
              contents[activeTab].hidePrevious && "single-button",
            ])}
          >
            {!contents[activeTab].hidePrevious && contents.length !== 1 && !disablePrevious && (
              <>
                <TextButton
                  text={translate("appStreamingModals.actions.previous")}
                  color="aqua-main"
                  onClick={() => {
                    if (contents[activeTab].overridePreviousAction) {
                      contents[activeTab].overridePreviousAction();
                      return;
                    }
                    if (activeTab > 0) {
                      // find the previous step index
                      const previousStepIndex = stepIndexes.slice(0, stepIndexes.indexOf(currentStep)).pop();
                      setActiveTab(previousStepIndex - (indexOffset || 0));
                    }
                  }}
                  disabled={contents[activeTab].disablePrevious}
                  className="slide-modal-footer-button"
                />
              </>
            )}
            {!contents[activeTab].hideNext && (
              <TextButton
                text={nextButtonText()}
                color="aqua-main"
                onClick={() => {
                  if (contents[activeTab].overrideNextAction) {
                    contents[activeTab].overrideNextAction();
                    return;
                  }
                  if (contents[activeTab].onSubmit) {
                    contents[activeTab].onSubmit();
                  }
                  if (!isLastTab) {
                    const nextStepIndex = stepIndexes[stepIndexes.indexOf(currentStep) + 1];

                    setActiveTab(nextStepIndex - (indexOffset || 0));
                  } else if (confirmAction) confirmAction();
                  else closeAction();
                }}
                className="slide-modal-footer-button"
                disabled={pending || contents[activeTab].disabled}
              />
            )}
          </div>
        )
      }
      topRightIconAction={closeAction}
      big={big}
      isPending={pending}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      showSidebar={contents[activeTab].showSidebar}
      sidebarFooter={contents[activeTab].sidebarFooter}
      steps={steps}
      currentStep={currentStep}
      setCurrentStep={setActiveTab}
      sidebarHeader={sidebarHeader}
      indexOffset={indexOffset}
      contents={contents}
    />
  );
};

export default SlideModal;
