import React, { useEffect, useLayoutEffect } from "react";
import _ from "lodash";
import { ReactComponent as WorldMapSVG } from "Assets/images/hot-world-map-base.svg";

import "./HotWorldMapCoverage.style.scss";

export const AUTOMATED_REGIONS = {
  europe: "vg-europe",
  "east-asia": "vg-east-asia",
  "south-asia": "vg-south-asia",
  "middle-east": "vg-middle-east",
  "north-america": "vg-north-america",
  "south-america": "vg-south-america",
  australia: "vg-australia",
};

const HotWorldMapCoverage = ({ id = 1, coveredRegions }) => {
  useLayoutEffect(() => {
    _.each(Object.entries(AUTOMATED_REGIONS), ([, regionId]) => {
      const element = document.getElementById(`${regionId}-g`);
      if (element) {
        element.id = `${regionId}-g-${id}`;
        element.classList.add("map-point");
      }
      const vgMarker = document.getElementById(regionId);
      if (vgMarker) {
        vgMarker.id = `${regionId}-${id}`;
      }
      const region = document.getElementById(`${regionId}-coverage`);
      const regionLabel = document.getElementById(`${regionId}-label`);
      // const vgMarkerCircle = document.getElementById(`${regionId}-circle`);
      const notAvailableLabel = document.getElementById(`${regionId}-not-available`);
      if (regionLabel) regionLabel.id = `${regionId}-label-${id}`;
      if (region) {
        region.id = `${regionId}-coverage-${id}`;
      }
      if (notAvailableLabel) notAvailableLabel.id = `${regionId}-not-available-${id}`;
    });
    updateRegions();
  }, []);

  useEffect(() => {
    updateRegions();
  }, [coveredRegions]);

  const updateRegions = () => {
    _.each(Object.entries(AUTOMATED_REGIONS), ([regionName, regionId]) => {
      const element = document.getElementById(`${regionId}-g-${id}`);
      const coverage = document.getElementById(`${regionId}-coverage-${id}`);
      const vgMarker = document.getElementById(`${regionId}-${id}`);
      if (element) {
        if (coveredRegions.includes(regionName)) {
          element.classList.remove("uncovered");
          element.classList.add("show-label");
          vgMarker.classList.remove("marker-not-available");
        } else {
          coverage.classList.add("inactive-region");
          element.classList.add("uncovered");
          vgMarker.classList.add("marker-not-available");
        }
      }

      const vgMarkerCircle = document.getElementById(`${regionId}-circle-${id}`);
      const regionLabel = document.getElementById(`${regionId}-label-${id}`);
      const notAvailableLabel = document.getElementById(`${regionId}-not-available-${id}`);
      if (notAvailableLabel) {
        notAvailableLabel.classList.add("hidden");
        notAvailableLabel.classList.add("not-available-label");
      }
      if (regionLabel) regionLabel.classList.add("region-label");
    });
  };

  return (
    <div className="hot-world-map">
      <WorldMapSVG />
    </div>
  );
};

export default HotWorldMapCoverage;
