import React, { useMemo, useState } from "react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { useDebounce } from "use-debounce";
import { apiGenerator, Loader } from "Utils";
import { isOdd } from "Utils/Helpers/functions.helpers";
import { ExpandableTable, Modal, TextButton } from "UI";
import SearchInput from "UI/SearchInput/SearchInput.component";
import StatusBadge from "UI/StatusBadge/StatusBadge.component";
import EmptyContent from "Components/Organization/Admin/Components/EmptyContent/EmptyContent.component";
import ModalResult from "../../../../Shared/ModalResult/ModalResult.component";
import UserCell from "../../Components/UserCell/UserCell.component";
import "./AssignTemplateModal.styles.scss";

const AssignTemplateModal = ({ onClose, selectedSilverImageId, selectedSilverImageSize, translate }) => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const {
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isInitialLoading,
    data: organizationsImageAssignableSeatsData,
  } = useInfiniteQuery({
    queryKey: [API_ENDPOINTS.ORGANIZATIONS_SEATS_IMAGE_ASSIGNABLE, debouncedSearchQuery],
    queryFn: ({ pageParam = 1 }) => {
      const params = { page: pageParam, per_page: 25, q: searchQuery };
      return apiGenerator("get")(API_ENDPOINTS.ORGANIZATIONS_SEATS_IMAGE_ASSIGNABLE, {
        params,
      }).then((res) => {
        return res.data;
      });
    },
    getNextPageParam: (lastPage) => lastPage.next_page,
    keepPreviousData: true,
  });

  const assignTemplateMutation = useMutation({
    mutationKey: [API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES_ASSIGN(selectedSilverImageId)],
    mutationFn: (data) => {
      return apiGenerator("post")(API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGES_ASSIGN(selectedSilverImageId), data);
    },
  });

  const { isSuccess, isError } = assignTemplateMutation;

  const handleSubmit = () => {
    assignTemplateMutation.mutate({ seat_ids: selectedSeats.map((id) => parseInt(id, 10)) });
  };

  const columns = [
    { name: "id", weight: 0, hide: true },
    { name: "User", weight: 50 },
    { name: "Status", weight: 30 },
    { name: "Disk Size", weight: 10 },
  ];

  const data = organizationsImageAssignableSeatsData?.pages?.flatMap((page) => {
    return page?.seats?.map((item, index) => {
      const { user, disk_size: diskSize, invitation, friendly_status: friendlyStatus } = item.attributes;

      return {
        id: item.id,
        user: (
          <UserCell
            user={user || invitation}
            seat={item}
            onCheckBoxChange={(e) => {
              if (e.target.checked) setSelectedSeats([...selectedSeats, item.id]);
              else setSelectedSeats(selectedSeats.filter((seatId) => seatId !== item.id));
            }}
            disableCheckBox={selectedSilverImageSize > diskSize}
          />
        ),
        status: <StatusBadge status={friendlyStatus} color={isOdd(index) && "gray"} />,
        "disk size": `${diskSize} GB`,
        disabled: selectedSilverImageSize > diskSize,
      };
    });
  });

  const searchBarState = useMemo(() => {
    if (!searchQuery && data?.length === 0) return false;
    return true;
  }, [searchQuery, data]);

  const modalContent = () => {
    if (isInitialLoading) return <Loader />;

    if (isSuccess || isError) {
      return (
        <ModalResult
          success={isSuccess}
          successHeader={translate("organizationTemplates.assignTemplateModal.success.header")}
          successDescription={translate("organizationTemplates.assignTemplateModal.success.description")}
          onClose={onClose}
        />
      );
    }

    return (
      <>
        {searchBarState && (
          <SearchInput
            value={searchQuery}
            placeholder="Search"
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
        )}
        {data.length > 0 ? (
          <ExpandableTable
            data={data}
            columns={columns}
            onPageEnd={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        ) : (
          <EmptyContent
            headerText={translate("organizationTemplates.assignTemplateModal.emptyContent.title")}
            descriptionText={translate("organizationTemplates.assignTemplateModal.emptyContent.description")}
            withoutButton
          />
        )}

        <div className="buttons-container">
          <TextButton text="Cancel" onClick={onClose} color="gray-3" />
          <TextButton
            text="Save Selection"
            onClick={handleSubmit}
            color="aqua-dark"
            disabled={selectedSeats.length === 0}
          />
        </div>
      </>
    );
  };

  return (
    <Modal
      className="assign-template-modal"
      closeAction={onClose}
      topRightIcon="close"
      headerText={translate("organizationTemplates.assignTemplateModal.header.title")}
      descriptionText={translate("organizationTemplates.assignTemplateModal.header.description")}
      topRightIconAction={onClose}
    >
      {modalContent()}
    </Modal>
  );
};

export default AssignTemplateModal;
