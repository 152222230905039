import actionCable from "actioncable";
import { STREAM_TYPE } from "Constants/global.constants";
import { Logger, Tracker } from "Utils";
import { getAccessTokenFromLocalStorage } from "Utils/Helpers/storage.helpers";

const MESSAGE_TYPES = {
  ANSWER: "ANSWER",
  OFFER: "OFFER",
  CANDIDATE: "CANDIDATE",
  INITIALIZE: "INITIALIZE",
  MULTIPLE_SESSION: "MULTIPLE_SESSION",
  DISCONNECTED: "DISCONNECTED",
  INITCOMPLETED: "INITCOMPLETED",
};
export default class Signaler {
  constructor(
    onConnected,
    onDisconnected,
    onOffer,
    onCandidate,
    onMultipleConnection,
    streamType,
    machineId = null,
    streamParams = {},
  ) {
    this.onConnected = onConnected;
    this.onDisconnected = onDisconnected;
    this.onOffer = onOffer;
    this.onCandidate = onCandidate;
    this.onMultipleConnection = onMultipleConnection;
    this.streamParams = streamParams;
    const localAccessToken = getAccessTokenFromLocalStorage();
    const url = () => {
      switch (streamType) {
        case STREAM_TYPE.workstation:
          return `${process.env.REACT_APP_WEBRTC_SIGNAL_URL}?token=${localAccessToken}`;
        case STREAM_TYPE.application:
        case STREAM_TYPE.pixel_streaming:
          return `${process.env.REACT_APP_WEBRTC_SIGNAL_URL}?app_session_id=${machineId}`;
        case STREAM_TYPE.organization:
          return `${process.env.REACT_APP_WEBRTC_SIGNAL_URL}?token=${localAccessToken}&organization_machine_id=${machineId}`;
        case STREAM_TYPE.public_organization:
          // eslint-disable-next-line max-len
          return `${process.env.REACT_APP_WEBRTC_SIGNAL_URL}?access_id=${streamParams?.publicAccessId}&organization_machine_id=${machineId}`;
        default:
          return "";
      }
    };
    this.streamType = streamType;
    this.cable = actionCable.createConsumer(url());
    this.gotOffer = false;
    this.handler = null;
    this.gotInit = false;
    Logger.log("Signaler | constructor", streamType, this.streamParams);
  }

  connect = (sessionId) => {
    this.sessionId = sessionId;
    this.session = this.cable.subscriptions.create("SessionChannel", {
      connected: () => {
        this.onConnected();
      },
      received: (data) => {
        if (data.sessionId === this.sessionId) {
          return;
        }
        switch (data.type) {
          case MESSAGE_TYPES.OFFER:
            this.gotOffer = true;
            Tracker.time({ type: "waiting_offer", start: false });
            this.onOffer(data.sdp);
            break;
          case MESSAGE_TYPES.CANDIDATE:
            this.onCandidate(data);
            break;
          case MESSAGE_TYPES.MULTIPLE_SESSION:
            this.onMultipleConnection();
            break;
          case MESSAGE_TYPES.DISCONNECTED:
            Logger.log("Signaler | received DISCONNECTED");
            clearTimeout(this.handler);
            this.onDisconnected();
            break;
          case MESSAGE_TYPES.INITCOMPLETED: {
            this.gotInit = true;
            clearTimeout(this.initHandler);
            this.streamParams.type = "PARAMETERS";
            this.session.sendMessage(this.streamParams);
            Tracker.time({ type: "waiting_offer", start: true });
            this.handler = setTimeout(
              () => {
                if (!this.gotOffer) {
                  this.sendDisconnected();
                  this.onDisconnected();
                  const diff = Tracker.time({ type: "waiting_offer", start: false });
                  Logger.log(`Signaler | sendDisconnected | System cloud not get offer in ${diff} ms`);
                }
              },
              this.streamType === STREAM_TYPE.application ? 40000 : 3000,
            );
            break;
          }
          default:
            break;
        }
      },
      sendMessage: (message) => {
        this.session.send(message);
      },
    });
  };

  disconnect = () => {
    this.sendDisconnected();
    if (this.session) {
      this.session.unsubscribe();
    }
    this.cable.disconnect();
  };

  sendOffer = (offer) => {
    this.session.sendMessage({
      type: MESSAGE_TYPES.OFFER,
      sdp: offer.sdp,
      sessionId: this.sessionId,
    });
  };

  sendAnswer = (answer) => {
    this.session.sendMessage({
      type: MESSAGE_TYPES.ANSWER,
      sdp: answer.sdp,
      sessionId: this.sessionId,
    });
  };

  sendInit = () => {
    this.session.sendMessage({
      type: MESSAGE_TYPES.INITIALIZE,
      version: this.streamType === STREAM_TYPE.pixel_streaming ? 999 : 3,
      sessionId: this.sessionId,
    });
    this.initHandler = setTimeout(() => {
      if (!this.gotInit) {
        this.onDisconnected();
        Logger.log("Signaler | sendDisconnected | System cloud not get init");
      }
    }, 1000);
    Logger.log("Signaler | sendInit");
  };

  sendCandidate = (candidate) => {
    this.session.sendMessage({
      type: MESSAGE_TYPES.CANDIDATE,
      sdp: candidate.candidate,
      sdpMid: candidate.sdpMid,
      sdpMlineIndex: candidate.sdpMLineIndex,
      sessionId: this.sessionId,
    });
  };

  sendDisconnected = () => {
    this.session.sendMessage({
      type: MESSAGE_TYPES.DISCONNECTED,
      sessionId: this.sessionId,
    });
  };
}
