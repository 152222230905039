import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { isEmpty } from "lodash";

import { Divider, Icon, SlideModal, Switch, TextButton } from "UI";
import { useAPI, isMobile, apiGenerator, bytesToGB } from "Utils";
import { CAPACITY_MANAGEMENT_TYPES, CAPACITY_TYPES, OS, REQUEST_STATUS } from "Constants/global.constants";
import { API_ENDPOINTS, APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import routes from "Constants/Route.constants";
import { AUTOMATED_AREAS, MAX_CAPACITY_BY_VENDOR_TYPE, VENDOR_TYPES } from "Constants/AppStreaming.constants";
import { approveCapacityWarningConsent } from "Actions/AppStreaming.actions";
import { useQuery } from "@tanstack/react-query";

import ChooseRegion from "./ChooseRegion.component";
import StreamError from "../StreamError/StreamError.component";
import StreamLink from "../StreamLink/StreamLink.component";
import CapacitySelection from "../CapacitySelection/CapacitySelection.component";
import CapacityManagement from "./CapacityManagement/CapacityManagement.component";
import AppListCard from "../../Streams/AppListCard/AppListCard.component";

import "./CreateStreamModal.styles.scss";

const CreateStreamModal = ({
  translate,
  setShowCreateStreamModal,
  selectedApp,
  capacities,
  createStreamAPI,
  getStreamsAPI,
  createStreamCTX,
  setSelectedStream,
  setSelectedApp,
  activeApplications,
  setShowSubscriptionRequestModal,
  history,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedCapacities, setSelectedCapacities] = useState([]);
  const [concurrentUser, setConcurrentUser] = useState(0);
  const [reserveCapacity, setReserveCapacity] = useState(1);
  const [stream, setStream] = useState();
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [showPricingInfo, setShowPricingInfo] = useState(false);
  const [multipleCapacities, setMultipleCapacities] = useState(false);
  const [multipleSelection, setMultipleSelection] = useState({});
  const [capacityType, setCapacityType] = useState();
  const [showCapacityWarning, setShowCapacityWarning] = useState(false);
  const [capacityWarningConsent, setCapacityWarningConsent] = useState(false);
  const [capacityManagement, setCapacityManagement] = useState(null);
  const [boostEnabled, setBoostEnabled] = useState(true);

  const fastConnection = capacityManagement === CAPACITY_MANAGEMENT_TYPES.fast_connection;
  const highDemand = capacityManagement === CAPACITY_MANAGEMENT_TYPES.high_demand;

  const { boost_enabled: createdStreamBoostEnabled, boost_ready: createdStreamBoostReady } =
    createStreamCTX.data?.attributes || {};
  const createdStreamID = createStreamCTX.data?.id;

  const { fast_connection_available: fastConnectionAvailable } =
    selectedApp?.attributes?.default_machine_type?.attributes || {};

  const { data: createdStreamData } = useQuery({
    queryKey: [APP_STREAMING_API_ENDPOINTS.GET_STREAM(createdStreamID), createdStreamID],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_STREAM(createdStreamID)).then((res) => res.data);
    },
    refetchInterval: 5000,
    enabled: !!createdStreamID,
  });

  const boosting = createdStreamData
    ? !createdStreamData.attributes.boost_ready && createdStreamBoostEnabled
    : createdStreamBoostEnabled && !createdStreamBoostReady;

  const dispatch = useDispatch();

  const vendorType = useSelector((state) => state.appStreaming.vendorType);
  const capacityWarningConsentApproved = useSelector(
    (state) => state.appStreaming.getVendorAccountCTX.data?.attributes?.capacity_warning_consent,
  );
  const {
    can_request_subscription: canRequestSubscription,
    hot_pool_allowed: hotPoolAllowed,
    pending_subscription_request: pendingSubscriptionRequest,
  } = useSelector((state) => state.appStreaming.getVendorAccountCTX.data?.attributes);

  const isVendorEnterprise = vendorType === VENDOR_TYPES.enterprise;

  const maxCapacity = MAX_CAPACITY_BY_VENDOR_TYPE[vendorType];
  const defaultCapacity = MAX_CAPACITY_BY_VENDOR_TYPE.default;
  const isLinux = selectedApp?.attributes?.os === "linux";
  const appSize = selectedApp?.attributes?.active_executable?.attributes?.archive_size; // in bytes

  const calculateInstallationTime = (appSizeInBytes = 0) => {
    let time = 0;
    const sizeInGB = bytesToGB(appSizeInBytes);
    if (sizeInGB <= 1) {
      time = 10;
    } else {
      time = 10 + (sizeInGB - 1) * 12;
    }

    time = Math.round(time);

    if (time > 99) {
      const minutes = Math.round(time / 60);
      return `~${minutes} minutes`;
    }

    return `~${time} seconds`;
  };

  const installationTime = calculateInstallationTime(appSize);

  const createStreamSteps = useMemo(() => {
    return {
      application: { step: 0 },
      servers: { step: 1, skipStep: isLinux || !hotPoolAllowed },
      coverage: { step: 2 },
      capacity: { step: 3, skipStep: capacityManagement === null ? true : fastConnection },
      optimization: { step: 4, skipStep: isLinux },
      ready: { step: 5, showOnCurrentStep: true },
    };
  }, [isLinux, fastConnection, !hotPoolAllowed, capacityManagement]);

  useEffect(() => {
    setSelectedCapacities([]);
    if (!isEmpty(selectedApp)) {
      if (isLinux || !hotPoolAllowed) {
        setCapacityManagement(CAPACITY_MANAGEMENT_TYPES.high_demand);
        setActiveTab(createStreamSteps.coverage.step);
        setBoostEnabled(false);
      } else {
        setActiveTab(createStreamSteps.servers.step);
      }
    }
  }, [selectedApp?.id]);

  useEffect(() => {
    if (submitted) {
      if (createStreamCTX.status === REQUEST_STATUS.SUCCESS) {
        if (selectedApp.attributes?.is_bundled_app) {
          onClose();
        }
        setStream(createStreamCTX.data);
        setSelectedStream(createStreamCTX.data);
        setError(null);
      } else if (createStreamCTX.status === REQUEST_STATUS.FAILURE) {
        const errorCode = createStreamCTX.client_code;
        if (!error) {
          setError(errorCode);
        }
      } else {
        setError(null);
      }
    }
  }, [createStreamCTX.status]);

  useEffect(() => {
    setMultipleSelection(
      selectedCapacities.reduce((accumulator, value) => {
        return { ...accumulator, [value]: 0 };
      }, {}),
    );
  }, [selectedCapacities]);

  const {
    api: calculateRegionPricingAPI,
    data: regionPricing,
    isLoading: regionPricingLoading,
  } = useAPI({
    type: "post",
    endpoint: APP_STREAMING_API_ENDPOINTS.CALCULATE_REGION_PRICING,
    onSuccess: () => setShowPricingInfo(true),
  });

  const selectedRegionCount = selectedCapacities.length;
  const minComputeCost = selectedRegionCount > 0 || fastConnection ? regionPricing?.min_compute_cost : null;
  const maxComputeCost = selectedRegionCount > 0 || fastConnection ? regionPricing?.max_compute_cost : null;

  useEffect(() => {
    if (selectedCapacities.length !== 0 || fastConnection) {
      setShowPricingInfo(false);
      calculateRegionPricingAPI({
        application_id: selectedApp.id,
        regions: selectedCapacities,
        automated: fastConnection,
      });
    }
    if (selectedCapacities.length === 1 && multipleCapacities) setMultipleCapacities(false);
  }, [selectedCapacities, fastConnection]);

  const determineCapacityCount = (regionName) => {
    if (capacityType !== "always_on") {
      return defaultCapacity;
    }

    return !multipleCapacities ? parseInt(concurrentUser, 10) : multipleSelection[regionName];
  };

  const onReset = () => {
    setActiveTab(0);
    setConcurrentUser(0);
    setStream();
    setError(null);
    setSubmitted(false);
    setSelectedCapacities([]);
  };

  const handleSubmit = () => {
    const tmpCapacities = _.cloneDeep(capacities);
    const newCapacities = selectedCapacities.reduce((regions, regionName) => {
      const index = regions.findIndex((region) => region.region === regionName);
      const regionCapacity = [...regions];
      regionCapacity[index].total_capacity = determineCapacityCount();
      regionCapacity[index].capacity_type = capacityType;
      regionCapacity[index].reserve_capacity = capacityType === CAPACITY_TYPES.balanced ? reserveCapacity : 0;
      return regionCapacity;
    }, tmpCapacities);
    setSubmitted(true);

    if (capacityWarningConsent) {
      dispatch(approveCapacityWarningConsent());
    }

    createStreamAPI({
      appID: selectedApp.id,
      capacities: newCapacities,
      capacityManagement,
      boostEnabled,
    });
  };

  const onClose = () => {
    if (submitted) getStreamsAPI();
    setShowCreateStreamModal(false);
    setSelectedApp({});
  };

  const sidebarFooter = () => {
    if (capacityManagement === CAPACITY_MANAGEMENT_TYPES.fast_connection) {
      return (
        <div className="modal-sidebar-footer">
          <Icon name="info" className="gray-5" />
          <p>
            Switch to{" "}
            <span
              onClick={() => {
                setActiveTab(createStreamSteps.servers.step);
              }}
            >
              Advanced Setup
            </span>{" "}
            to choose from 20+ regions globally
          </p>
        </div>
      );
    }
    if (!selectedApp.attributes?.is_bundled_app)
      return (
        <div className="modal-sidebar-footer">
          <Icon name="info" className="gray-5" />
          <p>{translate("appStreamingModals.stream.satteliteRegionFooter")}</p>
        </div>
      );
    return (
      <div className="modal-sidebar-footer">
        <Icon name="lightning" className="gray-5" />
        <p>{translate("appStreamingModals.stream.bundledAppFooter")}</p>
      </div>
    );
  };

  const { data: streamsRegions } = useQuery({
    queryKey: [APP_STREAMING_API_ENDPOINTS.GET_REGIONS],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_REGIONS).then((res) => res.data);
    },
  });

  const automatedRegionNames = streamsRegions?.automated_regions?.map((region) => region.attributes.name);
  const automatedAreaNames = [...new Set(automatedRegionNames?.map((region) => AUTOMATED_AREAS[region]))];

  const tabs = [
    {
      key: "step0",
      disabled: isEmpty(selectedApp),
      showSidebar: true,
      disablePrevious: true,
      hidePrevious: true,
      hideNext: true,
      content: (
        <>
          <h1>{translate("appStreamingModals.stream.application.header")}</h1>
          <p>{translate("appStreamingModals.stream.application.description")}</p>
          <div className="application-selection">
            {activeApplications?.applications?.map((application) => {
              return (
                <AppListCard
                  key={application.id}
                  name={application.attributes.name}
                  logo={application.attributes.logo_url || application.attributes.dummy_logo_url}
                  onClick={() => {
                    setSelectedApp(application);
                    if (application.attributes.os === OS.windows && hotPoolAllowed) {
                      setActiveTab(createStreamSteps.servers.step);
                    } else {
                      setActiveTab(createStreamSteps.coverage.step);
                    }
                  }}
                  selected={parseInt(selectedApp.id, 10) === parseInt(application.id, 10)}
                  withoutCaret
                  bundledApp={application.attributes.is_bundled_app}
                />
              );
            })}
          </div>
        </>
      ),
    },
    {
      key: "step1",
      disabled: capacityManagement === null,
      showSidebar: true,
      content: (
        <>
          <h1>{translate("appStreamingModals.stream.management.header")}</h1>
          <p>{translate("appStreamingModals.stream.management.description")}</p>
          <CapacityManagement
            isVendorEnterprise={isVendorEnterprise}
            fastConnectionAvailable={fastConnectionAvailable}
            isLinux={isLinux}
            capacityManagement={capacityManagement}
            setCapacityManagement={setCapacityManagement}
            canRequestSubscription={canRequestSubscription}
            pendingSubscriptionRequest={pendingSubscriptionRequest}
            setShowCreateStreamModal={setShowCreateStreamModal}
            setShowSubscriptionRequestModal={setShowSubscriptionRequestModal}
            automatedAreaNames={automatedAreaNames}
            translate={translate}
          />
        </>
      ),
    },
    {
      key: "step2",
      disabled: selectedCapacities.length === 0 && highDemand,
      showSidebar: true,
      content: (
        <ChooseRegion
          translate={translate}
          coveredRegions={fastConnection ? automatedAreaNames : capacities?.map((capacity) => capacity.region)}
          selectedCapacities={selectedCapacities}
          onRegionClick={(region) => {
            setSelectedCapacities((prevState) => _.xor(prevState, [region]));
          }}
          minSessionCostPerMinute={minComputeCost}
          maxSessionCostPerMinute={maxComputeCost}
          newDailyCost={regionPricing?.new_daily_datacenter_cost}
          regionPricingLoading={regionPricingLoading}
          hotPoolMap={fastConnection}
        />
      ),
      sidebarFooter: sidebarFooter(),
    },
    {
      key: "step3",
      showSidebar: true,
      disabled:
        !capacityType ||
        (capacityType === CAPACITY_TYPES.always_on && concurrentUser === 0) ||
        selectedCapacities.length === 0,
      content: (
        <CapacitySelection
          capacity={concurrentUser}
          setCapacity={setConcurrentUser}
          reserveCapacity={reserveCapacity}
          setReserveCapacity={setReserveCapacity}
          translate={translate}
          selectedRegionCount={selectedCapacities.length}
          selectedCapacities={selectedCapacities}
          cost={showPricingInfo && regionPricing?.total_datacenter_cost.toFixed(2)}
          dailyCost={regionPricing?.daily_datacenter_cost || ""}
          multipleSelection={multipleSelection}
          setMultipleSelection={setMultipleSelection}
          capacityType={capacityType}
          setCapacityType={setCapacityType}
          maxCapacity={maxCapacity}
          capacityWarningConsent={capacityWarningConsent}
          setCapacityWarningConsent={setCapacityWarningConsent}
          showCapacityWarning={showCapacityWarning}
        />
      ),
      overridePreviousAction: showCapacityWarning ? () => setShowCapacityWarning(false) : false,
      overrideNextAction:
        !capacityWarningConsentApproved && capacityType !== CAPACITY_TYPES.on_demand && !showCapacityWarning
          ? () => setShowCapacityWarning(true)
          : false,
      onSubmit: isLinux ? handleSubmit : false,
      overrideNextButtonText: isLinux ? translate("appStreamingModals.stream.step1.concurrentUser.action") : null,
      sidebarFooter: sidebarFooter(),
    },
    {
      key: "step4",
      showSidebar: true,
      content: (
        <>
          <h1>{translate("appStreamingModals.stream.optimization.header")}</h1>
          <p>{translate("appStreamingModals.stream.optimization.description")}</p>
          <div className="optimizations">
            <div className="optimization-card">
              <div className="optimization-card-header">
                <div>
                  <div className="optimization-card-header_with-icon">
                    <h1>{translate("appStreamingModals.stream.optimizations.fastLaunch.header")}</h1>
                    <Icon name="lightning" color="aqua-main" small />
                  </div>
                  <p>{translate("appStreamingModals.stream.optimizations.fastLaunch.description")}</p>
                </div>
              </div>

              <div className="optimization-card-action">
                <Switch
                  color="aqua-main"
                  checked={boostEnabled}
                  onChange={(e) => {
                    setBoostEnabled(e);
                  }}
                />
                <div className="installation-time">
                  <p>installation</p>
                  <p>{boostEnabled ? "~ instant" : installationTime}</p>
                </div>
              </div>
            </div>
            <div className="divider-wrapper">
              <Divider />
            </div>
            <div className="optimization-card">
              <div className="optimization-card-header">
                <div>
                  <h1>{translate("appStreamingModals.stream.optimizations.cacheCollection.header")}</h1>
                  <p>
                    {translate(
                      `appStreamingModals.stream.optimizations.cacheCollection.description.${
                        selectedApp?.attributes?.optimized ? "collected" : "notCollected"
                      }`,
                    )}
                  </p>
                </div>
              </div>
              <div className="optimization-card-action">
                {selectedApp?.attributes?.optimized && <Icon name="rounded-check" smaller />}
              </div>
            </div>
          </div>
        </>
      ),
      onSubmit: handleSubmit,
      overrideNextButtonText: translate("appStreamingModals.stream.step1.concurrentUser.action"),
    },
    {
      key: "step5",
      disabled: !submitted,
      disablePrevious: true,
      showSidebar: true,
      overrideStepName: createStreamCTX.status === REQUEST_STATUS.FAILURE && "Failed",
      content: (
        <>
          {stream && (
            <StreamLink
              setShowCreateStreamModal={setShowCreateStreamModal}
              translate={translate}
              onEdit={() => {
                history.push({
                  pathname: routes.appStreamingConfigureStream,
                  state: {
                    streamID: stream.attributes.id,
                  },
                });
              }}
              stream={stream}
              isPending={createStreamCTX.status === REQUEST_STATUS.PENDING}
              appID={selectedApp.id}
              boosting={boosting}
            />
          )}
          {createStreamCTX.status === REQUEST_STATUS.FAILURE && (
            <StreamError
              translate={translate}
              error={error}
              history={history}
              setShowModal={setShowCreateStreamModal}
              onReset={onReset}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <SlideModal
        className="new-stream-modal"
        closeAction={onClose}
        confirmAction={onClose}
        topRightIcon="close"
        contents={tabs}
        topRightIconAction={onClose}
        translate={translate}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        loading={createStreamCTX.status === REQUEST_STATUS.PENDING || capacities?.length === 0}
        disablePrevious={submitted}
        sidebarHeader="Streams"
        steps={createStreamSteps}
        currentStep={activeTab}
        big={!isMobile}
      />
    </>
  );
};

export default CreateStreamModal;
