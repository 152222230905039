import React, { useRef, useState } from "react";
import { FILE_ERROR } from "Constants/global.constants";
import { SlideModal, DivButton, Icon, TextButton } from "UI";
import { classnames, Logger, isMobile } from "Utils";
import { useDragged, useResetError } from "Utils/Hooks";
import {
  canUploadFiles,
  containsUnAllowedFileTypes,
  containsEmptyFile,
  zipsContainsFileTypes,
  isFileSizesAllowed,
} from "Utils/Helpers/file.helpers";
import WindowsImage from "Assets/images/appStreaming/windows.svg";
import { useSelector } from "react-redux";
import LinuxImage from "Assets/images/appStreaming/linux.svg";
import PrebakedImage from "Assets/images/appStreaming/prebakedImage.png";
import { readApplicationZipFiles } from "Utils/Helpers/functions.helpers";
import AppUploadArea from "../AppUploadArea/AppUploadArea.component";

import "./AppUploadModal.styles.scss";

const APP_TYPES = {
  WINDOWS: {
    via: "windows",
    img: WindowsImage,
  },
  LINUX: {
    via: "linux",
    img: LinuxImage,
  },
  PREBAKED_IMAGE: {
    via: "prebakedImage",
    img: PrebakedImage,
  },
};

const APP_UPLOAD_MODAL_STEPS = {
  applicationType: { name: "Type", step: 0, disabled: true },
  upload: { name: "Upload", step: 1, disabled: true },
  executable: { name: "General", step: 2, disabled: true },
  performance: { name: "Performance", step: 3, disabled: true },
  controls: { name: "Controls", step: 4, disabled: true },
  complete: { name: "Ready", step: 5, disabled: true, showOnCurrentStep: true },
};

const AppUploadModal = ({
  uploadingApps,
  showAppConfigurationModal,
  setShowFileUploadModal,
  uploadApplicationFile,
  translate,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedAppType, setSelectedAppType] = useState(null);
  const [appUploadError, setAppUploadError] = useState(false);
  useResetError(appUploadError, setAppUploadError);
  const containerRef = useRef(null);

  const { application_size_limit: applicationSizeLimit } =
    useSelector((state) => state.appStreaming.getVendorAccountCTX.data.attributes) || {}; // GB

  const { isDragActive, onDragOver, onDragEnter, onDragLeave, resetDrag } = useDragged();
  const isWindows = selectedAppType?.via === APP_TYPES.WINDOWS.via;

  const onCancel = () => {
    setShowFileUploadModal(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;

    handleFileDrop(files);
    resetDrag();
  };

  const handleFileDrop = async (files) => {
    if (showAppConfigurationModal) {
      // Do not upload new app if there is config modal is open
      return;
    }

    if (!isFileSizesAllowed(files, applicationSizeLimit * 1024)) {
      setAppUploadError(FILE_ERROR.APP_SIZE_TOO_BIG);
      return;
    }

    // Wait for previous app to finish
    if (uploadingApps.length > 0) {
      setAppUploadError(FILE_ERROR.ONGOING_PROCESS);
      return;
    }

    if (Object.keys(files).length > 1) {
      setAppUploadError(FILE_ERROR.TOO_MANY_FILES);
      return;
    }

    if (canUploadFiles(files) && !containsUnAllowedFileTypes(files, ["zip"])) {
      if (!containsEmptyFile(files)) {
        const { fileContents, companyName, productName, gameEngine } = await readApplicationZipFiles(files, isWindows);

        if (selectedAppType?.via === APP_TYPES.LINUX.via || zipsContainsFileTypes(fileContents, [".exe"])) {
          uploadApplicationFile({ files, fileContents, os: selectedAppType.via, companyName, productName, gameEngine });
          setShowFileUploadModal(false);
        } else {
          setAppUploadError(FILE_ERROR.SHOULD_CONTAIN_EXE);
        }
      } else {
        setAppUploadError(FILE_ERROR.EMPTY_FILE_NOT_ALLOWED);
      }
    } else {
      setAppUploadError(FILE_ERROR.FOLDER_NOT_ALLOWED);
    }

    resetDrag();
  };

  const tabs = [
    {
      disabled: !selectedAppType,
      key: "step0",
      showSidebar: true,
      content: (
        <>
          <h1>{translate("appStreamingModals.appUpload.appTypeSelection.header")}</h1>
          <p>{translate("appStreamingModals.appUpload.appTypeSelection.description")}</p>
          <div className="app-upload-modal-container">
            <div className="app-type-selection-container">
              {Object.keys(APP_TYPES).map((appType) => {
                return (
                  <DivButton
                    className={classnames([
                      "app-type-option",
                      selectedAppType && selectedAppType.via !== APP_TYPES[appType].via && "transparent",
                    ])}
                    onClick={() => {
                      setSelectedAppType(APP_TYPES[appType]);
                      if (APP_TYPES[appType].via === APP_TYPES.PREBAKED_IMAGE.via) {
                        setShowFileUploadModal(false);
                        window.Intercom("show");
                      } else {
                        setActiveTab(1);
                      }
                    }}
                    key={APP_TYPES[appType].via}
                  >
                    <div className="actual-content">
                      <img src={APP_TYPES[appType].img} />

                      <div className="type-description">
                        <h5>
                          {translate(
                            `appStreamingModals.appUpload.appTypeSelection.${APP_TYPES[appType].via}.description`,
                          )}
                        </h5>
                      </div>
                      <TextButton
                        className="app-action-button"
                        text={translate(
                          `appStreamingModals.appUpload.appTypeSelection.${APP_TYPES[appType].via}.action`,
                        )}
                        color="gray-2"
                        icon="arrow-right"
                        iconPosition="right"
                      />
                    </div>
                    <div className="hoverable-content">
                      <Icon name="info" color="aqua-main" small />
                      <p>
                        {translate(
                          `appStreamingModals.appUpload.appTypeSelection.${APP_TYPES[appType].via}.hoverableDescription`,
                        )}
                      </p>
                      <TextButton
                        className="app-action-button"
                        text={translate(
                          `appStreamingModals.appUpload.appTypeSelection.${APP_TYPES[appType].via}.hoverableAction`,
                        )}
                        color="gray-2"
                        icon="arrow-right"
                        iconPosition="right"
                      />
                    </div>
                  </DivButton>
                );
              })}
            </div>
          </div>
        </>
      ),
    },
  ];

  if (selectedAppType?.via !== APP_TYPES.PREBAKED_IMAGE.via && tabs.length === 1) {
    tabs.splice(1, 0, {
      key: "step1",
      showSidebar: true,
      content: (
        <>
          <h1>{translate("appStreamingModals.appUpload.header")}</h1>
          <p>{translate("appStreamingModals.appUpload.description")}</p>
          <div className="app-upload-container" ref={containerRef}>
            <AppUploadArea
              onFileDrop={handleFileDrop}
              error={appUploadError}
              isDragActive={isDragActive}
              translate={translate}
            />
          </div>
        </>
      ),
    });
  }
  return (
    <SlideModal
      className="app-upload-modal"
      closeAction={onCancel}
      confirmAction={onCancel}
      topRightIcon="close"
      contents={tabs}
      topRightIconAction={onCancel}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={handleDrop}
      big={!isMobile}
      hideFooter
      translate={translate}
      steps={APP_UPLOAD_MODAL_STEPS}
      sidebarHeader={translate("appStreamingModals.configuration.sidebar.header")}
      currentStep={activeTab}
    />
  );
};

export default AppUploadModal;
