import { all, debounce, put } from "redux-saga/effects";
import { getStatusCodeFamily, apiErrorHandler } from "Utils/Helpers/saga.helpers";
import * as LoginConstants from "Constants/Authentication/Login.constants";
import * as OrganizationConstants from "Constants/Organization.constants";
import { API_ENDPOINTS, STATUS_TYPE } from "Constants/api.constants";
import { removeKeysFromLocalStorage, setAccessTokenInLocalStorage } from "Utils/Helpers/storage.helpers";
import apiGenerator from "Utils/Helpers/api.helpers";
import queryClient from "Utils/Helpers/queryClient";

function* loginAPISaga(action) {
  const { requestPayload } = action.payload;
  const api = apiGenerator("post")(API_ENDPOINTS.LOGIN, requestPayload);

  try {
    const response = yield api;

    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      const { access_token: accessToken, user_uid: scope } = response.data;

      window.gtag("event", "login");
      if (window.mixpanel && typeof window.mixpanel.track === "function") {
        window.mixpanel.track("$login");
      }

      removeKeysFromLocalStorage(requestPayload.email);
      setAccessTokenInLocalStorage(accessToken);

      yield put({
        type: LoginConstants.LOGIN_API_SUCCESS,
        payload: { accessToken, scope },
      });
    } else {
      const error = apiErrorHandler({ response });

      yield put({
        type: LoginConstants.LOGIN_API_FAILURE,
        payload: error,
      });
    }
  } catch (err) {
    const error = apiErrorHandler(err);

    yield put({
      type: LoginConstants.LOGIN_API_FAILURE,
      payload: error,
    });
  }
}

function* logoutAPISaga(action) {
  const { accessToken, requestPayload } = action.payload;
  const api = apiGenerator("post", accessToken)(API_ENDPOINTS.LOGOUT, requestPayload);

  yield put({
    type: OrganizationConstants.ORGANIZATIONS_CLEAR, // clear organization state
  });
  queryClient.clear(); // remove react query cache

  try {
    removeKeysFromLocalStorage();
    yield api;
  } catch (err) {
    // Logout error
  }
}

function* checkFingerprintSaga(action) {
  const { requestPayload } = action.payload;
  const api = apiGenerator("post")(API_ENDPOINTS.CHECK_FINGERPRINT, requestPayload);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: LoginConstants.CHECK_FINGERPRINT_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: LoginConstants.CHECK_FINGERPRINT_FAILURE,
        payload: apiErrorHandler(response),
      });
    }
  } catch (err) {
    yield put({
      type: LoginConstants.CHECK_FINGERPRINT_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

function* verifyFingerprintSaga(action) {
  const { requestPayload } = action.payload;
  const api = apiGenerator("post")(API_ENDPOINTS.VERIFY_FINGERPRINT, requestPayload);

  try {
    const response = yield api;
    if (getStatusCodeFamily(response.status) === STATUS_TYPE.SUCCESS) {
      yield put({
        type: LoginConstants.VERIFY_FINGERPRINT_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: LoginConstants.VERIFY_FINGERPRINT_FAILURE,
        payload: apiErrorHandler(response),
      });
    }
  } catch (err) {
    yield put({
      type: LoginConstants.VERIFY_FINGERPRINT_FAILURE,
      payload: apiErrorHandler(err),
    });
  }
}

const DEBOUNCE_DELAY = 500;

export default function* root() {
  yield all([
    debounce(DEBOUNCE_DELAY, LoginConstants.LOGIN_API_PENDING, loginAPISaga),
    debounce(DEBOUNCE_DELAY, LoginConstants.LOGOUT_API_PENDING, logoutAPISaga),
    debounce(DEBOUNCE_DELAY, LoginConstants.CHECK_FINGERPRINT_PENDING, checkFingerprintSaga),
    debounce(DEBOUNCE_DELAY, LoginConstants.VERIFY_FINGERPRINT_PENDING, verifyFingerprintSaga),
  ]);
}
