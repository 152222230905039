import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Icon, SlideModal } from "UI";
import { isMobile } from "Utils";
import { CAPACITY_MANAGEMENT_TYPES, CAPACITY_TYPES } from "Constants/global.constants";
import { VENDOR_TYPES } from "Constants/AppStreaming.constants";
import CapacitySelection from "../CapacitySelection/CapacitySelection.component";
import ChooseRegion from "../CreateStreamModal/ChooseRegion.component";
import CapacityManagement from "../CreateStreamModal/CapacityManagement/CapacityManagement.component";

import "./StreamCapacityTypeModal.styles.scss";

const StreamCapacityTypeModal = ({
  translate,
  selectedApp,
  onClose,
  capacityManagement,
  setCapacityManagement,
  coveredRegions,
  selectedCapacities,
  onRegionClick,
  minSessionCostPerMinute,
  maxSessionCostPerMinute,
  newDailyCost,
  regionPricingLoading,
  capacity,
  setCapacity,
  reserveCapacity,
  setReserveCapacity,
  capacityType,
  setCapacityType,
  hideNumberInput,
  maxCapacity,
  showCapacityWarning,
  setShowCapacityWarning,
  capacityWarningConsent,
  setCapacityWarningConsent,
  handleCapacityTypeModalClose,
  setShowSubscriptionRequestModal,
  automatedAreaNames,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const fastConnection = capacityManagement === CAPACITY_MANAGEMENT_TYPES.fast_connection;
  const highDemand = capacityManagement === CAPACITY_MANAGEMENT_TYPES.high_demand;

  const { fast_connection_available: fastConnectionAvailable } =
    selectedApp?.attributes?.default_machine_type?.attributes || {};

  const vendorType = useSelector((state) => state.appStreaming.vendorType);
  const capacityWarningConsentApproved = useSelector(
    (state) => state.appStreaming.getVendorAccountCTX.data?.attributes?.capacity_warning_consent,
  );
  const canRequestSubscription = useSelector(
    (state) => state.appStreaming.getVendorAccountCTX.data?.attributes?.can_request_subscription,
  );

  const isVendorEnterprise = vendorType === VENDOR_TYPES.enterprise;

  const isLinux = selectedApp?.attributes?.os === "linux";

  const createStreamSteps = {
    servers: { step: 0, skipStep: isLinux },
    coverage: { step: 1 },
    capacity: { step: 2, skipStep: fastConnection },
  };

  const tabs = [
    {
      key: "step0",
      disabled: capacityManagement === null,
      showSidebar: true,
      hidePrevious: true,
      content: (
        <>
          <h1>{translate("appStreamingModals.stream.management.header")}</h1>
          <p>{translate("appStreamingModals.stream.management.description")}</p>
          <CapacityManagement
            isVendorEnterprise={isVendorEnterprise}
            fastConnectionAvailable={fastConnectionAvailable}
            isLinux={isLinux}
            capacityManagement={capacityManagement}
            setCapacityManagement={setCapacityManagement}
            canRequestSubscription={canRequestSubscription}
            setShowCreateStreamModal={() => {}}
            setShowSubscriptionRequestModal={setShowSubscriptionRequestModal}
            automatedAreaNames={automatedAreaNames}
            translate={translate}
          />
        </>
      ),
      overrideNextButtonText: "Next",
    },
    {
      key: "step1",
      disabled: selectedCapacities.length === 0 && highDemand,
      showSidebar: true,
      content: (
        <ChooseRegion
          coveredRegions={fastConnection ? automatedAreaNames : coveredRegions}
          selectedCapacities={selectedCapacities}
          onRegionClick={onRegionClick}
          minSessionCostPerMinute={minSessionCostPerMinute}
          maxSessionCostPerMinute={maxSessionCostPerMinute}
          newDailyCost={newDailyCost}
          translate={translate}
          regionPricingLoading={regionPricingLoading}
          hotPoolMap={fastConnection}
        />
      ),
      overrideNextButtonText: capacityManagement === CAPACITY_MANAGEMENT_TYPES.high_demand ? "Next" : "Done",
      overrideNextAction: fastConnection ? handleCapacityTypeModalClose : false,
      sidebarFooter: fastConnection && (
        <div className="modal-sidebar-footer">
          <Icon name="info" className="gray-5" />
          <p>{translate("appStreamingModals.stream.fastConnectionFooter")}</p>
        </div>
      ),
    },
    {
      key: "step2",
      showSidebar: true,
      disabled: !capacityType,
      content: (
        <CapacitySelection
          translate={translate}
          capacity={capacity}
          setCapacity={setCapacity}
          reserveCapacity={reserveCapacity}
          setReserveCapacity={setReserveCapacity}
          capacityType={capacityType}
          setCapacityType={setCapacityType}
          selectedCapacities={selectedCapacities}
          hideNumberInput={hideNumberInput}
          maxCapacity={maxCapacity}
          showCapacityWarning={showCapacityWarning}
          capacityWarningConsent={capacityWarningConsent}
          setCapacityWarningConsent={setCapacityWarningConsent}
        />
      ),
      overridePreviousAction: showCapacityWarning ? () => setShowCapacityWarning(false) : false,
      overrideNextAction:
        !capacityWarningConsentApproved && capacityType !== CAPACITY_TYPES.on_demand && !showCapacityWarning
          ? () => setShowCapacityWarning(true)
          : () => {
              handleCapacityTypeModalClose();
            },
      overrideNextButtonText: "Done",
    },
  ];

  return (
    <>
      <SlideModal
        className="new-stream-modal"
        closeAction={onClose}
        confirmAction={onClose}
        topRightIcon="close"
        contents={tabs}
        topRightIconAction={onClose}
        translate={translate}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        sidebarHeader="Streams"
        steps={createStreamSteps}
        currentStep={activeTab}
        big={!isMobile}
      />
    </>
  );
};

export default StreamCapacityTypeModal;
