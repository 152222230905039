import * as AppStreamingConstants from "Constants/AppStreaming.constants";
import { REQUEST_STATUS } from "Constants/global.constants";

const initialState = {
  getApplicationsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    appID: null,
    error: false,
  },
  getApplicationsStatsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
    regions: [],
  },
  getApplicationCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  getApplicationStatusCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: {},
    error: false,
  },
  getVendorApplicationsStatsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: {},
    error: false,
  },
  getMachineStatsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: {},
    error: false,
  },
  getApplicationImagesCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  getApplicationMachineTypesCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  getCustomerSessionStatsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: [],
    error: false,
  },
  testApplicationCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    error: false,
    connectionLink: null,
    clientCode: null,
  },
  getVendorAccountCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  getVendorChargeDetailsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  getVendorBalanceStatsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    total: null,
    estimation: null,
    error: false,
  },
  getVendorPaymentsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    total: null,
    error: false,
  },
  getCustomerStatsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  getCurrentPlanCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  createStreamCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
    client_code: null,
  },
  getStreamsCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  getStreamCapacitiesCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: null,
    error: false,
  },
  appCapacitiesCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: {},
    error: false,
  },
  pauseStreamCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: {},
    error: false,
  },
  updateStreamCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: {},
    error: false,
    client_code: null,
  },
  getStreamCTX: {
    status: REQUEST_STATUS.NOT_DEFINED,
    data: {},
    error: false,
  },
};

export default function appStreaming(state = initialState, action) {
  switch (action.type) {
    case AppStreamingConstants.GET_APPLICATIONS_API_PENDING:
      return getApplicationsAPIPending(state);
    case AppStreamingConstants.GET_APPLICATIONS_API_SUCCESS:
      return getApplicationsAPISuccess(state, action);
    case AppStreamingConstants.GET_APPLICATIONS_API_FAILURE:
      return getApplicationsAPIFailure(state);
    case AppStreamingConstants.GET_APPLICATIONS_STATS_API_PENDING:
      return getApplicationsStatsAPIPending(state);
    case AppStreamingConstants.GET_APPLICATIONS_STATS_API_SUCCESS:
      return getApplicationsStatsAPISuccess(state, action);
    case AppStreamingConstants.GET_APPLICATIONS_STATS_API_FAILURE:
      return getApplicationsStatsAPIFailure(state);
    case AppStreamingConstants.GET_APPLICATION_API_PENDING:
      return getApplicationAPIPending(state);
    case AppStreamingConstants.GET_APPLICATION_API_SUCCESS:
      return getApplicationAPISuccess(state, action);
    case AppStreamingConstants.GET_APPLICATION_API_FAILURE:
      return getApplicationAPIFailure(state);
    case AppStreamingConstants.GET_APPLICATION_STATUS_API_PENDING:
      return getApplicationStatusAPIPending(state);
    case AppStreamingConstants.GET_APPLICATION_STATUS_API_SUCCESS:
      return getApplicationStatusAPISuccess(state, action);
    case AppStreamingConstants.GET_APPLICATION_STATUS_API_FAILURE:
      return getApplicationStatusAPIFailure(state);
    case AppStreamingConstants.GET_VENDOR_APPLICATIONS_STATS_API_PENDING:
      return getVendorApplicationsStatsAPIPending(state);
    case AppStreamingConstants.GET_VENDOR_APPLICATIONS_STATS_API_SUCCESS:
      return getVendorApplicationsStatsAPISuccess(state, action);
    case AppStreamingConstants.GET_VENDOR_APPLICATIONS_STATS_API_FAILURE:
      return getVendorApplicationsStatsAPIFailure(state);
    case AppStreamingConstants.GET_MACHINE_STATS_API_PENDING:
      return getMachineStatsAPIPending(state);
    case AppStreamingConstants.GET_MACHINE_STATS_API_SUCCESS:
      return getMachineStatsAPISuccess(state, action);
    case AppStreamingConstants.GET_MACHINE_STATS_API_FAILURE:
      return getMachineStatsAPIFailure(state);
    case AppStreamingConstants.GET_APPLICATION_IMAGES_API_PENDING:
      return getApplicationImagesAPIPending(state);
    case AppStreamingConstants.GET_APPLICATION_IMAGES_API_SUCCESS:
      return getApplicationImagesAPISuccess(state, action);
    case AppStreamingConstants.GET_APPLICATION_IMAGES_API_FAILURE:
      return getApplicationImagesAPIFailure(state);
    case AppStreamingConstants.GET_APPLICATION_MACHINE_TYPES_API_PENDING:
      return getApplicationMachineTypesAPIPending(state);
    case AppStreamingConstants.GET_APPLICATION_MACHINE_TYPES_API_SUCCESS:
      return getApplicationMachineTypesAPISuccess(state, action);
    case AppStreamingConstants.GET_APPLICATION_MACHINE_TYPES_API_FAILURE:
      return getApplicationMachineTypesAPIFailure(state);
    case AppStreamingConstants.GET_CUSTOMER_SESSION_STATS_PENDING:
      return getCustomerSessionStatsPending(state);
    case AppStreamingConstants.GET_CUSTOMER_SESSION_STATS_SUCCESS:
      return getCustomerSessionStatsSuccess(state, action);
    case AppStreamingConstants.GET_CUSTOMER_SESSION_STATS_FAILURE:
      return getCustomerSessionStatsFailure(state);
    case AppStreamingConstants.TEST_APPLICATION_PENDING:
      return testApplicationPending(state);
    case AppStreamingConstants.TEST_APPLICATION_SUCCESS:
      return testApplicationSuccess(state, action);
    case AppStreamingConstants.TEST_APPLICATION_FAILURE:
      return testApplicationFailure(state, action);
    case AppStreamingConstants.GET_VENDOR_ACCOUNT_API_PENDING:
      return getVendorAccountPending(state);
    case AppStreamingConstants.GET_VENDOR_ACCOUNT_API_SUCCESS:
      return getVendorAccountSuccess(state, action);
    case AppStreamingConstants.GET_VENDOR_ACCOUNT_API_FAILURE:
      return getVendorAccountFailure(state);
    case AppStreamingConstants.GET_VENDOR_CHARGE_DETAILS_PENDING:
      return getVendorChargeDetailsPending(state, action);
    case AppStreamingConstants.GET_VENDOR_CHARGE_DETAILS_SUCCESS:
      return getVendorChargeDetailsSuccess(state, action);
    case AppStreamingConstants.GET_VENDOR_CHARGE_DETAILS_FAILURE:
      return getVendorChargeDetailsFailure(state);
    case AppStreamingConstants.GET_VENDOR_BALANCE_STATS_PENDING:
      return getVendorBalanceStatsPending(state, action);
    case AppStreamingConstants.GET_VENDOR_BALANCE_STATS_SUCCESS:
      return getVendorBalanceStatsSuccess(state, action);
    case AppStreamingConstants.GET_VENDOR_BALANCE_STATS_FAILURE:
      return getVendorBalanceStatsFailure(state);
    case AppStreamingConstants.GET_VENDOR_PAYMENTS_PENDING:
      return getVendorPaymentsPending(state, action);
    case AppStreamingConstants.GET_VENDOR_PAYMENTS_SUCCESS:
      return getVendorPaymentsSuccess(state, action);
    case AppStreamingConstants.GET_VENDOR_PAYMENTS_FAILURE:
      return getVendorPaymentsFailure(state);
    case AppStreamingConstants.GET_CUSTOMER_STATS_API_PENDING:
      return getCustomerStatsPending(state, action);
    case AppStreamingConstants.GET_CUSTOMER_STATS_API_SUCCESS:
      return getCustomerStatsSuccess(state, action);
    case AppStreamingConstants.GET_CUSTOMER_STATS_API_FAILURE:
      return getCustomerStatsFailure(state);
    case AppStreamingConstants.GET_CURRENT_PLAN_API_PENDING:
      return getCurrentPlanAPIPending(state, action);
    case AppStreamingConstants.GET_CURRENT_PLAN_API_SUCCESS:
      return getCurrentPlanAPISuccess(state, action);
    case AppStreamingConstants.GET_CURRENT_PLAN_API_FAILURE:
      return getCurrentPlanAPIFailure(state, action);
    case AppStreamingConstants.CREATE_STREAM_API_PENDING:
      return createStreamAPIPending(state, action);
    case AppStreamingConstants.CREATE_STREAM_API_SUCCESS:
      return createStreamAPISuccess(state, action);
    case AppStreamingConstants.CREATE_STREAM_API_FAILURE:
      return createStreamAPIFailure(state, action);
    case AppStreamingConstants.GET_STREAMS_API_PENDING:
      return getStreamsAPIPending(state, action);
    case AppStreamingConstants.GET_STREAMS_API_SUCCESS:
      return getStreamsAPISuccess(state, action);
    case AppStreamingConstants.GET_STREAMS_API_FAILURE:
      return getStreamsAPIFailure(state, action);
    case AppStreamingConstants.GET_STREAM_API_PENDING:
      return getStreamAPIPending(state, action);
    case AppStreamingConstants.GET_STREAM_API_SUCCESS:
      return getStreamAPISuccess(state, action);
    case AppStreamingConstants.GET_STREAM_API_FAILURE:
      return getStreamAPIFailure(state, action);
    case AppStreamingConstants.GET_STREAM_CAPACITIES_API_PENDING:
      return getStreamCapacitiesAPIPending(state, action);
    case AppStreamingConstants.GET_STREAM_CAPACITIES_API_SUCCESS:
      return getStreamCapacitiesAPISuccess(state, action);
    case AppStreamingConstants.GET_STREAM_CAPACITIES_API_FAILURE:
      return getStreamCapacitiesAPIFailure(state, action);
    case AppStreamingConstants.GET_APP_CAPACITIES_API_PENDING:
      return getAppCapacitiesAPIPending(state, action);
    case AppStreamingConstants.GET_APP_CAPACITIES_API_SUCCESS:
      return getAppCapacitiesAPISuccess(state, action);
    case AppStreamingConstants.GET_APP_CAPACITIES_API_FAILURE:
      return getAppCapacitiesAPIFailure(state, action);
    case AppStreamingConstants.PAUSE_STREAM_API_PENDING:
      return pauseStreamAPIPending(state, action);
    case AppStreamingConstants.PAUSE_STREAM_API_SUCCESS:
      return pauseStreamAPISuccess(state, action);
    case AppStreamingConstants.PAUSE_STREAM_API_FAILURE:
      return pauseStreamAPIFailure(state, action);
    case AppStreamingConstants.UPDATE_STREAM_API_PENDING:
      return updateStreamAPIPending(state, action);
    case AppStreamingConstants.UPDATE_STREAM_API_SUCCESS:
      return updateStreamAPISuccess(state, action);
    case AppStreamingConstants.UPDATE_STREAM_API_FAILURE:
      return updateStreamAPIFailure(state, action);
    case AppStreamingConstants.UPDATE_STREAM_API_RESET:
      return updateStreamAPIReset(state, action);
    case AppStreamingConstants.CAPACITY_WARNING_CONSENT_SUCCESS:
      return capacityWarningConsentSuccess(state, action);
    default:
      return state;
  }
}

function getApplicationsAPIPending(state) {
  return {
    ...state,
    getApplicationsCTX: {
      ...state.getApplicationsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getApplicationsAPISuccess(state, action) {
  return {
    ...state,
    getApplicationsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
    },
  };
}

function getApplicationsAPIFailure(state) {
  return {
    ...state,
    getApplicationsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getApplicationsStatsAPIPending(state) {
  return {
    ...state,
    getApplicationsStatsCTX: {
      ...state.getApplicationsStatsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getApplicationsStatsAPISuccess(state, action) {
  return {
    ...state,
    getApplicationsStatsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
      regions: action.payload?.region_stats,
    },
  };
}

function getApplicationsStatsAPIFailure(state) {
  return {
    ...state,
    getApplicationsStatsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getApplicationAPIPending(state) {
  return {
    ...state,
    getApplicationCTX: {
      ...state.getApplicationCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getApplicationAPISuccess(state, action) {
  return {
    ...state,
    getApplicationCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
    },
  };
}

function getApplicationAPIFailure(state) {
  return {
    ...state,
    getApplicationCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getApplicationStatusAPIPending(state) {
  return {
    ...state,
    getApplicationStatusCTX: {
      ...state.getApplicationStatusCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getApplicationStatusAPISuccess(state, action) {
  return {
    ...state,
    getApplicationStatusCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: { ...state.getApplicationStatusCTX.data, [action.payload.application.id]: action.payload },
    },
  };
}

function getApplicationStatusAPIFailure(state) {
  return {
    ...state,
    getApplicationStatusCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getVendorApplicationsStatsAPIPending(state) {
  return {
    ...state,
    getVendorApplicationsStatsCTX: {
      ...state.getVendorApplicationsStatsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getVendorApplicationsStatsAPISuccess(state, action) {
  return {
    ...state,
    getVendorApplicationsStatsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function getVendorApplicationsStatsAPIFailure(state) {
  return {
    ...state,
    getVendorApplicationsStatsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getMachineStatsAPIPending(state) {
  return {
    ...state,
    getMachineStatsCTX: {
      ...state.getVendorApplicationsStatsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getMachineStatsAPISuccess(state, action) {
  return {
    ...state,
    getMachineStatsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function getMachineStatsAPIFailure(state) {
  return {
    ...state,
    getMachineStatsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getApplicationImagesAPIPending(state) {
  return {
    ...state,
    getApplicationImagesCTX: {
      ...state.getApplicationImagesCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getApplicationImagesAPISuccess(state, action) {
  return {
    ...state,
    getApplicationImagesCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function getApplicationImagesAPIFailure(state) {
  return {
    ...state,
    getApplicationImagesCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getApplicationMachineTypesAPIPending(state) {
  return {
    ...state,
    getApplicationMachineTypesCTX: {
      ...state.getApplicationMachineTypesCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getApplicationMachineTypesAPISuccess(state, action) {
  return {
    ...state,
    getApplicationMachineTypesCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function getApplicationMachineTypesAPIFailure(state) {
  return {
    ...state,
    getApplicationMachineTypesCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getCustomerSessionStatsPending(state) {
  return {
    ...state,
    getCustomerSessionStatsCTX: {
      ...state.getCustomerSessionStatsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getCustomerSessionStatsSuccess(state, action) {
  return {
    ...state,
    getCustomerSessionStatsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function getCustomerSessionStatsFailure(state) {
  return {
    ...state,
    getCustomerSessionStatsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function testApplicationPending(state) {
  return {
    ...state,
    testApplicationCTX: {
      ...state.testApplicationCTX,
      status: REQUEST_STATUS.PENDING,
    },
  };
}

function testApplicationSuccess(state, action) {
  return {
    ...state,
    testApplicationCTX: {
      ...state.testApplicationCTX,
      status: REQUEST_STATUS.SUCCESS,
      connectionLink: action.payload.attributes.connection_link,
    },
  };
}

function testApplicationFailure(state, action) {
  const { client_code: error } = action.payload;
  return {
    ...state,
    testApplicationCTX: {
      ...state.testApplicationCTX,
      status: REQUEST_STATUS.FAILURE,
      error,
      clientCode: action.payload.client_code,
    },
  };
}

function getVendorAccountPending(state) {
  return {
    ...state,
    getVendorAccountCTX: {
      ...state.getVendorAccountCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getVendorAccountSuccess(state, action) {
  return {
    ...state,
    getVendorAccountCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
    },
    vendorType:
      (action.payload.attributes.pro && "pro") || (action.payload.attributes.enterprise && "enterprise") || "basic",
  };
}

function getVendorAccountFailure(state) {
  return {
    ...state,
    getVendorAccountCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getVendorChargeDetailsPending(state, action) {
  return {
    ...state,
    getVendorChargeDetailsCTX: {
      ...state.getVendorChargeDetailsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
      payload: action.payload,
    },
  };
}

function getVendorChargeDetailsSuccess(state, action) {
  return {
    ...state,
    getVendorChargeDetailsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
      estimation: action.payload?.estimation,
      total: action.payload?.total,
    },
  };
}

function getVendorChargeDetailsFailure(state) {
  return {
    ...state,
    getVendorChargeDetailsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getVendorBalanceStatsPending(state, action) {
  return {
    ...state,
    getVendorBalanceStatsCTX: {
      ...state.getVendorBalanceStatsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
      payload: action.payload,
    },
  };
}

function getVendorBalanceStatsSuccess(state, action) {
  return {
    ...state,
    getVendorBalanceStatsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
    },
  };
}

function getVendorBalanceStatsFailure(state) {
  return {
    ...state,
    getVendorBalanceStatsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getVendorPaymentsPending(state, action) {
  return {
    ...state,
    getVendorPaymentsCTX: {
      ...state.getVendorPaymentsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
      payload: action.payload,
    },
  };
}

function getVendorPaymentsSuccess(state, action) {
  return {
    ...state,
    getVendorPaymentsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
    },
  };
}

function getVendorPaymentsFailure(state) {
  return {
    ...state,
    getVendorPaymentsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getCustomerStatsPending(state, action) {
  return {
    ...state,
    getCustomerStatsCTX: {
      ...state.getCustomerStatsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
      payload: action.payload,
    },
  };
}

function getCustomerStatsSuccess(state, action) {
  return {
    ...state,
    getCustomerStatsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
    },
  };
}

function getCustomerStatsFailure(state) {
  return {
    ...state,
    getCustomerStatsCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getCurrentPlanAPIPending(state) {
  return {
    ...state,
    getCurrentPlanCTX: {
      ...state.getCurrentPlanCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getCurrentPlanAPISuccess(state, action) {
  return {
    ...state,
    getCurrentPlanCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
    },
  };
}

function getCurrentPlanAPIFailure(state) {
  return {
    ...state,
    getCurrentPlanCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function createStreamAPIPending(state) {
  return {
    ...state,
    createStreamCTX: {
      ...state.createStreamCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function createStreamAPISuccess(state, action) {
  return {
    ...state,
    createStreamCTX: {
      status: REQUEST_STATUS.SUCCESS,
      data: action.payload,
    },
  };
}

function createStreamAPIFailure(state, action) {
  return {
    ...state,
    createStreamCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
      client_code: action.payload.client_code,
    },
  };
}

function getStreamsAPIPending(state) {
  return {
    ...state,
    getStreamsCTX: {
      ...state.getStreamsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getStreamsAPISuccess(state, action) {
  return {
    ...state,
    getStreamsCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function getStreamsAPIFailure(state) {
  return {
    ...state,
    getStreamsCTX: {
      ...state.getStreamsCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getStreamAPIPending(state) {
  return {
    ...state,
    getStreamCTX: {
      ...state.getStreamsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getStreamAPISuccess(state, action) {
  return {
    ...state,
    getStreamCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function getStreamAPIFailure(state) {
  return {
    ...state,
    getStreamCTX: {
      ...state.getStreamsCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getStreamCapacitiesAPIPending(state) {
  return {
    ...state,
    getStreamCapacitiesCTX: {
      ...state.getStreamsCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getStreamCapacitiesAPISuccess(state, action) {
  return {
    ...state,
    getStreamCapacitiesCTX: {
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function getStreamCapacitiesAPIFailure(state) {
  return {
    ...state,
    getStreamCapacitiesCTX: {
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function getAppCapacitiesAPIPending(state) {
  return {
    ...state,
    appCapacitiesCTX: {
      ...state.appCapacitiesCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function getAppCapacitiesAPISuccess(state, action) {
  return {
    ...state,
    appCapacitiesCTX: {
      ...state.appCapacitiesCTX,
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: { ...state.appCapacitiesCTX.data, ...action.payload },
    },
  };
}

function getAppCapacitiesAPIFailure(state) {
  return {
    ...state,
    appCapacitiesCTX: {
      ...state.appCapacitiesCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function pauseStreamAPIPending(state) {
  return {
    ...state,
    pauseStreamCTX: {
      ...state.pauseStreamCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function pauseStreamAPISuccess(state, action) {
  return {
    ...state,
    pauseStreamCTX: {
      ...state.pauseStreamCTX,
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function pauseStreamAPIFailure(state) {
  return {
    ...state,
    pauseStreamCTX: {
      ...state.pauseStreamCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
    },
  };
}

function updateStreamAPIPending(state) {
  return {
    ...state,
    updateStreamCTX: {
      ...state.updateStreamCTX,
      status: REQUEST_STATUS.PENDING,
      error: false,
    },
  };
}

function updateStreamAPISuccess(state, action) {
  return {
    ...state,
    updateStreamCTX: {
      ...state.updateStreamCTX,
      status: REQUEST_STATUS.SUCCESS,
      error: false,
      data: action.payload,
    },
  };
}

function updateStreamAPIFailure(state, action) {
  return {
    ...state,
    updateStreamCTX: {
      ...state.updateStreamCTX,
      status: REQUEST_STATUS.FAILURE,
      error: true,
      client_code: action.payload.client_code,
    },
  };
}

function updateStreamAPIReset(state) {
  return {
    ...state,
    updateStreamCTX: {
      ...state.updateStreamCTX,
      status: REQUEST_STATUS.NOT_DEFINED,
      error: false,
      client_code: null,
    },
  };
}

function capacityWarningConsentSuccess(state) {
  return {
    ...state,
    getVendorAccountCTX: {
      ...state.getVendorAccountCTX,
      data: {
        ...state.getVendorAccountCTX.data,
        attributes: {
          ...state.getVendorAccountCTX.data.attributes,
          capacity_warning_consent: true,
        },
      },
    },
  };
}
