import React from "react";
import { useMutation } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import { apiGenerator } from "Utils";
import { ConfirmationModal } from "UI";
import ModalResult from "Components/Shared/ModalResult/ModalResult.component";

const DeleteTemplateModal = ({ silverImageId, onClose }) => {
  const deleteSilverImageMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("delete")(API_ENDPOINTS.ORGANIZATIONS_SILVER_IMAGE(silverImageId));
    },
  });

  if (deleteSilverImageMutation.isSuccess) {
    return (
      <ModalResult
        success={deleteSilverImageMutation.isSuccess}
        successHeader="Successfully Deleted"
        successDescription="Selected template has been successfully deleted."
        onClose={onClose}
      />
    );
  }

  return (
    <ConfirmationModal
      headerText="Delete Template"
      descriptionText="Deleting this template will remove it permanently. However, you can always
      create a new template using existing computers or the application library. Are you sure you
      want to delete this template?"
      closeAction={() => {
        deleteSilverImageMutation.reset();
        onClose();
      }}
      confirmText="Confirm"
      confirmAction={() => {
        deleteSilverImageMutation.mutate();
      }}
      pending={deleteSilverImageMutation.isLoading}
    />
  );
};

export default DeleteTemplateModal;
