import React, { useEffect, useMemo, useState } from "react";
import { DivButton, ExpandableTable, HoverableTooltip, Icon, IconButton, TextButton } from "UI";
import { Loader, convertDateFormat, localDateFormat, useAPI } from "Utils";
import { classnames, convertToSelectedTimeZone, secondsToTime } from "Utils/Helpers/functions.helpers";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import translate from "Stories/translate";
import NoTransactionsImage from "Assets/images/appStreaming/app-streaming_first review 1.png";
import { STREAM_AUDIO_STATES } from "Constants/streaming.constants";
import useCustomInfiniteQuery from "Utils/Hooks/useCustomInfiniteQuery";
import { convertCSVFileAndDownload } from "Utils/Helpers/file.helpers";
import { history } from "Configs/store";
import moment from "moment";
import { REGIONS_NAMES } from "Constants/global.constants";
import routes from "Routes/routes";
import { STATS_SESSIONS_FILTERED_DUMMY_DATA } from "Constants/StreamsOnboarding.constants";
import { useSelector } from "react-redux";
import { AUTOMATED_AREAS } from "Constants/AppStreaming.constants";

const TABS = {
  CONNECTED_VISITORS: 0,
  STREAM_SESSIONS: 1,
};

const BG_STYLE_BY_MACHINE_STATUS = {
  terminated: { background: "rgba(130, 130, 130, 1)" },
  waiting: { backgroundColor: "rgba(236, 177, 66, 1)" },
  available: { backgroundColor: "rgba(115, 212, 199, 1)" },
  connected: { backgroundColor: "rgba(69, 0, 166, 1)" },
  cleaning: { backgroundColor: "rgba(115, 212, 199, 1)" },
  assigned: { backgroundColor: "rgba(171, 182, 255, 1)" },
  proxy_rule_migration: { backgroundColor: "rgba(115, 212, 199, 1)" },
  failed_session: { backgroundColor: "rgba(224, 137, 137, 1)" },
};

const STRENGTH_THRESHOLDS = { strong: 50, good: 100, low: 200 };

const signalStrength = (ping) => {
  switch (true) {
    case ping < STRENGTH_THRESHOLDS.high:
      return "strong";
    case ping < STRENGTH_THRESHOLDS.good:
      return "good";
    case ping < STRENGTH_THRESHOLDS.low:
      return "low";
    default:
      return "weak";
  }
};

const connectionColumn = (props) => {
  const audioStates = STREAM_AUDIO_STATES;
  const { city, country, ping, os, deviceType, audioState, logsUrl } = props;
  if (ping) {
    return (
      <div className="connection-column">
        {deviceType && ![audioStates.NOT_COLLECTED, audioStates.WARNING].includes(audioState) && (
          <Icon name={audioState === audioStates.PLAYING ? "stream-sound-on" : "stream-sound-off"} />
        )}
        {deviceType && os && (
          <HoverableTooltip content={os}>
            <Icon name={deviceType} />
          </HoverableTooltip>
        )}
        {logsUrl && (
          <HoverableTooltip content="Download Logs">
            <IconButton name="logs" onClick={() => window.open(logsUrl, "_blank")} />
          </HoverableTooltip>
        )}
        <HoverableTooltip content={city && country ? `${city}, ${country}` : null}>
          <Icon name={`signal-${signalStrength(ping)}`} smaller />
        </HoverableTooltip>
      </div>
    );
  }
  return "";
};

const ipColumn = (props) => {
  const { publicIpAddress, copiedIpAddress, setCopiedIpAddress } = props;
  if (publicIpAddress) {
    return (
      <HoverableTooltip
        onClose={() => {
          setCopiedIpAddress(null);
        }}
        content={
          <DivButton
            className="ip-address-column"
            onClick={() => {
              navigator.clipboard.writeText(publicIpAddress);
              setCopiedIpAddress(publicIpAddress);
            }}
          >
            <p>Click to Copy IP Address</p>
            <p>
              {publicIpAddress}
              {copiedIpAddress === publicIpAddress && <span>Copied</span>}
            </p>
          </DivButton>
        }
        side="right"
      >
        <Icon name="ip-address" small />
      </HoverableTooltip>
    );
  }
  return "";
};

const formatConnectedVisitors = (sessionsData, csv = false) => {
  return (
    sessionsData?.map((sessionData) => {
      const startTime = sessionData.attributes.start_at;
      const {
        session,
        duration: minutes,
        ping,
        os,
        city,
        country,
        device_type: deviceType,
        audio_state: audioState,
        stream_name: streamName,
        child_app_name: childAppName,
        application_logs_url: logsUrl,
        region,
      } = sessionData.attributes;
      const { capacity_type_at_creation: capacityTypeAtCreation } = session.attributes;

      const fromHotPool = capacityTypeAtCreation === "automated";

      const showCollectedData =
        deviceType && ![STREAM_AUDIO_STATES.NOT_COLLECTED, STREAM_AUDIO_STATES.WARNING].includes(audioState);

      const formatConnectionColumn = () => {
        let string = "";

        string += ping ? `${ping}ms` : "";

        if (showCollectedData) {
          string += ` - ${city} - ${country} - ${os} - ${deviceType} - ${audioState}`;
        }

        return string;
      };

      const applicationName = session.attributes.application.attributes.name;

      const momentDuration = moment.duration(minutes, "minutes");
      const duration = `${String(Math.floor(momentDuration.asHours())).padStart(2, "0")}:${String(
        momentDuration.minutes(),
      ).padStart(2, "0")}`;

      const data = {
        stream: streamName,
        id: sessionData.id,
        key: sessionData.id,
        visitor: sessionData.attributes.customer,
        application: childAppName || applicationName,
        date: `${convertDateFormat(startTime.substring(0, 10))} ${startTime.substring(
          startTime.indexOf("T") + 1,
          startTime.indexOf("T") + 6,
        )}`,
        duration,
        region: fromHotPool ? translate(`automatedAreas.${AUTOMATED_AREAS[region]}`) : REGIONS_NAMES[region],
        performance: session.attributes.machine_type.attributes.stream_friendly_name,
        connection: csv
          ? formatConnectionColumn()
          : connectionColumn({ city, country, ping, os, deviceType, audioState, logsUrl }),
      };

      if (csv) {
        data.application_logs_collected = logsUrl ? "Yes" : "No";
      }

      return data;
    }) || []
  );
};

const UserSessionTable = ({
  startDate,
  endDate,
  applicationFilter,
  streamNameFilter,
  regionFilter,
  streamStats,
  showMachines,
  displayDummyData,
  onboardingModalCurrentStep,
  onboardingCustomDisable,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { time_zone: userTimeZone } = useSelector((state) => state?.account?.account?.attributes) || {};
  const firstDayOfMonth = moment().startOf("month").format("DD-MM-YYYY");
  const today = convertDateFormat(new Date().toISOString().substring(0, 10));
  const [copiedIpAddress, setCopiedIpAddress] = useState(null);

  useEffect(() => {
    if (showMachines) {
      setActiveTab(TABS.STREAM_SESSIONS);
      history.replace({
        pathname: routes.appStreamingUsers,
        state: { showMachines: false },
      });
    }
  }, [showMachines]);

  useEffect(() => {
    if (onboardingCustomDisable) {
      setActiveTab(TABS.STREAM_SESSIONS);
    } else {
      setActiveTab(TABS.CONNECTED_VISITORS);
    }
  }, [onboardingModalCurrentStep, onboardingCustomDisable]);

  const {
    data: sessionDetailsData,
    fetchNextPage: fetchNextPageMachines,
    isFetchingNextPage: isFetchingNextPageMachines,
    hasNextPage: hasNextPageMachines,
    isLoading: machinesLoading,
  } = useCustomInfiniteQuery({
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_MACHINES,
    params: {
      start_at: startDate ? localDateFormat(startDate) : firstDayOfMonth,
      end_at: endDate ? localDateFormat(endDate) : today,
      application_id: applicationFilter?.id,
      stream_id: streamNameFilter?.id,
      region: regionFilter === "all regions" ? null : regionFilter,
    },
    resource: "machines",
  });

  const {
    data: connectedVisitorsData,
    fetchNextPage: fetchNextPageVisitors,
    isFetchingNextPage: isFetchingNextPageVisitors,
    hasNextPage: hasNextPageVisitors,
    isLoading: visitorsLoading,
  } = useCustomInfiniteQuery({
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_CUSTOMER_SESSION_DETAILS,
    params: {
      start_at: startDate ? localDateFormat(startDate) : firstDayOfMonth,
      end_at: endDate ? localDateFormat(endDate) : today,
      application_id: applicationFilter?.id,
      stream_id: streamNameFilter?.id,
      region: regionFilter === "all regions" ? null : regionFilter,
    },
    resource: "sessions",
  });

  const { api: getAllSessionDetailsAPI, data: allSessionDetailsData } = useAPI({
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_MACHINES,
    type: "get",
  });

  const { api: getAllConnectedVisitorsAPI, data: allConnectedVisitorsData } = useAPI({
    endpoint: APP_STREAMING_API_ENDPOINTS.GET_CUSTOMER_SESSION_DETAILS,
    type: "get",
  });

  const formatSessionDetails = (machinesData, csv = false) => {
    return machinesData?.map((machineData) => {
      const {
        start_at: startTime,
        application_name: appName,
        region,
        connection_status: status,
        duration,
        cost,
        failed_session: failedSession,
        stream_name: streamName,
        public_ip_address: publicIpAddress,
        capacity_type_at_creation: capacityTypeAtCreation,
      } = machineData.attributes;

      const fromHotPool = capacityTypeAtCreation === "automated";

      const statusTranslation = failedSession
        ? translate("appStreamingUsers.status.failed_session")
        : translate(`appStreamingUsers.status.${status}`);
      return {
        id: machineData.id,
        key: machineData.id,
        stream: streamName,
        started: `${convertDateFormat(startTime.substr(0, 10))} ${startTime.substring(
          startTime.indexOf("T") + 1,
          startTime.indexOf("T") + 6,
        )}`,
        application: appName,
        region: fromHotPool ? translate(`automatedAreas.${AUTOMATED_AREAS[region]}`) : REGIONS_NAMES[region],
        status: csv ? (
          statusTranslation
        ) : (
          <div className="status">
            <div
              style={failedSession ? BG_STYLE_BY_MACHINE_STATUS.failed_session : BG_STYLE_BY_MACHINE_STATUS[status]}
            />{" "}
            <span>{statusTranslation}</span>
          </div>
        ),

        duration: secondsToTime(duration),
        usage: `$ ${cost}`,
        ip: csv ? "" : ipColumn({ publicIpAddress, copiedIpAddress, setCopiedIpAddress }),
      };
    });
  };

  const downloadAllData = async () => {
    await getAllSessionDetailsAPI({
      params: {
        start_at: startDate ? localDateFormat(startDate) : firstDayOfMonth,
        end_at: endDate ? localDateFormat(endDate) : today,
        application_id: applicationFilter?.id,
        stream_id: streamNameFilter?.id,
        region: regionFilter === "all regions" ? null : regionFilter,
        without_pagination: true,
      },
    });

    await getAllConnectedVisitorsAPI({
      params: {
        start_at: startDate ? localDateFormat(startDate) : firstDayOfMonth,
        end_at: endDate ? localDateFormat(endDate) : today,
        application_id: applicationFilter?.id,
        stream_id: streamNameFilter?.id,
        region: regionFilter === "all regions" ? null : regionFilter,
        without_pagination: true,
      },
    });
  };

  const selectData = () => {
    if (displayDummyData) {
      return formatSessionDetails(STATS_SESSIONS_FILTERED_DUMMY_DATA);
    }
    const updatedVisitorsDate = connectedVisitors.map((visitorData) => ({
      ...visitorData,
      date: convertToSelectedTimeZone(visitorData.date, userTimeZone),
    }));

    const updatedsessionDetailsDate = sessionDetails.map((sessionData) => ({
      ...sessionData,
      started: convertToSelectedTimeZone(sessionData.started, userTimeZone),
    }));

    return activeTab === TABS.STREAM_SESSIONS ? updatedsessionDetailsDate : updatedVisitorsDate;
  };

  useEffect(() => {
    if (allSessionDetailsData && allConnectedVisitorsData) {
      convertCSVFileAndDownload(
        [
          { name: "visitor-sessions.csv", data: formatConnectedVisitors(allConnectedVisitorsData?.sessions, true) },
          { name: "stream-machines.csv", data: formatSessionDetails(allSessionDetailsData?.machines, true) },
        ],
        "VagonStreams-stats.zip",
      );
    }
  }, [allSessionDetailsData, allConnectedVisitorsData]);

  const connectedVisitors = formatConnectedVisitors(connectedVisitorsData);
  const sessionDetails = formatSessionDetails(sessionDetailsData);

  const dataCount = activeTab === TABS.STREAM_SESSIONS ? sessionDetailsData?.count : connectedVisitorsData?.count;
  const vacantConnectedVisitor = connectedVisitors.length === 0 && activeTab === TABS.CONNECTED_VISITORS;
  const vacantSessionDetails = sessionDetails.length === 0 && activeTab === TABS.STREAM_SESSIONS;
  const deviceTypePresent = connectedVisitorsData?.some((sessionData) => sessionData.attributes.device_type);
  const logsUrlPresent = connectedVisitorsData?.some((sessionData) => sessionData.attributes.application_logs_url);
  const disableDownloadIcon = !sessionDetailsData.length && !onboardingCustomDisable;

  const connectionColumnWeight = useMemo(() => {
    if (deviceTypePresent && logsUrlPresent) return 40;
    if (deviceTypePresent || logsUrlPresent) return 30;
    return 10;
  }, [deviceTypePresent, logsUrlPresent]);

  const connectedVisitorColumns = [
    { name: "Stream", weight: 50 },
    { name: "Visitor", weight: 40 },
    { name: "Date", weight: 50 },
    { name: "Application", weight: 50 },
    { name: "Region", weight: 50 },
    { name: "Performance", weight: 50 },
    { name: "Duration", weight: 40 },
    { name: "Connection", weight: connectionColumnWeight, customWeight: 10, hide: true },
  ];
  const sessionDetailColumns = [
    { name: "ID", weight: 30 },
    { name: "Stream", weight: 60 },
    { name: "Started", weight: 60 },
    { name: "Application", weight: 60 },
    { name: "Region", weight: 50 },
    { name: "Status", weight: 40 },
    { name: "Duration", weight: 40 },
    { name: "Usage", weight: 30 },
    { name: "IP", weight: 10, customWeight: 10, hide: true },
  ];

  if (
    (activeTab === TABS.CONNECTED_VISITORS && machinesLoading) ||
    (activeTab === TABS.STREAM_SESSIONS && visitorsLoading)
  )
    return <Loader />;

  return (
    <>
      <div className="table-input-line">
        <div className="tab-buttons-container">
          <TextButton
            text={translate("appStreamingUsers.tabs.connectedVisitors")}
            color={activeTab === TABS.CONNECTED_VISITORS ? "purple" : "gray-3"}
            onClick={() => !onboardingCustomDisable && setActiveTab(TABS.CONNECTED_VISITORS)}
          />
          <TextButton
            text={translate("appStreamingUsers.tabs.streamSessions")}
            color={activeTab === TABS.STREAM_SESSIONS ? "purple" : "gray-3"}
            onClick={() => !onboardingCustomDisable && setActiveTab(TABS.STREAM_SESSIONS)}
          />
        </div>
        <HoverableTooltip content={!disableDownloadIcon && "Download"}>
          <IconButton
            className={classnames([
              "stats-download-icon",
              disableDownloadIcon && "disabled",
              onboardingCustomDisable && "onboarding-disable",
            ])}
            name="file-download"
            small
            color="gray-4"
            onClick={() => {
              if (!onboardingCustomDisable) downloadAllData();
            }}
            disabled={disableDownloadIcon}
          />
        </HoverableTooltip>
      </div>
      <div className="content-container-xd">
        {(dataCount === 0 || vacantConnectedVisitor || vacantSessionDetails) && !displayDummyData && (
          <div className="no-user-stats-content">
            <img src={NoTransactionsImage} />
            <span>{translate("appStreamingUsers.noStats")}</span>
          </div>
        )}
        {((dataCount !== 0 && !vacantConnectedVisitor && !vacantSessionDetails) || displayDummyData) && (
          <ExpandableTable
            key={activeTab === TABS.STREAM_SESSIONS ? "sessionDetails" : "connectedVisitor"}
            columns={activeTab === TABS.STREAM_SESSIONS ? sessionDetailColumns : connectedVisitorColumns}
            data={selectData()}
            hasNextPage={activeTab === TABS.STREAM_SESSIONS ? hasNextPageMachines : hasNextPageVisitors}
            onPageEnd={activeTab === TABS.STREAM_SESSIONS ? fetchNextPageMachines : fetchNextPageVisitors}
            isFetchingNextPage={
              activeTab === TABS.STREAM_SESSIONS ? isFetchingNextPageMachines : isFetchingNextPageVisitors
            }
            className={classnames(["app-streaming-users-table", streamStats && "stream-stats"])}
          />
        )}
      </div>
    </>
  );
};

export default UserSessionTable;
