import * as AppStreamingConstants from "Constants/AppStreaming.constants";

export const getApplicationsAPI = () => ({
  type: AppStreamingConstants.GET_APPLICATIONS_API_PENDING,
});

export const getApplicationsStatsAPI = (appID) => ({
  type: AppStreamingConstants.GET_APPLICATIONS_STATS_API_PENDING,
  payload: { appID },
});

export const getApplicationAPI = (appID) => ({
  type: AppStreamingConstants.GET_APPLICATION_API_PENDING,
  payload: { appID },
});

export const getApplicationStatusAPI = (appID) => ({
  type: AppStreamingConstants.GET_APPLICATION_STATUS_API_PENDING,
  payload: { appID },
});

export const getAppCapacitiesAPI = (appID) => ({
  type: AppStreamingConstants.GET_APP_CAPACITIES_API_PENDING,
  payload: { appID },
});

export const getApplicationMachineTypesAPI = (appID) => ({
  type: AppStreamingConstants.GET_APPLICATION_MACHINE_TYPES_API_PENDING,
  payload: { appID },
});

export const getVendorApplicationsStatsAPI = (startAt = null, endAt = null) => ({
  type: AppStreamingConstants.GET_VENDOR_APPLICATIONS_STATS_API_PENDING,
  payload: { startAt, endAt },
});

export const getApplicationImagesAPI = (appID) => ({
  type: AppStreamingConstants.GET_APPLICATION_IMAGES_API_PENDING,
  payload: { appID },
});

export const getCustomerSessionStatsAPI = (appID) => ({
  type: AppStreamingConstants.GET_CUSTOMER_SESSION_STATS_PENDING,
  payload: { appID },
});

export const getMachineStatsAPI = (startAt = null, endAt = null) => ({
  type: AppStreamingConstants.GET_MACHINE_STATS_API_PENDING,
  payload: { startAt, endAt },
});

export const testApplicationAPI = (appID, region) => ({
  type: AppStreamingConstants.TEST_APPLICATION_PENDING,
  payload: { appID, region },
});

export const getVendorAccountAPI = () => ({
  type: AppStreamingConstants.GET_VENDOR_ACCOUNT_API_PENDING,
});

export const getVendorChargeDetailsAPI = (
  startAt = null,
  endAt = null,
  transactionCategory = null,
  detailLevel = "stream",
) => ({
  type: AppStreamingConstants.GET_VENDOR_CHARGE_DETAILS_PENDING,
  payload: { startAt, endAt, detailLevel, transactionCategory },
});

export const getVendorBalanceStatsAPI = (startAt = null, endAt = null) => ({
  type: AppStreamingConstants.GET_VENDOR_BALANCE_STATS_PENDING,
  payload: { startAt, endAt },
});

export const getVendorPaymentsAPI = (startAt = null, endAt = null, paymentType = null) => ({
  type: AppStreamingConstants.GET_VENDOR_PAYMENTS_PENDING,
  payload: { startAt, endAt, paymentType },
});

export const getCustomerStatsAPI = (startAt = null, endAt = null) => ({
  type: AppStreamingConstants.GET_CUSTOMER_STATS_API_PENDING,
  payload: { startAt, endAt },
});

export const getCurrentPlanAPI = () => ({
  type: AppStreamingConstants.GET_CURRENT_PLAN_API_PENDING,
});

export const createStreamAPI = ({ appID, capacities, capacityManagement, boostEnabled }) => ({
  type: AppStreamingConstants.CREATE_STREAM_API_PENDING,
  payload: { appID, capacities, capacityManagement, boostEnabled },
});

export const getStreamsAPI = () => ({
  type: AppStreamingConstants.GET_STREAMS_API_PENDING,
});

export const getStreamCapacitiesAPI = (streamID) => ({
  type: AppStreamingConstants.GET_STREAM_CAPACITIES_API_PENDING,
  payload: { streamID },
});

export const pauseStreamAPI = (streamID) => ({
  type: AppStreamingConstants.PAUSE_STREAM_API_PENDING,
  payload: { streamID },
});

export const updateStreamAPI = (streamID, updateType, data) => ({
  type: AppStreamingConstants.UPDATE_STREAM_API_PENDING,
  payload: { streamID, updateType, data },
});

export const updateStreamAPIReset = () => ({
  type: AppStreamingConstants.UPDATE_STREAM_API_RESET,
});

export const getStreamAPI = (streamID) => ({
  type: AppStreamingConstants.GET_STREAM_API_PENDING,
  payload: { streamID },
});

export const approveCapacityWarningConsent = () => ({
  type: AppStreamingConstants.CAPACITY_WARNING_CONSENT_PENDING,
});
