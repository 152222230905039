import { useMutation } from "@tanstack/react-query";
import ModalResult from "Components/Shared/ModalResult/ModalResult.component";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input, Modal } from "UI";
import { apiGenerator } from "Utils";

const SubscriptionRequestModal = ({ translate, handleClose }) => {
  const [useCase, setUseCase] = useState("");
  const [contactName, setContactName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const userName = useSelector((state) => state.account.account?.attributes?.name);
  const vendorName = useSelector((state) => state.appStreaming?.getVendorAccountCTX?.data?.attributes?.name);
  const [showErrors, setShowErrors] = useState(false);

  const inputErrors = showErrors
    ? {
        useCase: !useCase,
        contactName: !contactName,
        companyName: !companyName,
        companyWebsite: !companyWebsite,
      }
    : {};

  useEffect(() => {
    if (userName) {
      setContactName(userName);
    }
  }, [userName]);

  useEffect(() => {
    if (vendorName) {
      setCompanyName(vendorName);
    }
  }, [vendorName]);

  const subscriptionRequestMutation = useMutation({
    mutationFn: () => {
      return apiGenerator("post")(APP_STREAMING_API_ENDPOINTS.SUBSCRIPTION_REQUESTS, {
        note: useCase,
        request_type: "enterprise_trial",
        company_name: companyName,
        company_website: companyWebsite,
        contact_name: contactName,
      });
    },
  });

  const handleActionButtonClick = () => {
    if (!allInputsFilled) {
      setShowErrors(true);
      return;
    }
    subscriptionRequestMutation.mutate();
  };

  if (subscriptionRequestMutation.isSuccess) {
    return (
      <ModalResult
        success
        successHeader={translate("appStreamingModals.subscriptionRequestModal.success.header")}
        successDescription={translate("appStreamingModals.subscriptionRequestModal.success.description")}
        onClose={handleClose}
      />
    );
  }

  const allInputsFilled = useCase && contactName && companyName && companyWebsite;

  return (
    <Modal
      className="centered"
      closeAction={handleClose}
      topRightIcon="close"
      topRightIconAction={handleClose}
      headerText={translate("appStreamingModals.subscriptionRequestModal.header")}
      descriptionText={translate("appStreamingModals.subscriptionRequestModal.description")}
      buttonText={translate("appStreamingModals.subscriptionRequestModal.button")}
      buttonDisabled={subscriptionRequestMutation.isLoading}
      buttonAction={handleActionButtonClick}
      small
      errorable
      errorText={subscriptionRequestMutation.isError && translate("appStreamingModals.subscriptionRequestModal.error")}
    >
      <Input
        label="Full Name"
        className="gray-border"
        maxLength="50"
        value={contactName}
        onChange={(event) => {
          setContactName(event.target.value);
        }}
        hasError={inputErrors.contactName}
        errorText="Enter your name and surname"
      />
      <Input
        label="Company Name"
        className="gray-border"
        maxLength="50"
        value={companyName}
        onChange={(event) => {
          setCompanyName(event.target.value);
        }}
        hasError={inputErrors.companyName}
        errorText="Enter your company"
      />
      <Input
        className="gray-border"
        label="Company Website"
        placeholder="https://"
        maxLength="100"
        value={companyWebsite}
        onChange={(event) => {
          setCompanyWebsite(event.target.value);
        }}
        hasError={inputErrors.companyWebsite}
        errorText="Enter your company's website"
      />
      <Input
        className="gray-border"
        textarea
        label="Use Case"
        placeholder="Tell us your use case"
        maxLength="1000"
        value={useCase}
        onChange={(event) => {
          setUseCase(event.target.value);
        }}
        hasError={inputErrors.useCase}
        errorText="Enter your use case"
      />
    </Modal>
  );
};

export default SubscriptionRequestModal;
