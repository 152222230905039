import React, { useMemo, useState } from "react";
import _ from "lodash";
import { SlideModal, BoxInput, DivButton, Dropdown, Switch, IconButton, Icon } from "UI";
import MouseClickImage from "Assets/images/appStreaming/mouse-click.png";
import {
  FILTERED_WORDS_UPLOADED_APP,
  KEY_MAPPING_OPTIONS,
  LINUX_UN_PERMITTED_EXTENSIONS,
  UPLOAD_TYPES,
} from "Constants/global.constants";
import TapImage from "Assets/images/appStreaming/tap.png";
import CompletionImage from "Assets/images/appStreaming/configuration-completed.png";
import { resetAppUpload, uploadImage } from "Actions/Dashboard/Files.actions";
import { connect } from "react-redux";
import { classnames, useAPI } from "Utils";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import ImageUploadArea from "Utils/Components/File/ImageUploadArea/ImageUploadArea.component";
import { useDragged } from "Utils/Hooks";
import { isFileSizesAllowed, containsUnAllowedFileTypes, firstFileInType } from "Utils/Helpers/file.helpers";
import MachineTypeSelection from "../../Configuration/MachineTypeSelection.component";

import "./AppConfigurationModal.styles.scss";

const APP_CONFIGURATION_STEPS = {
  applicationType: { name: "Type", step: 0, disabled: true },
  upload: { name: "Upload", step: 1, disabled: true },
  executable: { name: "General", step: 2, disabled: false },
  performance: { name: "Performance", step: 3, disabled: false },
  controls: { name: "Controls", step: 4, disabled: false },
  complete: { name: "Ready", step: 5, disabled: true, showOnCurrentStep: true },
};

const AppConfigurationModal = ({
  files,
  appStreaming,
  selectedApp,
  uploadedApps,
  uploadImage,
  setShowAppConfigurationModal,
  initialConfig,
  setInitialConfig,
  setSelectedApp,
  getApplicationsAPI,
  resetAppUpload,
  translate,
}) => {
  const { id: appId } = selectedApp || {};
  const { os, is_bundled_app: isBundledApp } = selectedApp?.attributes || {};
  const linux = os === "linux";
  const uploadedApp = uploadedApps?.[appId];
  const appExeAttributes = selectedApp?.attributes?.active_executable?.attributes || {};
  const uploadedAppContents = uploadedApp?.contents || appExeAttributes.executable_list || [];
  const runningMachinesPresent = selectedApp?.attributes?.running_machines_present;

  const [activeTab, setActiveTab] = useState(0);
  const [appName, setAppName] = useState(selectedApp?.attributes?.name || "");
  const [selectedMachineType, setSelectedMachineType] = useState(
    parseInt(selectedApp?.attributes?.default_machine_type?.id, 10),
  );
  const [keyMappingSelection, setKeyMappingSelection] = useState(selectedApp?.attributes?.key_mapping_selection);
  const [canChangeKeyMapping, setCanChangeKeyMapping] = useState(selectedApp?.attributes?.changeable_key_mapping);
  const [logoUrl, setLogoUrl] = useState(selectedApp?.attributes?.logo_url);
  const [submitted, setSubmitted] = useState(false);

  const appExecList = useMemo(() => {
    if (linux) {
      return uploadedAppContents.filter((file) => {
        return !LINUX_UN_PERMITTED_EXTENSIONS.includes(file.split(".")?.pop()) && !file.endsWith("/");
      });
    }

    return uploadedAppContents.filter(
      (file) => file.endsWith(".exe") && !FILTERED_WORDS_UPLOADED_APP.some((word) => file.toLowerCase().includes(word)),
    );
  }, [uploadedAppContents, linux]);

  const [appExecName, setAppExecName] = useState(
    appExeAttributes.friendly_full_path || firstFileInType(appExecList, ".exe") || "",
  );

  const {
    api: updateAppAPI,
    isLoading: isAppConfigAPILoading,
    data: updateAppData,
  } = useAPI({
    type: "put",
    endpoint: initialConfig
      ? APP_STREAMING_API_ENDPOINTS.APPLICATION(appId)
      : APP_STREAMING_API_ENDPOINTS.CONFIGURE_APPLICATION(appId),
    onSuccess: () => {
      getApplicationsAPI();
      setSelectedApp(selectedApp);
    },
  });

  const { api: deleteLogoAPI } = useAPI({
    type: "delete",
    endpoint: APP_STREAMING_API_ENDPOINTS.DELETE_APP_LOGO(appId),
    onSuccess: () => {
      setLogoUrl(null);
    },
  });

  const { isDragActive, onDragOver, onDragEnter, onDragLeave, resetDrag } = useDragged();

  const handleImageDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;

    handleImageInput(files);
  };

  const handleImageInput = (files) => {
    const imgFile = files?.[0];
    // Check file types: only jpeg and png allowed
    if (containsUnAllowedFileTypes(files, ["image/jpeg", "image/png"])) {
      return;
    }
    // Check file size: 2MB is limit
    if (!isFileSizesAllowed(files, 2)) {
      return;
    }

    if (imgFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogoUrl(e.target.result);
      };

      reader.readAsDataURL(imgFile);
      uploadImage({ appId, files, uploadType: UPLOAD_TYPES.APPLICATION_LOGO_UPLOAD });
      resetDrag();
    }
  };

  const isImageUploading =
    _.filter(files.uploadFilesCTX.uploadFiles, (file) => file.uploadType === UPLOAD_TYPES.APPLICATION_LOGO_UPLOAD)
      .length > 0;

  const { getApplicationMachineTypesCTX } = appStreaming;
  const machineTypes =
    getApplicationMachineTypesCTX.data?.pricing?.map((pricingItem) => {
      return { ...pricingItem.attributes.machine_type, ...{ price: pricingItem.attributes.price.toFixed(3) } };
    }) || [];

  const { dx_cache_collectable: cacheCollectable, optimized } = updateAppData?.attributes || {};

  const tabs = [
    {
      key: "step0",
      showSidebar: true,
      disabled: !appName,
      disablePrevious: true,
      content: (
        <>
          <h1>{translate("appStreamingModals.configuration.executable.header")}</h1>
          <p>{translate("appStreamingModals.configuration.executable.description")}</p>
          <h3>{translate("appStreamingModals.configuration.executable.appFolder")}</h3>
          <Dropdown
            className="app-folder-box"
            defaultSelected={isBundledApp ? "Bundled Application" : appExecName}
            options={appExecList}
            handleSelectedOptionChange={(option) => {
              setAppExecName(option);
            }}
            underlined
            disabled={isBundledApp || appExecList.length === 0 || runningMachinesPresent}
          />
          <h3>{translate("appStreamingModals.configuration.executable.appName")}</h3>
          <BoxInput
            value={appName}
            onChange={(event) => setAppName(event.target.value)}
            color="purple-light"
            maxLength="18"
            placeholder="Application Name"
          />
          <div className="image-upload-container">
            <ImageUploadArea
              imageSrc={logoUrl}
              isDragActive={isDragActive}
              uploadText={translate("appStreamingModals.configuration.logo.description")}
              acceptedFiles="image/png, image/jpeg"
              onFileDrop={handleImageInput}
              icon="upload-to-cloud"
              color="aqua-main"
              translate={translate}
              topRightContent={
                logoUrl &&
                (isImageUploading ? (
                  <Icon name="loader" tiny style={{ height: "1.2rem" }} />
                ) : (
                  <IconButton
                    name="close"
                    style={{ height: "1.2rem" }}
                    onClick={() => {
                      deleteLogoAPI(appId);
                    }}
                  />
                ))
              }
              small
              single
            />
          </div>
        </>
      ),
    },
    {
      key: "step1",
      showSidebar: true,
      disabled: !selectedMachineType,
      content: (
        <>
          <h1>{translate("appStreamingModals.configuration.performance.header")}</h1>
          <p>{translate("appStreamingModals.configuration.performance.description")}</p>
          <div className="machine-type-cards-container">
            <MachineTypeSelection
              machineTypes={machineTypes}
              selectedMachineType={selectedMachineType}
              setSelectedMachineType={setSelectedMachineType}
              os={os}
              translate={translate}
            />
          </div>
          <p className="pricing-annotation">{translate("appStreamingModals.configuration.performance.info")}</p>
        </>
      ),
    },
    {
      key: "step2",
      showSidebar: true,
      disabled: !keyMappingSelection,
      content: (
        <>
          <h1>{translate("appStreamingModals.configuration.controls.header")}</h1>
          <p>{translate("appStreamingModals.configuration.controls.inputMode")}</p>
          <div className="key-mapping-options">
            <div className="row grid-2-columns">
              <DivButton
                className={classnames([
                  "key-mapping-option",
                  keyMappingSelection === KEY_MAPPING_OPTIONS.CLICK && "selected",
                ])}
                onClick={() => {
                  setKeyMappingSelection(KEY_MAPPING_OPTIONS.CLICK);
                }}
              >
                <img src={MouseClickImage} />
                <h5>{translate("appStreamingModals.configuration.keyMappingSelection.mouse.header")}</h5>
                <p>{translate("appStreamingModals.configuration.keyMappingSelection.mouse.description")}</p>
              </DivButton>
              <DivButton
                className={classnames([
                  "key-mapping-option",
                  keyMappingSelection === KEY_MAPPING_OPTIONS[360] && "selected",
                ])}
                onClick={() => {
                  setKeyMappingSelection(KEY_MAPPING_OPTIONS[360]);
                }}
              >
                <img src={TapImage} />
                <h5>{translate("appStreamingModals.configuration.keyMappingSelection.tap.header")}</h5>
                <p>{translate("appStreamingModals.configuration.keyMappingSelection.tap.description")}</p>
              </DivButton>
            </div>
            <Switch
              text={translate("appStreamingModals.configuration.keyMappingSelection.option")}
              color="aqua-main"
              checked={canChangeKeyMapping}
              onChange={(e) => setCanChangeKeyMapping(e)}
            />
          </div>
        </>
      ),
      onSubmit: () => {
        setSubmitted(true);
        updateAppAPI({
          executable_name: appExecName.includes(".exe") || linux ? appExecName : `${appExecName}.exe`,
          application_name: appName,
          key_mapping_selection: keyMappingSelection,
          changeable_key_mapping: canChangeKeyMapping,
          machine_type_id: selectedMachineType,
        });
      },
    },
    {
      disabled: !submitted,
      key: "step4",
      showSidebar: true,
      disablePrevious: true,
      content: (
        <div className="completion-step">
          <img src={CompletionImage} />
          <h1>
            {initialConfig
              ? translate("appStreamingModals.configuration.created.header")
              : translate("appStreamingModals.configuration.configured.header")}
          </h1>
          <p>
            {initialConfig
              ? translate("appStreamingModals.configuration.created.description")
              : translate("appStreamingModals.configuration.configured.description")}
          </p>

          {/* {!optimized && cacheCollectable && (
            <div className="optimization-info-container">
              <Icon name="check" color="aqua-main" />
              <div className="optimization-descriptions">
                <h1>{translate("appStreamingModals.optimizationInformation.header")}</h1>
                <p>{translate("appStreamingModals.optimizationInformation.description")}</p>
              </div>
            </div>
          )} */}
        </div>
      ),
    },
  ];

  const onClose = () => {
    setSelectedApp({});
    setShowAppConfigurationModal(false);
    resetAppUpload(appId);
  };

  return (
    <SlideModal
      loading={isAppConfigAPILoading}
      className="app-configuration-modal"
      closeAction={onClose}
      confirmAction={() => {
        setInitialConfig(false);
        onClose();
      }}
      topRightIcon="close"
      contents={tabs}
      topRightIconAction={() => onClose()}
      translate={translate}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      pending={isAppConfigAPILoading || isImageUploading}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={handleImageDrop}
      steps={APP_CONFIGURATION_STEPS}
      currentStep={activeTab + 2}
      sidebarHeader={translate("appStreamingModals.configuration.sidebar.header")}
      indexOffset={2}
      big
    />
  );
};

const mapStateToProps = (state) => ({
  files: state.files,
  appStreaming: state.appStreaming,
});

const actionCreators = {
  uploadImage,
  resetAppUpload,
};

export default connect(mapStateToProps, actionCreators)(AppConfigurationModal);
